import Icon from "components/atoms/Icon/Icon";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import spacing from "styles/spacing";

interface InputButtonIconProps {
  testID?: string;
  children: React.ReactElement;
  onClick: () => void;
  color?: string; // Parsed to Icon
}

const InputButtonIconStyled = styled.button`
  background-color: transparent;
  border: none;
  margin: 0px ${spacing.s};
  cursor: pointer;
`;

InputButtonIconComponent.testID = "inputButtonIcon";

export default function InputButtonIconComponent({ testID, children, onClick, ...restProps }: InputButtonIconProps) {
  return (
    <InputButtonIconStyled onClick={onClick} data-testid={composeTestID(InputButtonIconComponent.testID, testID)}>
      <Icon size={22} {...restProps}>
        {children}
      </Icon>
    </InputButtonIconStyled>
  );
}
