import api from "api";
import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import PasswordInput from "components/molecules/Form/PasswordInput/PasswordInput";
import TextInput from "components/molecules/Form/TextInput/TextInput";
import BasicCard from "components/organisms/Cards/BasicCard/BasicCard";
import CardText from "components/organisms/Cards/CardText/CardText";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as QuestionMarkSVG } from "assets/svg/question-mark.svg";
import spacing from "styles/spacing";
import Spacer from "components/atoms/Spacer/Spacer";

const RecoverStyled = styled.div`
  & .questionMark {
    width: 200px;
  }
`;

interface RecoveryKeyForm {
  email: string;
  newPassword: string;
  repeatNewPassword: string;
}

export default function RecoverComponent() {
  const { key } = useParams<{ key: string }>();

  const handleOnSubmit = async (values: RecoveryKeyForm) => {
    try {
      const response = await api.Authentication.recoveryPassword({ ...values, recoveryKey: key });
      console.log("response:", response);
      window.alert("Success");
    } catch (er: any) {
      console.log("error:", er.message);
      window.alert(er.message);
    }
  };
  return (
    <Form onSubmit={handleOnSubmit}>
      {({ handleSubmit }) => {
        return (
          <RecoverStyled>
            <BasicCard
              swapMobileOrientation
              rightComponent={
                <CardText
                  title={<>Recover your account</>}
                  body={
                    <>
                      <Field component={TextInput} type="email" compact name="email" placeholder="Enter your email" />
                      <Field
                        component={PasswordInput}
                        inputType="password"
                        name="newPassword"
                        placeholder="New password"
                        compact
                      />
                      <Field
                        component={PasswordInput}
                        inputType="password"
                        name="repeatNewPassword"
                        placeholder="Repeat new password"
                        compact
                      />
                      <Spacer height={spacing.xl} />
                      <Button onClick={handleSubmit}>Set new password</Button>
                    </>
                  }
                />
              }
              leftComponent={<QuestionMarkSVG className="questionMark" />}
            />
          </RecoverStyled>
        );
      }}
    </Form>
  );
}
