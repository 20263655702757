import Connection from "./Connection";

export interface APIGetTestListOptions {
  offset?: number;
  limit?: number;
}

const DEFAULT_GET_TEST_LIST_OPTIONS = {
  limit: "10",
  offset: "0",
};

export function getTestList(options?: APIGetTestListOptions) {
  return Connection.get(
    `/test?${new URLSearchParams({
      ...DEFAULT_GET_TEST_LIST_OPTIONS,
      ...(options === undefined ? options : {}),
    })}`
  );
}
