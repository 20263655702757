import * as React from "react";
import styled from "styled-components";
import color from "styles/color";

const STICKER_WIDTH = "19mm";
const STICKER_HEIGHT = "25mm";

const TOP_MARGIN = "5mm";

const BORDER_RADIUS = "2mm";

const SPACING_HORIZONTAL = "5mm";
const SPACING_VERTICAL = "5mm";

const BlankStickerPackStyled = styled.div`
  margin: ${TOP_MARGIN} auto;
  /* background-color: yellow; */
  width: calc((${STICKER_WIDTH} * 2) + ${SPACING_HORIZONTAL} * 2);
`;

const BlankStickerStyled = styled.div`
  background-color: ${color.grey1};
  width: ${STICKER_WIDTH};
  height: ${STICKER_HEIGHT};
  border-radius: ${BORDER_RADIUS};
  margin: calc(${SPACING_VERTICAL} / 2) calc(${SPACING_HORIZONTAL} / 2);
  display: inline-block;
`;

export function BlankStickerPack() {
  return (
    <BlankStickerPackStyled>
      <BlankStickerStyled />
      <BlankStickerStyled />
      <BlankStickerStyled />
      <BlankStickerStyled />
    </BlankStickerPackStyled>
  );
}
