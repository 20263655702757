import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Picture from "components/atoms/Picture/Picture";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import color from "styles/color";
import media from "styles/media";
import spacing from "styles/spacing";

interface BlogPostProps {
  id: string;
  categories?: string[];
  title: string;
  date: string;
  owner?: string;
  subtitle: string;
  images?: string[];
  location?: string;
  released?: boolean;
}

const BlogPostStyled = styled.article`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  padding: calc(${spacing.s} * 2) ${spacing.s} ${spacing.s} ${spacing.s};
  box-sizing: border-box;
  cursor: pointer;
  ${({ released }: { released: boolean }) => `${released === false ? `opacity: 0.5; pointer-events: none;` : ""}`}
  @media ${media.mobile} {
    padding: ${spacing.xs} ${spacing.xs} 0px ${spacing.xs} !important;
    box-sizing: border-box;
    width: 100%;
  }
  @media ${media.desktop} {
    width: 50%;
  }
  @media ${media.large} {
    width: 33.3333332%;
  }
`;

const BlogPostContainerStyled = styled.div`
  width: 350px;
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.05);
`;
const BlogPostImageStyled = styled.div`
  position: relative;
  width: calc(100% - ${spacing.xs} * 2);
  height: 200px;
  background-color: ${color.white};
  margin: ${spacing.xs} ${spacing.xs} ${spacing.xs} ${spacing.xs};
  object-fit: cover;
  & img {
    width: calc(100% - ${spacing.xs} * 2);
    height: 200px;
    margin: ${spacing.xs} ${spacing.xs} ${spacing.xs} ${spacing.xs};
    object-fit: cover;
  }
`;
const BlogPostContentContainerStyled = styled.div`
  flex: 1;
  margin: 0px ${spacing.m} ${spacing.xs} ${spacing.m};
  & > p {
    /* font-size: ; */
  }
`;

export default function BlogPostComponent({
  id,
  categories,
  title,
  date,
  owner,
  subtitle,
  images = [],
  location,
  released,
}: BlogPostProps) {
  const navigate = useNavigate();

  return (
    <BlogPostStyled
      released={!!released}
      onClick={
        location
          ? () => {
              navigate(location);
            }
          : undefined
      }
    >
      <BlogPostContainerStyled className="blogPost__container">
        <BlogPostImageStyled
          as={Picture}
          alt={`${title}${released === false ? "(Coming soon)" : ""}`}
          supportedTypes={["png"]}
          className="blogPost__image"
          src={images[0]}
        />
        <BlogPostContentContainerStyled className="blogPost__content-container">
          <Heading varient="h4" as="h1">
            {`${title}${released === false ? " (Coming soon)" : ""}`}
          </Heading>
          <Paragraph>{subtitle}</Paragraph>
        </BlogPostContentContainerStyled>
      </BlogPostContainerStyled>
    </BlogPostStyled>
  );
}
