import * as React from "react";
import styled from "styled-components";
import Button from "components/atoms/Button/Button";
import spacing from "styles/spacing";
import Heading from "components/atoms/Heading/Heading";
import BottomBar from "../../../Print/components/BottomBar";
import InformationBox from "pages/Admin/Print/components/InformationBox";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import { toPng } from "html-to-image";
import { KeyringWood } from "../../components/KeyringWood";
import generateId from "functions/generate-id";
import PrintKeyring from "pages/Admin/Print/components/PrintKeyring";

const KeyringA4Styled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  align-items: center;
`;

const PrintContentContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: ${spacing.l};
  &.printable {
    display: flex;
  }
`;

const FILE_NAME = "KeyringWood";

export default function KeyringWoodPrintPrint() {
  const componentRef = React.useRef<HTMLDivElement>(null);
  const [code, setActivationCode] = React.useState(generateId());

  const handlePrint = () => {
    if (componentRef.current === null) {
      return;
    }

    toPng(componentRef.current, {
      cacheBust: true,
      pixelRatio: 10,
    })
      .then(dataUrl => {
        const link = document.createElement("a");
        link.download = `${FILE_NAME}_${code}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <KeyringA4Styled>
      <Heading as="h1" varient="h3">
        Keyring (Wood)
      </Heading>
      <PrintContentContainer>
        <PrintKeyring ref={componentRef}>
          <>
            <KeyringWood id={code} />
          </>
        </PrintKeyring>
      </PrintContentContainer>
      <InformationBox>
        <Heading as="h2" varient="h4">
          Materials
        </Heading>
        <Paragraph>
          <>
            <b>Loop rings</b>
            <ul>
              <li>
                Buy:{" "}
                <a href="https://nl.aliexpress.com/item/32924011829.html?spm=a2g0o.order_detail.order_detail_item.3.46426d76K1FjsF&gatewayAdapt=glo2nld">
                  Here
                </a>
              </li>
            </ul>
            <b>Keyrings</b>
            <ul>
              <li>
                Buy (6.2 cents each):{" "}
                <a href="https://nl.aliexpress.com/item/1005002630473762.html?spm=a2g0o.productlist.main.67.2dcf4641yW9rM9&algo_pvid=ecd2a411-1fd4-419d-a783-53c800b7d92a&algo_exp_id=ecd2a411-1fd4-419d-a783-53c800b7d92a-33&pdp_ext_f=%7B%22sku_id%22%3A%2212000021475703667%22%7D&pdp_npi=3%40dis%21EUR%218.87%216.21%21%21%21%21%21%4021227d8316762300712726513d0686%2112000021475703667%21sea%21NL%213749754133&curPageLogUid=mtLW7ReN9qbZ">
                  Here
                </a>
              </li>
            </ul>
          </>
        </Paragraph>
        <Heading as="h2" varient="h4">
          Engraving (Laser Pecker)
        </Heading>
        <Paragraph>
          <>
            <b>Laser front</b>
            <ul>
              <li>height 26mm</li>
              <li>strength: 90%</li>
              <li>depth: 80%</li>
            </ul>
            <b>Laser back</b>
            <ul>
              <li>height 13mm</li>
              <li>strength: 90%</li>
              <li>depth: 80%</li>
            </ul>
          </>
        </Paragraph>
      </InformationBox>
      <BottomBar>
        <Button
          onClick={() => {
            handlePrint();
            setActivationCode(generateId());
          }}
        >
          Save PNG
        </Button>
      </BottomBar>
    </KeyringA4Styled>
  );
}
