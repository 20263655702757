import * as React from "react";
import styled from "styled-components";
import color from "styles/color";

interface PrintPageProps {
  children?: React.ReactElement;
  size?: number;
}

const A3_WIDTH = 297; // mm
const A3_HEIGHT = 420; // mm

const PrintPageContentStyled = styled.svg`
  width: ${A3_WIDTH}mm;
  min-height: ${A3_HEIGHT}mm;
  /* background-color: ${color.grey2}; */
  background-color: white;
`;

const PrintPageStyled = styled.div`
  /* overflow: hidden;/ */
  /* display: inline; */
  /* background-color: white; */
`;

const PrintPageContainer = styled.div`
  /* overflow: hidden;/ */
  /* display: inline; */
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
`;

export default React.forwardRef<HTMLDivElement, PrintPageProps>(function PrintPageComponent(
  { children, size = 0.5 },
  ref
) {
  return (
    <PrintPageContainer style={{ width: `calc(${A3_WIDTH}mm * ${size})`, height: `calc(${A3_HEIGHT}mm * ${size})` }}>
      <PrintPageStyled
        ref={ref}
        style={{
          width: `${A3_WIDTH}mm`,
          height: `${A3_HEIGHT}mm`,
          transform: `scale(${100 * size}%) translate(-${(100 * size) / 1}%, -${(100 * size) / 1}%)`,
        }}
      >
        <PrintPageContentStyled
          style={{ width: `${A3_WIDTH}mm`, height: `${A3_HEIGHT}mm` }}
          viewBox={`0 0 ${A3_WIDTH} ${A3_HEIGHT}`}
          id="print_svg"
          className="printable"
        >
          {children}
        </PrintPageContentStyled>
      </PrintPageStyled>
    </PrintPageContainer>
  );
});
