import Icon from "components/atoms/Icon/Icon";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

// extends React.TextareaHTMLAttributes<HTMLTextAreaElement>
interface InputProps {
  icon?: React.ReactElement;
  appendElement?: React.ReactElement;
  value: string;
  onChange: (ev: any) => void;
}

const INPUT_ICON_SIZE = 20;

const ContentEditableStyled = styled.textarea`
  border: solid ${color.grey3} 1px;
  border-radius: 3px;
  width: 100%;
  display: flex;
  overflow: hidden;
  background-color: ${color.grey2};
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.input};
  font-weight: ${fontWeight.thin};
  color: ${color.black};
  padding: ${spacing.m};
  flex: 1;
  min-height: 150px;
  ::placeholder {
    color: ${color.greyDark1};
  }
`;

const TextAreaComponent = React.forwardRef(
  ({ icon, appendElement, value, onChange, ...props }: InputProps, ref: any) => {
    return (
      <ContentEditableStyled
        onChange={onChange}
        // tagName="br"
        {...props}
      >
        {value}
      </ContentEditableStyled>
    );
  }
);

TextAreaComponent.displayName = "TextAreaComponent";

export default TextAreaComponent;
