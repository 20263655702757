import * as React from "react";
import styled from "styled-components";
import Tag from "../../components/Tag";

const TagDesignStyled = styled.div`
  transform: scale(300%);
  /* border: 1px solid green; */
`;

export default function TagDesignComponent() {
  return (
    <TagDesignStyled>
      <Tag id="12345" />
    </TagDesignStyled>
  );
}
