import CheckBox from "components/atoms/Form/CheckBox";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

const LARGE_PADDING = spacing.m;
const SMALL_PADDING = spacing.s;

const TableCheckBoxStyled = styled.td(
  ({ $paddingSize }: { $paddingSize: "small" | "large" }) => `
  text-align: left;
  padding: ${$paddingSize === "small" ? SMALL_PADDING : LARGE_PADDING} ${
    $paddingSize === "small" ? SMALL_PADDING : LARGE_PADDING
  };
  font-family: ${fontFamily.primary};
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.p};
  color: ${color.black};
`
);

/**
 * See reason for float https://stackoverflow.com/a/56685632
 */
const TableCheckBoxFloat = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
`;

interface TableCheckBoxProps extends Omit<React.HTMLAttributes<HTMLTableCellElement>, "onChange"> {
  paddingSize?: "small" | "large";
  colSpan?: number;
  id: string;
  value: boolean;
  onChange?: (value: boolean) => void;
}

TableCheckBoxComponent.testID = "tableData";

export default function TableCheckBoxComponent({
  id,
  value,
  children,
  paddingSize = "large",
  onChange,
  ...props
}: TableCheckBoxProps) {
  return (
    <TableCheckBoxStyled $paddingSize={paddingSize} {...props}>
      <TableCheckBoxFloat>
        <CheckBox
          id={id}
          value={value ? "1" : "0"}
          onChange={(value: any) => {
            console.log("change", value.target.value, value.target.value === "0" ? true : false);
            onChange?.(value.target.value === "0" ? true : false);
          }}
        />
        {children}
      </TableCheckBoxFloat>
    </TableCheckBoxStyled>
  );
}
