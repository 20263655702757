import api from "api";
import Button from "components/atoms/Button/Button";
import PasswordInput from "components/molecules/Form/PasswordInput/PasswordInput";
import TextInput from "components/molecules/Form/TextInput/TextInput";
import { FORM_ERROR } from "final-form";
import * as React from "react";
import { Field, Form } from "react-final-form";
import styled from "styled-components";

interface LoginPageProps {
  onSuccess: (jwt: string) => void;
}

interface LoginFormValues {
  email: string;
  password: string;
}

const LoginPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function LoginPageComponent({ onSuccess }: LoginPageProps) {
  const handleOnSubmit = async (values: LoginFormValues) => {
    try {
      console.log("values:", values);
      const response = await api.Authentication.logIn(values);
      console.log("authentication/login/user", response);
      onSuccess(response.jwt);
    } catch (er) {
      console.log("err", er);
      return { [FORM_ERROR]: "Submission error" };
    }
  };
  return (
    <LoginPageStyled>
      Login
      <Form onSubmit={handleOnSubmit}>
        {({ handleSubmit, submitting }) => {
          return (
            <>
              <Field name="email" label="Email" component={TextInput}></Field>
              <Field name="password" label="Password" component={PasswordInput}></Field>
              <Button onClick={handleSubmit}>Login</Button>
              {submitting && "Loading..."}
            </>
          );
        }}
      </Form>
    </LoginPageStyled>
  );
}
