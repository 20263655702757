import Container from "components/atoms/Container/Container";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import media from "styles/media";

interface BasicCardProps {
  testID?: string;
  leftComponent?: React.ReactElement;
  rightComponent?: React.ReactElement;
  leftFlex?: number;
  rightFlex?: number;
  theme?: "light" | "dark";
  backgroundImage?: any;
  backgroundColor?: string;
  swapMobileOrientation?: boolean;
}

const LARGE_HEIGHT_PADDING = "60px";
const LARGE_HEIGHT = "300px";
const LARGE_MARGIN = "30px";

const SMALL_HEIGHT_PADDING = "10px";
const SMALL_HEIGHT = "200px";
const SMALL_MARGIN = "20px";

const BasicCardStyled = styled.section(
  ({ $theme }: { $theme: "light" | "dark" }) => `
  position: relative;
  width: 100%;
  overflow: hidden;
  ${$theme === "dark" ? `background-color: ${color.grey2};` : ""}

  @media ${media.mobile} {
    min-height: ${SMALL_HEIGHT};
    display: relative;
  }
  @media ${media.desktop} {
    min-height: ${LARGE_HEIGHT};
    display: flex;
    justify-content: center;
  }
`
);

const BasicCardContainerStyled = styled.div(
  ({ $swapMobileOrientation }: { $swapMobileOrientation: boolean }) => `
    @media ${media.mobile} {
        display: flex;
        height: 100%;
        padding-top: ${SMALL_HEIGHT_PADDING};
        padding-bottom: ${SMALL_HEIGHT_PADDING};
        box-sizing: border-box;
        flex-direction: ${$swapMobileOrientation === true ? `column` : "column-reverse"};
    }
    @media ${media.desktop} {
        display: flex;
      height: 100%;
      flex-direction: row;
      padding-top: ${LARGE_HEIGHT_PADDING};
      padding-bottom: ${LARGE_HEIGHT_PADDING};
      box-sizing: border-box;
      max-width: 1100px;
    }
`
);

const BasicCardLeftStyled = styled.div`
  @media ${media.mobile} {
    display: flex;
    flex-direction: column;
    margin-top: ${SMALL_MARGIN};
    justify-content: center;
  }
  @media ${media.desktop} {
    display: flex;
    flex-direction: column;
    margin-right: ${LARGE_MARGIN};
    justify-content: center;
  }
`;

const BasicCardRightStyled = styled.div`
  @media ${media.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: ${SMALL_MARGIN};
  }
  @media ${media.desktop} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: ${LARGE_MARGIN};
  }
`;

BasicCardComponent.testID = "basicCard";

export default function BasicCardComponent({
  testID,
  leftComponent,
  rightComponent,
  leftFlex,
  rightFlex,
  backgroundColor,
  theme = "light",
  swapMobileOrientation = false,
}: BasicCardProps) {
  return (
    <BasicCardStyled
      $theme={theme}
      style={
        backgroundColor
          ? {
              backgroundColor,
            }
          : {}
      }
      data-testid={composeTestID(BasicCardComponent.testID, testID)}
    >
      <BasicCardContainerStyled
        as={Container}
        $swapMobileOrientation={swapMobileOrientation}
        className={`basicCard__container ${swapMobileOrientation ? "--swap" : ""}`}
      >
        <BasicCardLeftStyled className="basicCard__left" style={leftFlex ? { flex: leftFlex } : undefined}>
          {leftComponent}
        </BasicCardLeftStyled>
        <BasicCardRightStyled className="basicCard__right" style={rightFlex ? { flex: rightFlex } : undefined}>
          {rightComponent}
        </BasicCardRightStyled>
      </BasicCardContainerStyled>
    </BasicCardStyled>
  );
}
