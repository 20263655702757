import Button from "components/atoms/Button/Button";
import TitleDescription from "components/molecules/TitleDescription/TitleDescription";
import BasicCard from "components/organisms/Cards/BasicCard/BasicCard";
import CardText from "components/organisms/Cards/CardText/CardText";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import ShoppingJSON from "assets/lottie/shopping.json";
import ActivateJSON from "assets/lottie/activatePing.json";
import ApplyJSON from "assets/lottie/applyPing.json";
import styled from "styled-components";
import CardLottieImage from "components/organisms/Cards/CardLottieImage/CardLottieImage";
import Meta from "components/atoms/Meta";

const HowItWorksStyled = styled.div``;

const VideoStyled = styled.section`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function HowItWorksComponent() {
  const navigate = useNavigate();
  return (
    <HowItWorksStyled>
      <Meta
        title={`Pingify - How it works`}
        description={`1) Get some Pings! 2) Download and Activate Pings 3) Stick them to your belongings`}
        ogUrl={"http://pingify.io/how-it-works"}
      />
      <TitleDescription title="How It works" />
      <VideoStyled>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/nqOoGBNNMAI"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </VideoStyled>
      <BasicCard
        theme="dark"
        backgroundColor={`rgba(255,255,255,0.7)`}
        leftComponent={
          <CardText
            headingSize={2}
            title={
              <>
                <b>Step 1: </b> Get some Pings!
              </>
            }
            body={`Purchase pings through our online web shop.`}
            primaryActionComponent={
              <Button
                // noMargin
                onClick={() => {
                  navigate("/products");
                }}
              >
                Products
              </Button>
            }
          />
        }
        rightFlex={1}
        rightComponent={<CardLottieImage src={ShoppingJSON} />}
      />
      <BasicCard
        theme="dark"
        rightComponent={
          <CardText
            headingSize={2}
            title={
              <>
                <b>Step 2: </b> Download and Activate Pings
              </>
            }
            body={`Download the Pingify app, create and account and register your pings. Remember to allow notifications so you will be notified when somebody scans your item.`}
            primaryActionComponent={
              <Button
                onClick={() => {
                  navigate("/download");
                }}
              >
                Download the app
              </Button>
            }
          />
        }
        leftFlex={1}
        leftComponent={<CardLottieImage src={ActivateJSON} />}
      />
      <BasicCard
        theme="light"
        backgroundColor={`rgba(255,255,255,0.7)`}
        leftComponent={
          <CardText
            headingSize={2}
            title={
              <>
                <b>Step 3: </b> Stick them on your belongings
              </>
            }
            body={`Place pingify stickers on all of your beloved items and assign them in the app.`}
          />
        }
        rightFlex={1}
        rightComponent={<CardLottieImage src={ApplyJSON} />}
      />
      <TitleDescription
        title="That is it, safe and sound"
        description="If somebody scans your ping or fills in the digital return form you will be notified."
      />
    </HowItWorksStyled>
  );
}
