import * as React from "react";
import styled from "styled-components";
import colorStyle from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";

interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {
  color?: string;
}

const ParagraphStyled = styled.p(
  ({ color }: ParagraphProps) => `
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.p};
  color: ${color ?? colorStyle.greyDark1};
  line-height: 21px;
  font-weight: ${fontWeight.regular};
  & > ul {
    padding-inline-start: 14px;
  }
  & > b {
    color: ${colorStyle.black};
  }
`
);

export default ParagraphStyled;
