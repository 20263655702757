import Button from "components/atoms/Button/Button";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import { ReactComponent as IconBackIosSVG } from "assets/icons/icon-back-ios.svg";
import { ReactComponent as IconForwardIosSVG } from "assets/icons/icon-forward-ios.svg";
import Carousel from "components/atoms/Carousel/Carousel";
import media from "styles/media";
import CardBlogPost from "../CardBlogPost/CardBlogPost";

export interface ICardBlogPost {
  title: string;
  body: string;
  user?: string;
  time?: string;
  image: any;
  buttonText?: string;
  location?: string;
}

interface CardBlogProps {
  testID?: string;
  posts: ICardBlogPost[];
}

const LARGE_HEIGHT = "500px";
const SMALL_HEIGHT = "550px";

const CardBlogStyled = styled.div`
  @media ${media.mobile} {
    width: 100%;
    height: ${SMALL_HEIGHT};
    display: flex;
    flex-direction: column;
  }
  @media ${media.desktop} {
    width: 100%;
    box-sizing: border-box;
    height: ${LARGE_HEIGHT};
    display: flex;
    flex-direction: row;
    position: relative;
  }
`;

const CardBlogNavigationStyled = styled.div`
  @media ${media.mobile} {
    display: none;
  }
  @media ${media.desktop} {
    width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const CardBlogMobileNavigationStyled = styled.div`
  @media ${media.mobile} {
    height: 40px;
    width: 100%;
  }
  @media ${media.desktop} {
    display: none;
  }
`;

const CardBlogContentStyled = styled.div`
  scroll-behavior: smooth;
  height: 100%;
  flex: 1;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
  @media ${media.mobile} {
    height: 100%;
    flex: 1;
  }
  @media ${media.desktop} {
    height: 100%;
    flex: 1;
  }
`;

CardBlogComponent.testID = "cardBlog";

export default function CardBlogComponent({ posts, ...props }: CardBlogProps) {
  const [currentLocation, setCurrentLocation] = React.useState(0);
  const [numberPerPage, setNumberPerPage] = React.useState(1);
  const [lastLeftScroll, setLastLeftScroll] = React.useState(0);
  const containerEl = React.useRef(null);

  const handelOnScroll = (ev: any) => {
    const element: any = containerEl && containerEl.current;
    const leftScroll = element.scrollLeft;
    const containerWidth = element.getBoundingClientRect().width;
    const childWidth =
      element.childNodes.length > 0 ? Math.ceil(element.childNodes[0].getBoundingClientRect().width) : 0;
    const position =
      lastLeftScroll > leftScroll
        ? Math.floor(leftScroll / childWidth + 0.0)
        : Math.ceil(leftScroll / childWidth + 0.0);
    const newNumberPerPage = Math.ceil(containerWidth / childWidth);
    setNumberPerPage(newNumberPerPage);
    setCurrentLocation(position < 0 ? 0 : position + newNumberPerPage >= posts.length ? posts.length - 1 : position);
    setLastLeftScroll(leftScroll);
  };

  const handleOnNavigate = (location: number) => () => {
    const element: any = containerEl && containerEl.current;
    const currentScroll = element.scrollLeft;
    const childWidth =
      element.childNodes.length > 0 ? Math.ceil(element.childNodes[0].getBoundingClientRect().width) : 0;
    element.scrollTo({
      left: currentScroll + location * childWidth,
      behavour: "smooth",
    });
  };

  return (
    <CardBlogStyled data-testid={composeTestID(CardBlogComponent.testID, props.testID)}>
      <CardBlogNavigationStyled className="cardBlog__navigation">
        <Button
          layout="icon-only"
          disabled={currentLocation <= 0}
          onClick={handleOnNavigate(-1)}
          icon={<IconBackIosSVG />}
        />
      </CardBlogNavigationStyled>
      <CardBlogContentStyled className="cardBlog__content" ref={containerEl} onScroll={handelOnScroll}>
        {posts.map((item, index) => (
          <CardBlogPost key={`post_${index}`} post={item} />
        ))}
      </CardBlogContentStyled>
      <CardBlogNavigationStyled className="cardBlog__navigation">
        <Button
          layout="icon-only"
          disabled={currentLocation + numberPerPage >= posts.length}
          onClick={handleOnNavigate(1)}
          icon={<IconForwardIosSVG />}
        />
      </CardBlogNavigationStyled>
      <CardBlogMobileNavigationStyled className="cardBlog__mobile-navigation">
        <Carousel numberOfDots={posts.length} currentLocation={currentLocation} />
      </CardBlogMobileNavigationStyled>
    </CardBlogStyled>
  );
}
