import Icon from "components/atoms/Icon/Icon";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

interface TableHeaderProps {
  icon?: React.ReactElement;
  paddingSize?: "small" | "large";
  children?: React.ReactElement | string | Element | React.ReactNode;
  textAlign?: "left" | "center" | "right";
}

const LARGE_PADDING = spacing.m;
const SMALL_PADDING = spacing.s;

const TableHeaderStyled = styled.th(
  ({ $paddingSize, $textAlign }: { $paddingSize: "small" | "large"; $textAlign: "left" | "center" | "right" }) => `
  text-align: ${$textAlign};
  padding: ${$paddingSize === "small" ? SMALL_PADDING : LARGE_PADDING} ${
    $paddingSize === "small" ? SMALL_PADDING : LARGE_PADDING
  };
  font-family: ${fontFamily.primary};
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.p};
  color: ${color.greyDark1};
`
);

/**
 * See reason for float https://stackoverflow.com/a/56685632
 */
const TableHeaderFloat = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
`;

const IconStyled = styled.div`
  margin-right: ${spacing.s};
`;

TableHeaderComponent.testID = "tableHeader";

export default function TableHeaderComponent({
  icon,
  children,
  paddingSize = "large",
  textAlign = "left",
  ...props
}: TableHeaderProps) {
  return (
    <TableHeaderStyled $paddingSize={paddingSize} $textAlign={textAlign} {...props}>
      <TableHeaderFloat>
        <>
          {icon && (
            <IconStyled size={18} as={Icon}>
              {icon}
            </IconStyled>
          )}
          {children}
        </>
      </TableHeaderFloat>
    </TableHeaderStyled>
  );
}
