import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import { FontStyle } from "./FontStyle";

interface PrintKeyringWoodProps {
  children?: React.ReactElement;
  size?: number;
}

const WIDTH = 25;
const HEIGHT = 25;

const PrintKeyringWoodDivStyled = styled.div`
  width: ${WIDTH}mm;
  height: ${HEIGHT}mm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  border: none;
`;

const PrintKeyringWoodContainer = styled.svg`
  /* overflow: hidden;/ */
  /* display: inline; */
  width: ${WIDTH}mm;
  min-height: ${HEIGHT}mm;
  background-color: white;
  & text {
    font-family: ${fontFamily.primary};
  }
`;

export default React.forwardRef<HTMLDivElement, PrintKeyringWoodProps>(function PrintKeyringWoodComponent(
  { children, size = 1 },
  ref
) {
  return (
    <PrintKeyringWoodDivStyled ref={ref} style={{ transform: `scale(${size * 100}%)` }}>
      {children}
    </PrintKeyringWoodDivStyled>
  );
});
