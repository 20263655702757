export default {
  gradient: " radial-gradient(circle at 20% 80%, #00b4c2 10%, #1e7ab8 110%)",
  primary: "#00B4C2",
  secondary: "#00d2d4",
  grey2: "#F1F1F1",
  grey1: "#FAF8F9",
  grey3: "#D9D9D9",
  greyDark1: "#969696",
  white: "#fff",
  black: "#000000",
  lightBlack: "rgb(33,35,40)",
  red: "#FF5B42",
  lightRed: "#",
  yellow: "#EDE325",
  green: "#00CE59",
  lightGreen: "#ACE68F",
  orange: "#FD886E",
  lightOrange: "#FDBEB0",
  purple: "#635CCC",
  lightPurple: "#D4D1FF",
};
