import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import media from "styles/media";

interface CardTextProps {
  testID?: string;
  title: string | React.ReactElement;
  subtitle?: string;
  body: string | React.ReactElement;
  headingSize?: 1 | 2;
  primaryActionComponent?: React.ReactElement;
  secondaryActionComponent?: React.ReactElement;
}

const CardTextStyled = styled.div`
  @media ${media.mobile} {
  }
  @media ${media.desktop} {
    max-width: 500px;
  }
`;

const CardTextActionsStyled = styled.div`
  margin-top: 35px;
  width: 100%;
  display: flex;
  flex-direction: row;
  @media ${media.mobile} {
    margin-bottom: 35px;
    justify-content: center;
  }
  @media ${media.desktop} {
  }
`;

CardTextComponent.testID = "cardText";

export default function CardTextComponent({
  testID,
  title,
  body,
  subtitle,
  primaryActionComponent,
  secondaryActionComponent,
}: CardTextProps) {
  return (
    <CardTextStyled data-testid={composeTestID(CardTextComponent.testID, testID)}>
      <Heading as="h2" varient="h2">
        {title}
      </Heading>
      <Paragraph>{subtitle}</Paragraph>
      <Paragraph>{body}</Paragraph>
      {primaryActionComponent || secondaryActionComponent ? (
        <CardTextActionsStyled className="cardText__actions">
          {primaryActionComponent}
          {secondaryActionComponent}
        </CardTextActionsStyled>
      ) : (
        <></>
      )}
    </CardTextStyled>
  );
}
