import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import useOrders from "hooks/query/use-orders";

export default function useConfirmTransaction() {
  const queryCache = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, batch }: { id: string; batch: string }) => {
      return api.Order.updateBatch(id, batch);
    },
    onSuccess: (_, { id: _id }: { id: string; batch: string }) => {
      queryCache.invalidateQueries({ queryKey: [useOrders.queryKey] });
    },
  });
}
