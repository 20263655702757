import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";

interface CarouselProps {
  testID?: string;
  numberOfDots?: number;
  currentLocation?: number;
}

const CarouselStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const CarouselDotStyled = styled.div(
  ({ $active }: { $active: boolean }) => `
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 0px 5px;
    background-color: ${$active === true ? color.primary : color.greyDark1};
`
);

CarouselComponent.testID = "carousel";

export default function CarouselComponent({ numberOfDots = 3, currentLocation = 0, testID }: CarouselProps) {
  return (
    <CarouselStyled data-testid={composeTestID(CarouselComponent.testID, testID)}>
      {Array(numberOfDots)
        .fill("")
        .map((item, index) => (
          <CarouselDotStyled key={`carousel_${index}`} $active={index === currentLocation} />
        ))}
    </CarouselStyled>
  );
}
