import * as React from "react";
import styled from "styled-components";
import color from "styles/color";

const HOLE_SIZE = "3mm";

const TOP_MARGIN = "8mm";

const BlankKeyringStyled = styled.div`
  margin: ${TOP_MARGIN} auto;
  width: 100%;
`;

const HoleStyled = styled.div`
  background-color: ${color.grey1};
  width: ${HOLE_SIZE};
  height: ${HOLE_SIZE};
  margin: 0 auto;
  border-radius: 100%;
`;

export function BlankKeyring() {
  return (
    <BlankKeyringStyled>
      <HoleStyled />
    </BlankKeyringStyled>
  );
}
