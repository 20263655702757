import Paragraph from "components/atoms/Paragraph/Paragraph";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import spacing from "styles/spacing";

interface CheckBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactElement | string;
  id: string;
  error?: boolean;
}
const SIZE = "18px";

const CheckBoxStyled = styled.div`
  display: flex;
  align-items: center;
`;

const InputStyled = styled.input<{ $error: boolean }>`
  margin-right: ${spacing.s};
  accent-color: ${color.primary};
  border: 1px solid ${({ $error }) => ($error ? color.red : color.primary)};
  appearance: none;
  width: ${SIZE};
  height: ${SIZE};
  border-radius: 2px;
  position: relative;
  cursor: pointer;

  &[value="1"] {
    background-color: ${color.primary};

    &::after {
      content: "";
      background-image: url("/images/icons/checkbox.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px;
      width: ${SIZE};
      height: ${SIZE};
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${color.white};
    }
  }

  min-width: ${SIZE};
`;

export default function CheckBoxComponent({ value, label, id, onChange, error = false }: CheckBoxProps) {
  return (
    <CheckBoxStyled>
      <InputStyled $error={error} id={id} type="checkbox" onChange={onChange} value={value} />
      <Paragraph as="label" htmlFor={id}>
        {label}
      </Paragraph>
    </CheckBoxStyled>
  );
}
