import * as React from "react";
import styled from "styled-components";
import { BasketProduct } from "types/BasketProduct";
import productsData, { ProductSlug } from "data/products";
import Picture from "components/atoms/Picture/Picture";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import color from "styles/color";
import Button from "components/atoms/Button/Button";
import { ReactComponent as DeleteIconSVG } from "assets/icons/clear.svg";
import spacing from "styles/spacing";

interface BasketItemProps {
  onDelete: () => void;
  data: BasketProduct;
}

const THUMBNAIL_SIZE = "80px";

const BasketItemStyled = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: ${spacing.s};
  &:not(:first-child) {
    border-top: 1px solid ${color.grey2};
  }
  & > picture,
  img {
    width: ${THUMBNAIL_SIZE};
    height: ${THUMBNAIL_SIZE};
  }
`;

const BasketItemTextStyled = styled.div`
  flex: 1;
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const BasketItemTextTitleStyled = styled.span`
  font-size: ${fontSize.button};
  font-weight: ${fontWeight.bold};
  color: ${color.black};
`;
const BasketItemTextDescriptionStyled = styled.span`
  font-size: ${fontSize.p};
  font-weight: ${fontWeight.thin};
  color: ${color.greyDark1};
`;

export default function BasketItemComponent({ data, onDelete }: BasketItemProps) {
  const productData = Object.values(productsData).find(item => item.id === data.id);
  if (productData === undefined) return <></>;
  return (
    <BasketItemStyled>
      <Picture alt={`${productData.title} Thumbnail`} supportedTypes={["png"]} src={productData?.images[0]}></Picture>
      <BasketItemTextStyled>
        <BasketItemTextTitleStyled>{`${data.amount} x ${productData.title}`}</BasketItemTextTitleStyled>
        <BasketItemTextDescriptionStyled>{productData.subtitle}</BasketItemTextDescriptionStyled>
      </BasketItemTextStyled>
      <Button onClick={onDelete} varient="light" icon={<DeleteIconSVG />} layout="icon-only"></Button>
    </BasketItemStyled>
  );
}
