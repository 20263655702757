import * as React from "react";
import { PingPublic } from "types/Ping";
import DisplayPingMessageSuccess from "components/molecules/Display/DisplayPingMessageSuccess";
import generateRandomThanks from "functions/generate-random-thanks";

interface PingMessageSuccessProps {
  data: PingPublic;
  onFinish: () => void;
}

export default function PingMessageSuccessComponent({ data, onFinish }: PingMessageSuccessProps) {
  React.useEffect(() => {
    setTimeout(() => {
      onFinish();
    }, 4000);
  }, []);

  return <DisplayPingMessageSuccess title="Message sent!" body={generateRandomThanks()} />;
}
