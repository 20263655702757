import * as React from "react";
import styled from "styled-components";
import Button from "components/atoms/Button/Button";
import spacing from "styles/spacing";
import Heading from "components/atoms/Heading/Heading";
import BottomBar from "../../../Print/components/BottomBar";
import InformationBox from "pages/Admin/Print/components/InformationBox";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import PrintA6Page from "pages/Admin/Print/components/PrintA6Page";
import A6Side from "pages/Admin/Print/components/A6Side";
import PackagePage1 from "../../components/Package/Package_Page_1";
import PackagePage4 from "../../components/Package/Package_Page_4";
import { toPng } from "html-to-image";
import Spacer from "components/atoms/Spacer/Spacer";
import StickerDisplay from "../../assets/sticker_display.svg";
import TagDisplay from "../../assets/tag_display.svg";
import KeyringDisplay from "../../assets/keyring_display.svg";
import PrintA6PageHTML from "pages/Admin/Print/components/PrintA6PageHTML";
import TitleBody from "../../components/Package/TitleBody";
import { A6SideHTML } from "pages/Admin/Print/components/A6SideHTML";
import Activate from "../../components/Package/Activate";
import { SloganText } from "../../components/Package/SloganText";
import ImageTitleBody from "../../components/Package/ImageTitleBody";
import { BlankKeyring } from "../../components/Package/BlankKeyring";

const KeyringA4Styled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  align-items: center;
`;

const PrintContentContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: ${spacing.l};
  &.printable {
    display: flex;
  }
`;

const FILE_NAME = "Keyring_Package";

export default function PackageKeyringA6Print() {
  const [showGuides, setShowGuides] = React.useState(false);
  const refOutside = React.useRef<HTMLDivElement>(null);
  const refInside = React.useRef<HTMLDivElement>(null);

  const handlePrint = (location: 0 | 1) => {
    const ref =
      location === 0 && refOutside.current !== null
        ? refOutside.current
        : location === 1 && refInside.current !== null
        ? refInside.current
        : undefined;
    if (ref == undefined) {
      return;
    }

    toPng(ref, {
      cacheBust: true,
      pixelRatio: 10,
    })
      .then(dataUrl => {
        const link = document.createElement("a");
        link.download = `${FILE_NAME}_${location === 0 ? "Outside" : "Inside"}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <KeyringA4Styled>
      <Heading as="h1" varient="h3">
        Package (Keyring)
      </Heading>
      <PrintContentContainer>
        <PrintA6Page ref={refOutside} showGuides={showGuides}>
          <>
            <A6Side page={1}>
              <>
                <PackagePage4 />
              </>
            </A6Side>
            <A6Side page={2}>
              <PackagePage1 />
            </A6Side>
          </>
        </PrintA6Page>
      </PrintContentContainer>
      <PrintContentContainer>
        <PrintA6PageHTML ref={refInside} showGuides={showGuides}>
          <>
            <A6SideHTML>
              <>
                <TitleBody
                  title="More pings for your things"
                  body={
                    <>
                      <p>
                        Protect more of your items with the full range of pings at <b>www.pingify.io/products</b>.
                      </p>
                    </>
                  }
                />
                <Spacer height={"3mm"} />
                <ImageTitleBody
                  imageUrl={StickerDisplay}
                  title="Stickers"
                  italic
                  body={<>Headphones, notepads, phones, sports equipment.</>}
                />
                <ImageTitleBody
                  imageUrl={TagDisplay}
                  title="Tags"
                  italic
                  body={<>Teddies, wallets, umbrellas, gloves, and items with piercable material.</>}
                />
                <ImageTitleBody
                  imageUrl={KeyringDisplay}
                  title="Keyrings"
                  italic
                  body={<>Luggage, keys, backpacks, items with a zip.</>}
                />
                <Activate />
              </>
            </A6SideHTML>
            <A6SideHTML>
              <BlankKeyring />
              <SloganText></SloganText>
            </A6SideHTML>
          </>
        </PrintA6PageHTML>
      </PrintContentContainer>
      <InformationBox>
        <Heading as="h2" varient="h4">
          Printing
        </Heading>
        <Paragraph>
          <ul>
            <li>
              Use <a href="https://www.printenbind.nl/en/bestel/article?product=ansichtkaart">PrintNBind</a>
              <br />
              <i>
                A6 color double sided printed on 300 grams Natural card (white) paper, basic cut out, front provided
                with a glossy coating, none folded
              </i>
            </li>
          </ul>
        </Paragraph>
        <Heading as="h2" varient="h4">
          Assembly
        </Heading>
        <Paragraph>
          <ul>
            <li>Use 3D printed line creator to create fold in center</li>
            <li>Cut corners with corner stamp ?mm corner</li>
          </ul>
        </Paragraph>
      </InformationBox>
      <BottomBar>
        <Button onClick={() => handlePrint(0)}>Export Outside</Button>
        <Spacer width={spacing.m} />
        <Button onClick={() => handlePrint(1)}>Export Inside</Button>
        <Spacer width={spacing.m} />
        <Button varient="secondary" onClick={() => setShowGuides(!showGuides)}>{`Toggle Guides: ${
          showGuides ? "On" : "Off"
        }`}</Button>
      </BottomBar>
    </KeyringA4Styled>
  );
}
