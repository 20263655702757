import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import { FieldRenderProps } from "react-final-form";
import Input from "components/atoms/Form/Input/Input";
import Label from "components/atoms/Form/Label/Label";
import InputNote from "components/atoms/Form/InputNote/InputNote";
import PasswordIndicator from "components/atoms/Form/PasswordIndicator/PasswordIndicator";
import color from "styles/color";
import { ReactComponent as VisibilityOffNewSVG } from "assets/icons/visibility_off_new.svg";
import { ReactComponent as VisibilityOnNewSVG } from "assets/icons/visibility_on_new.svg";
import InputButtonIcon from "components/atoms/Form/InputButtonIcon/InputButtonIcon";

interface TextInputProps extends FieldRenderProps<string, HTMLInputElement> {
  testID?: string;
  icon?: React.ReactElement;
  note?: string;
  hasIndicator?: boolean;
  compact?: boolean;
}

const MAX_INPUT_WIDTH = "500px";

const TextInputStyled = styled.div`
  max-width: ${MAX_INPUT_WIDTH};
`;

TextInputComponent.testID = "textInput";

export default function TextInputComponent({
  input: { ...inputProps },
  label,
  note,
  hasIndicator,
  compact,
  ...props
}: TextInputProps) {
  const [isShown, setIsShown] = React.useState(false);
  const showIndicator = typeof inputProps.value === "string" && inputProps.value.length > 0 && hasIndicator === true;

  const errorMessage = props.meta.error;

  return (
    <TextInputStyled data-testid={composeTestID(TextInputComponent.testID, props.testID)}>
      <Label htmlFor={inputProps.name}>{label}</Label>
      <Input
        {...inputProps}
        {...props}
        appendElement={
          <InputButtonIcon onClick={() => setIsShown(!isShown)} color={color.black}>
            {isShown ? <VisibilityOnNewSVG /> : <VisibilityOffNewSVG />}
          </InputButtonIcon>
        }
        type={isShown ? "text" : "password"}
      ></Input>
      {showIndicator && errorMessage === undefined ? (
        <PasswordIndicator value={inputProps.value} />
      ) : compact === false || note !== undefined || typeof errorMessage === "string" ? (
        <InputNote htmlFor={inputProps.name} color={!errorMessage ? undefined : color.red}>
          {!errorMessage ? note : errorMessage}
        </InputNote>
      ) : null}
    </TextInputStyled>
  );
}
