import { useQuery } from "@tanstack/react-query";

import API from "api";
import { AppCheckout } from "types/App/Checkout";

useCheckout.queryKey = "checkout";

export default function useCheckout(sessionId: string) {
  return useQuery(
    [useCheckout.queryKey, sessionId],
    () => {
      return API.Checkout.getCheckout(sessionId) as Promise<AppCheckout>;
    },
    { notifyOnChangeProps: "all" }
  );
}
