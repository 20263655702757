import * as React from "react";
import styled from "styled-components";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

interface DisplayPingInstructionProps {
  children?: string;
}

const DisplayPingInstructionStyled = styled.span`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.h3};
  font-weight: ${fontWeight.light};
  margin: ${spacing.m} 0px ${spacing.xxs} 0px;
  display: block;
  text-align: center;
`;

export default function DisplayPingInstructionComponent({ children }: DisplayPingInstructionProps) {
  return <DisplayPingInstructionStyled>{children}</DisplayPingInstructionStyled>;
}
