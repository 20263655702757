import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

interface DisplayPingDescriptionProps {
  children: string | null;
}

const DisplayPingDescriptionStyled = styled.p(
  ({ $disabled }: { $disabled: boolean }) => `
font-family: ${fontFamily.primary};
  font-size: ${fontSize.p};
  font-weight: ${fontWeight.thin};
  margin: ${spacing.s} 0px ${spacing.m} 0px;
  display: block;
  background-color: ${color.grey2};
  /* width: 100%; */
  max-width: calc(100vw - ${spacing.xl} * 2);
  padding: ${spacing.s} ${spacing.s};
  text-align: center;
  border-radius: 7px;
  color: ${$disabled ? color.greyDark1 : color.black};
`
);

DisplayPingDescriptionComponent.testID = "displayPingDescription";

export default function DisplayPingDescriptionComponent({ children }: DisplayPingDescriptionProps) {
  const noText = children === null || children !== "";
  return (
    <DisplayPingDescriptionStyled $disabled={noText}>
      {noText ? "No description" : children}
    </DisplayPingDescriptionStyled>
  );
}
