import FlexInfo from "components/molecules/FlexInfo";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as QuestionMarkSVG } from "assets/svg/question-mark.svg";
import Button from "components/atoms/Button/Button";

export default function NotFoundComponent() {
  const navigate = useNavigate();

  const handleOnTryAgain = () => {
    navigate("/");
  };
  return (
    <FlexInfo
      svgElement={<QuestionMarkSVG />}
      title={`404 not found`}
      body={`Oops, looks like you are lost.`}
      buttonElement={
        <Button size="small" onClick={handleOnTryAgain}>
          Go home
        </Button>
      }
    />
  );
}
