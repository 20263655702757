import * as React from "react";
import Helmet from "react-helmet";

interface MetaProps {
  title: string;
  description: string;
  ogUrl: string;
  ogImage?: string;
  twitterCard?: "summary" | "photo" | "video" | "product" | "app" | "gallery" | "large version";
}

export default function MetaComponent({
  title,
  description,
  ogUrl,
  ogImage = "https://pingify.io/images/product-selection.png",
  twitterCard = "summary",
}: MetaProps) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:image" content={ogImage} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:url" content={ogUrl} />
      <meta property="twitter:image" content={ogImage} />
      <meta property="twitter:card" content={twitterCard} />
    </Helmet>
  );
}
