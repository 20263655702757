import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import spacing from "styles/spacing";

interface InformationBoxProps {
  children?: React.ReactElement | React.ReactElement[];
}

const InformationBoxStyled = styled.div`
  background-color: ${color.grey1};
  border: 1px solid ${color.grey3};
  width: 500px;
  min-height: 100px;
  padding: ${spacing.xs} ${spacing.l};
  margin-bottom: 200px;
`;

export default function InformationBoxComponent({ children }: InformationBoxProps) {
  return <InformationBoxStyled>{children}</InformationBoxStyled>;
}
