import * as React from "react";
import styled from "styled-components";
import fontWeight from "styles/fontWeight";

const FONT_SIZE = "5mm";

const PADDING_VERTICAL = "15mm";
const PADDING_HORIZONTAL = "10mm";

const SloganTextStyled = styled.div`
  padding: ${PADDING_VERTICAL} ${PADDING_HORIZONTAL};
  font-weight: ${fontWeight.thin};
  font-size: ${FONT_SIZE};
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export function SloganText() {
  return <SloganTextStyled>Protect your things with pings.</SloganTextStyled>;
}
