import * as React from "react";
import styled from "styled-components";
import { A6_HEIGHT, A6_WIDTH } from "./PrintA6Page";

interface A6SideProps {
  children?: React.ReactElement;
  page: 1 | 2;
}

const A6SideStyled = styled.svg`
  display: inline-block;
`;

export default function A6SideComponent({ children, page }: A6SideProps) {
  return (
    <A6SideStyled x={(page === 1 ? 0 : A6_WIDTH / 2) - A6_WIDTH / 4} y={0} viewBox={`0 0 ${A6_WIDTH / 2} ${A6_HEIGHT}`}>
      {children}
    </A6SideStyled>
  );
}
