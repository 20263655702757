import { ReactComponent as KeyboardArrowRight } from "assets/icons/keyboard-arrow-right.svg";
import { useIsMobile } from "hooks/use-is-mobile";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import spacing from "styles/spacing";

interface TableRowProps {
  children?: React.ReactElement[] | React.ReactElement;
  onRowClick?: () => void;
  emptyOnClick?: boolean;
}

const TableRowStyled = styled.tr`
  position: relative;

  &:not(:last-child) {
    & > td {
      border-bottom: 1px solid ${color.grey3};
    }
  }
`;

const ActionIconStyled = styled.td`
  padding: 0 ${spacing.xs};
  cursor: pointer;
`;

const EmptyActionIconStyled = styled.th``;

export default function TableRowComponent({ children, onRowClick, emptyOnClick }: TableRowProps) {
  const isMobile = useIsMobile();

  return (
    <TableRowStyled>
      {children}
      {onRowClick && !isMobile && (
        <ActionIconStyled onClick={onRowClick}>
          <KeyboardArrowRight />
        </ActionIconStyled>
      )}
      {emptyOnClick === true && <EmptyActionIconStyled></EmptyActionIconStyled>}
    </TableRowStyled>
  );
}
