import * as React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Login from "./Login";
import authCookies from "authCookies/index";
import { AuthenticationContext } from "context/authentication";
import Dashboard from "./Dashboard";
import ShippingLabel from "./Print/ShippingLabel";
import Orders from "./Orders";
import Products from "./Products";
import Design from "./Products/Design";
import Print from "./Products/Print";

const AdminPageStyled = styled.div`
  min-height: 100%;
  width: 100%;
`;

export default function AdminPageComponent() {
  const [jwt, setJWT] = React.useState<string>("");
  const navigate = useNavigate();
  React.useEffect(() => {
    const jwt = authCookies.get("jwt");
    if (!jwt) {
      navigate("/admin/login");
    }
  }, []);

  const handleOnSuccess = (newJwt: string) => {
    authCookies.set("jwt", newJwt);
    setJWT(newJwt);
    navigate("/admin");
  };

  return (
    <AuthenticationContext.Provider value={jwt}>
      <AdminPageStyled>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/print/shipping-label" element={<ShippingLabel />} />
          <Route path="/products/print/*" element={<Print />} />
          <Route path="/login" element={<Login onSuccess={handleOnSuccess} />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/products/design/*" element={<Design />} />
          <Route path="/products" element={<Products />} />
        </Routes>
      </AdminPageStyled>
    </AuthenticationContext.Provider>
  );
}
