import * as React from "react";
import styled from "styled-components";

interface TableHeadProps {
  children: React.ReactElement;
}

const TableHeadStyled = styled.thead``;

export default function TableHeadComponent({ children }: TableHeadProps) {
  return <TableHeadStyled>{children}</TableHeadStyled>;
}
