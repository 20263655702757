import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import { FontStyle } from "./FontStyle";

interface PrintPageProps {
  children?: React.ReactElement;
  size?: number;
  showGuides?: boolean;
}

export const A6_WIDTH = 149; //mm
export const A6_HEIGHT = 105; //mm

const PrintPageDivStyled = styled.div`
  width: ${A6_WIDTH}mm;
  height: ${A6_HEIGHT}mm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  border: none;
`;

const PrintPageContainer = styled.svg`
  /* overflow: hidden;/ */
  /* display: inline; */
  width: ${A6_WIDTH}mm;
  min-height: ${A6_HEIGHT}mm;
  background-color: white;
  & text {
    font-family: ${fontFamily.primary};
  }
`;

export default React.forwardRef<HTMLDivElement, PrintPageProps>(function PrintPageComponent(
  { children, size = 1, showGuides = false },
  ref
) {
  return (
    <PrintPageDivStyled ref={ref} style={{ transform: `scale(${size * 100}%)` }}>
      <PrintPageContainer id="print_svg" viewBox={`0 0 ${A6_WIDTH} ${A6_HEIGHT}`}>
        <FontStyle />
        {children}
        {showGuides && (
          <line x1={A6_WIDTH / 2} x2={A6_WIDTH / 2} y1={0} y2={A6_HEIGHT} strokeWidth={0.2} stroke={"pink"}></line>
        )}
      </PrintPageContainer>
    </PrintPageDivStyled>
  );
});
