import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import Badge from "components/atoms/Badge";
import TableBody from "components/atoms/Table/TableBody";
import TableData from "components/atoms/Table/TableData/TableData";
import TableHead from "components/atoms/Table/TableHead";
import TableHeader from "components/atoms/Table/TableHeader/TableHeader";
import TableRow from "components/atoms/Table/TableRow";
import TableRowBadge from "components/atoms/Table/TableRowBadge";
import capitalize from "functions/capitalize";
import formatCurrency from "functions/format-currency";
import { useIsMobile } from "hooks/use-is-mobile";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import color from "styles/color";
import spacing from "styles/spacing";
import { AppOrderListItem } from "types/App/Order";
import format from "date-fns/format";
import TableCheckBox from "components/atoms/Table/TableCheckBox";

interface OrdersTableProps {
  data: AppOrderListItem[];
  selection: string[];
  setSelection: (newValue: string[]) => void;
  onItemClick: (id: string) => void;
}

const OrdersTableStyled = styled.table`
  width: 100%;

  & .currency {
    & > p {
      margin-top: ${spacing.xxs};
      color: rgba(0, 0, 0, 0.4);
    }
  }
`;

const OrdersTableWrapperStyled = styled.div`
  width: 100%;
  max-height: 80vh;
  padding: 0 ${spacing.m};
  overflow-x: auto;
  & th {
    position: sticky;
    background-color: ${color.white};
    top: 0;
    z-index: 1;
  }
  & thead > tr {
    width: 100%;
  }
  & td {
  }
`;

const columnHelper = createColumnHelper<AppOrderListItem>();

export default function OrdersTableComponent({ data, selection, setSelection, onItemClick }: OrdersTableProps) {
  const isMobile = useIsMobile();
  const navigation = useNavigate();

  const handleOnSelectChange = (id: string, value: boolean) => {
    console.log("setting", id, value);
    if (value === true) {
      setSelection([...selection, id]);
    } else {
      setSelection(selection.filter(item => item !== id));
    }
  };

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "",
        cell: info => (
          <TableCheckBox
            onChange={(value: boolean) => {
              handleOnSelectChange(info.getValue(), value);
            }}
            id={info.getValue()}
            value={selection.includes(info.getValue())}
          />
        ),
        footer: info => info.column.id,
      }),
      columnHelper.accessor("customer_details", {
        header: "Name",
        cell: info => <b>{capitalize(info.getValue()?.name ? info.getValue()?.name : "")}</b>,
        footer: info => info.column.id,
      }),
      columnHelper.accessor("amount_total", {
        header: "Amount",
        cell: info => <b>€{formatCurrency(info.getValue(), ".")}</b>,
        footer: info => info.column.id,
      }),
      columnHelper.accessor("payment_intent", {
        header: "Payment",
        cell: info =>
          info.getValue()?.status ? (
            <Badge
              varient={info.getValue().status === "succeeded" ? "success" : undefined}
              text={info.getValue().status ?? ""}
            ></Badge>
          ) : undefined,
        footer: info => info.column.id,
      }),
      columnHelper.accessor("payment_intent", {
        header: "Shipping",
        cell: info => (info.getValue()?.metadata?.status ? info.getValue()?.metadata?.status : "Waiting"),
        footer: info => info.column.id,
      }),
      columnHelper.accessor("payment_intent", {
        header: "Batch No",
        cell: info => (info.getValue()?.metadata?.batch ? info.getValue()?.metadata?.batch : "-"),
        footer: info => info.column.id,
      }),
      columnHelper.accessor("created", {
        header: "Created",
        cell: info => format(new Date(0).setUTCSeconds(info.getValue()), "dd-MM-yyyy HH:mm"),
        footer: info => info.column.id,
      }),
      columnHelper.accessor("id", {
        header: "ID",
        cell: info => (info.getValue() ? info.getValue().slice(0, 15) : "-"),
        footer: info => info.column.id,
      }),
    ],
    [selection]
  );

  const mobileColumns = [columns[0], columns[1], columns[2]];

  const table = useReactTable({
    data,
    columns: isMobile ? mobileColumns : columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const onRowClick = React.useCallback((data: AppOrderListItem) => {
    // console.log("navigate");
    onItemClick(data.id);
    // navigation(`/admin/order/${data.id}`);
  }, []);

  return (
    <OrdersTableWrapperStyled>
      <OrdersTableStyled cellSpacing="0">
        <TableHead>
          <>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id} emptyOnClick>
                {headerGroup.headers.map(header => (
                  <TableHeader paddingSize="small" key={header.id}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeader>
                ))}
              </TableRow>
            ))}
          </>
        </TableHead>
        <TableBody>
          <>
            {table.getRowModel().rows.map(row => {
              return (
                <>
                  {isMobile && (
                    <TableRowBadge
                      colSpan={4}
                      onRowClick={() => onRowClick(row.original)}
                      badgeElement={<Badge text={row.original?.status} />}
                    ></TableRowBadge>
                  )}
                  <TableRow key={row.id} onRowClick={() => onRowClick(row.original)}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <TableData paddingSize="small" key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableData>
                      );
                    })}
                  </TableRow>
                </>
              );
            })}
          </>
        </TableBody>
      </OrdersTableStyled>
    </OrdersTableWrapperStyled>
  );
}
