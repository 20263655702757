import { A6_SIDE_HORIZONTAL_PADDING, A6_SIDE_VERTICAL_PADDING } from "pages/Admin/Print/components/A6SideHTML";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import TitleBody from "./TitleBody";

const ACTIVATE_BOX_HEIGHT = "10mm";
const BORDER_SIZE = "0.2mm";
const BORDER_COLOR = color.grey3;

const ADDITIONAL_BOTTOM_PADDING = "2mm";

interface ActivateProps {
  code?: string;
}

const ActivateStyled = styled.div`
  padding: calc(${A6_SIDE_VERTICAL_PADDING} + ${ADDITIONAL_BOTTOM_PADDING}) ${A6_SIDE_HORIZONTAL_PADDING};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const ActivateCodeStyled = styled.div`
  width: 100%;
  height: ${ACTIVATE_BOX_HEIGHT};
  background-color: white;
  border: ${BORDER_SIZE} solid ${BORDER_COLOR};
  text-align: center;
  line-height: ${ACTIVATE_BOX_HEIGHT};
  letter-spacing: 0.3mm;
  font-size: 3.5mm;
`;

export default function Activate({ code }: ActivateProps) {
  return (
    <ActivateStyled>
      {code === undefined && (
        <TitleBody
          title="Activate"
          body={
            <>
              Download the app from <b>www.pingify.io</b> and use the activation code to add your pings.
            </>
          }
        ></TitleBody>
      )}
      <ActivateCodeStyled style={code !== undefined ? { border: "none" } : undefined}>{code}</ActivateCodeStyled>
    </ActivateStyled>
  );
}
