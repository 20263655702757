import * as React from "react";
import styled from "styled-components";
import PrintA3Page from "../../../Print/components/PrintA3Page";
import { useReactToPrint } from "react-to-print";
import Button from "components/atoms/Button/Button";
import spacing from "styles/spacing";
import Heading from "components/atoms/Heading/Heading";
import BottomBar from "../../../Print/components/BottomBar";
import generateId from "functions/generate-id";
import Tag, { TAG_BORDER_COLOR, TAG_BORDER_WIDTH } from "../../components/Tag";
import InformationBox from "pages/Admin/Print/components/InformationBox";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Sticker4Pack, { STICKER_PACK_HEIGHT, STICKER_PACK_WIDTH } from "../../components/Sticker4Pack";
import Spacer from "components/atoms/Spacer/Spacer";
import { toPng } from "html-to-image";
import shorthash from "shorthash";
import * as svg from "save-svg-as-png";
import SvgSaver from "svgsaver";

const svgsaver = new SvgSaver();

const StickersA4Styled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  align-items: center;
`;

const PrintContentContainer = styled.div`
  /* flex: 1; */
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: ${spacing.l};
  &.printable {
    display: flex;
  }
`;

const FILE_NAME = "StickersA3";

const ROW_LENGTH = 5;

export default function StickersA4Component() {
  const componentRef = React.useRef(null);

  const [showCut, setShowCut] = React.useState(false);
  const [idList, setIdList] = React.useState(
    new Array(30)
      .fill(null)
      .map(() => [generateId(), generateId(), generateId(), generateId()] as [string, string, string, string])
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const exportSVG = () => {
    //notihng
    svgsaver.asSvg(document.getElementById("print_svg"));
  };

  const exportPNG = () => {
    console.log("export", componentRef);
    if (componentRef.current === null) {
      return;
    }
    svg.saveSvgAsPng(document.getElementById("print_svg"), `${FILE_NAME}_${shorthash.unique(idList.join())}.png`, {
      encoderOptions: 1,
      scale: 10,
    });
  };

  return (
    <StickersA4Styled>
      <Heading as="h1" varient="h3">
        Stickers A3
      </Heading>
      <PrintContentContainer>
        <PrintA3Page ref={componentRef}>
          <>
            {idList.map((ids, index) => {
              const x = (index % ROW_LENGTH) * STICKER_PACK_WIDTH;
              const y = Math.floor(index / ROW_LENGTH) * STICKER_PACK_HEIGHT;
              if (index + 1 < ROW_LENGTH) {
                console.log("x,y", (index + 1) % ROW_LENGTH, (index + 1) / ROW_LENGTH, y);
              }
              return (
                <React.Fragment key={`${ids[0]}`}>
                  <Sticker4Pack xOrigin={x} yOrigin={y} idList={ids} showCut={showCut} />
                </React.Fragment>
              );
            })}
          </>
        </PrintA3Page>
      </PrintContentContainer>
      <InformationBox>
        <Heading as="h2" varient="h4">
          Creating template file (from scratch)
        </Heading>
        <Paragraph>
          <ul>
            <li>Export SVG with lines and open with affinity</li>
            <li>Create</li>
            <li>
              Create spot color:
              <ul>
                <li>
                  <a
                    target="blank"
                    href="https://forum.affinity.serif.com/index.php?/topic/122865-how-can-i-create-a-cut-line-for-stickers-wbleed-and-such-in-designer/&do=findComment&comment=671599"
                  >
                    Create global spot swatch <b>Die cut</b> color with affinity.
                  </a>
                </li>
                <li>
                  <a
                    target="blank"
                    href="https://affinity.help/designer/en-US.lproj/index.html?page=pages/Clr/spotClr.html?title=Spot%20colors"
                  >
                    Affinity guide.
                  </a>
                </li>
              </ul>
            </li>
            <li>Import image and place behind.</li>
            <li>
              Add each to a layer, <b>Cutting_Line_Layer</b> and <b>Printing_layer</b>
            </li>
            <li>Export as {"PDF/X-1a:2003"}</li>
            <li>
              Test export by uploading to{" "}
              <a target="blank" href="https://stickerapp.nl/stickers#!">
                StickerApp.nl
              </a>
            </li>
          </ul>
        </Paragraph>
        <Heading as="h2" varient="h4">
          Creating multiple sheets of stickers
        </Heading>
        <Paragraph>
          <ul>
            <li>Replace image in affinity template file</li>
            <li>Export as {"PDF/X-1a:2003"}</li>
          </ul>
        </Paragraph>
        <Heading as="h2" varient="h4">
          Ordering
        </Heading>
        <Paragraph>
          <ul>
            <li>Order via domingstickers.nl</li>
            <li>A3, Vorm: Vrije vorm, Ondergrond: Wit permanent</li>
          </ul>
        </Paragraph>
      </InformationBox>
      <BottomBar>
        {showCut ? (
          <Button
            onClick={() => {
              exportSVG();
            }}
          >
            Export Cut Line (svg)
          </Button>
        ) : (
          <Button
            onClick={() => {
              console.log("export png");
              exportPNG();
              setIdList(
                new Array(30)
                  .fill(null)
                  .map(
                    () => [generateId(), generateId(), generateId(), generateId()] as [string, string, string, string]
                  )
              );
            }}
          >
            Export Stickers (png)
          </Button>
        )}
        <Spacer width={spacing.m} />
        <Button
          varient="secondary"
          onClick={() => {
            setShowCut(!showCut);
          }}
        >
          {`Toggle: ${showCut ? "Cut lines" : "Stickers"}`}
        </Button>
      </BottomBar>
    </StickersA4Styled>
  );
}
