import { AppOrderListItem } from "../types/App/Order";
import Connection from "./Connection";

export async function getAll() {
  const response = await Connection.getAuthorized(`admin/order`);
  return response.data as AppOrderListItem[];
}

export async function getAllQuery(ids: string[]) {
  const response = await Connection.getAuthorized(`admin/order/${ids.join(",")}`);
  return response.data as AppOrderListItem[];
}

export async function updateStatus(orderId: string, status: string) {
  const response = await Connection.putAuthorized(`admin/order/${orderId}`, {
    status,
  });
  return response.data as AppOrderListItem[];
}

export async function updateBatch(orderId: string, batch: string) {
  const response = await Connection.putAuthorized(`admin/order/${orderId}`, {
    batch,
  });
  return response.data as AppOrderListItem[];
}
