import * as React from "react";
import styled from "styled-components";

interface TableBodyProps {
  children: React.ReactElement;
}

const TableBodyStyled = styled.tbody``;

export default function TableBodyComponent({ children }: TableBodyProps) {
  return <TableBodyStyled>{children}</TableBodyStyled>;
}
