import Loader from "components/atoms/LoaderSimple";
import * as React from "react";
import styled from "styled-components";

const CardLoaderStyled = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function CardLoaderComponent() {
  return (
    <CardLoaderStyled>
      <Loader size={"100px"} />
    </CardLoaderStyled>
  );
}
