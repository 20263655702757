import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import { FieldRenderProps } from "react-final-form";
import TextArea from "components/atoms/Form/TextArea";
import Label from "components/atoms/Form/Label/Label";
import InputNote from "components/atoms/Form/InputNote/InputNote";
import color from "styles/color";

interface MultiLineInputProps extends FieldRenderProps<string, HTMLInputElement> {
  testID?: string;
  icon?: React.ReactElement;
  note?: string;
  compact?: boolean;
}

const MAX_INPUT_WIDTH = "500px";

const MultiLineInputStyled = styled.div`
  max-width: ${MAX_INPUT_WIDTH};
`;

MultiLineInputComponent.testID = "MultiLineInput";

export default function MultiLineInputComponent({
  input: { type = "text", ...inputProps },
  label,
  note,
  compact = false,
  ...props
}: MultiLineInputProps) {
  const errorMessage = props.meta.touched && props.meta.error;

  return (
    <MultiLineInputStyled data-testid={composeTestID(MultiLineInputComponent.testID, props.testID)}>
      <Label htmlFor={inputProps.name}>{label}</Label>
      <TextArea {...inputProps} {...props} value={inputProps.value} onChange={inputProps.onChange}></TextArea>
      {compact === false || note !== undefined || typeof errorMessage === "string" ? (
        <InputNote htmlFor={inputProps.name} color={!errorMessage ? undefined : color.red}>
          {!errorMessage ? note : errorMessage}
        </InputNote>
      ) : null}
    </MultiLineInputStyled>
  );
}
