import React from "react";
import PropTypes, { string } from "prop-types";
import { QRCode } from "react-qr-svg";
// import "./tag.style.css";

interface KeyringWoodProps {
  id: string;
  offsetY?: number;
  offsetX?: number;
  totalWidthPx?: number;
  totalHeightPx?: number;
  totalWidthMM?: number;
  totalHeightMM?: number;
}

const QR_CODE_SIZE = 10;

const WIDTH = 25;
const HEIGHT = 25;

const OFFSET_HEIGHT = 0;

export const KeyringWood = ({ id }: KeyringWoodProps) => {
  return (
    <svg
      id="tag_test"
      width={`${WIDTH}mm`}
      height={`${HEIGHT}mm`}
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      style={{ backgroundColor: "white" }}
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: `
        .lost_text{
            font-size:0.9mm;
            font-weight: 600; 
            font-family:'Lato';
        }
        .pingify_text{
            font-size:0.55mm;
            font-weight: 600; 
            font-family:'Lato';
        }
        .id_text{
            font-size:0.8mm;
            font-weight: 600; 
            font-family:'Lato';
        }
  `,
        }}
      ></style>
      <g>
        <QRCode
          bgColor="rgba(255,255,255,1)"
          fgColor="rgba(0,0,0,1)"
          level="L"
          fill="none"
          width={QR_CODE_SIZE}
          x={WIDTH / 2 - QR_CODE_SIZE / 2}
          y={0 + OFFSET_HEIGHT}
          value={`pingify.io/${id}`}
        />
      </g>
      <text x={WIDTH / 2} y={6.5 + OFFSET_HEIGHT} textAnchor="middle" className="lost_text">
        Lost?
      </text>
      <text x={WIDTH / 2} y={19.6 + OFFSET_HEIGHT} textAnchor="middle" className="pingify_text">
        pingify.io/
      </text>
      <text textAnchor="middle" x={WIDTH / 2} y={22.5 + OFFSET_HEIGHT} className="id_text">
        {id}
      </text>
    </svg>
  );
};
