import Button from "components/atoms/Button/Button";
import DropDown from "components/atoms/Form/DropDown";
import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Spacer from "components/atoms/Spacer/Spacer";
import Switch from "components/atoms/Switch";
import TextInput from "components/molecules/Form/TextInput/TextInput";
import { wrapHandler } from "framer-motion";
import formatCurrency from "functions/format-currency";
import useUpdateOrderBatch from "hooks/query/use-update-order-batch";
import useUpdateOrderStatus from "hooks/query/use-update-order-status";
import * as React from "react";
import styled from "styled-components";
import spacing from "styles/spacing";
import { APIOrderListItem } from "types/API/Order";

interface OrderDetailProps {
  orderData: APIOrderListItem[];
  id: string | false;
}

const OrderDetailStyled = styled.div`
  height: 400px;
  overflow-y: scroll;
  max-width: 450px;
  p {
    word-wrap: break-word;
  }
`;

const SwitchContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function OrderDetailComponent({ orderData, id }: OrderDetailProps) {
  const [currentLocation, setCurrentLocation] = React.useState("0");
  const [batchNumber, setBatchNumber] = React.useState("");
  const [newStatus, setNewStatus] = React.useState("SHIPPED");
  const { mutateAsync: mutateUpdateOrderStatusAsync, isLoading: isUpdatingOrderStatus } = useUpdateOrderStatus();
  const { mutateAsync: mutateUpdateOrderBatchAsync, isLoading: isUpdatingOrderBatch } = useUpdateOrderBatch();

  const orderItem = React.useMemo(() => {
    return orderData.find(item => item.id === id);
  }, []);

  const handleOnUpdateOrderStatus = async () => {
    if (typeof orderItem?.payment_intent.id !== "string") return;
    await mutateUpdateOrderStatusAsync({ id: orderItem?.payment_intent.id, status: newStatus });
  };

  const handleOnUpdateOrderBatch = async () => {
    if (typeof orderItem?.payment_intent.id !== "string") return;
    await mutateUpdateOrderBatchAsync({ id: orderItem?.payment_intent.id, batch: batchNumber });
  };

  return (
    <OrderDetailStyled>
      {orderItem && (
        <>
          <SwitchContainerStyled>
            <Switch
              value={currentLocation}
              onChange={setCurrentLocation}
              options={[
                {
                  id: "0",
                  label: "Details",
                },
                {
                  id: "1",
                  label: "Status",
                },
                {
                  id: "2",
                  label: "Order",
                },
              ]}
            />
          </SwitchContainerStyled>
          {currentLocation === "0" && (
            <>
              <Paragraph>
                Name: {orderItem.customer_details.name}
                <br />
                Email: {orderItem.customer_details.email}
              </Paragraph>
              <Paragraph>
                Status: {orderItem.payment_intent?.metadata?.status ?? "Waiting"} <br />
                Batch No: {orderItem.payment_intent?.metadata?.batch ?? "-"} <br />
                ID: {orderItem.id ?? "-"}
              </Paragraph>
              <Paragraph>
                Amount: {orderItem.amount_total ? `€${formatCurrency(orderItem.amount_total, ".")}` : "-"}
              </Paragraph>
              <Paragraph>
                Address Line 1: {orderItem.shipping_details.address.line1 ?? "-"}
                <br />
                Address Line 2: {orderItem.shipping_details.address.line2 ?? "-"}
                <br />
                City: {orderItem.shipping_details.address.city ?? "-"}
                <br />
                Post Code: {orderItem.shipping_details.address.postal_code ?? "-"}
                <br />
                Country: {orderItem.shipping_details.address.country ?? "-"}
                <br />
              </Paragraph>
            </>
          )}
          {currentLocation === "1" && (
            <>
              <Paragraph>Current Status: {orderItem.payment_intent?.metadata?.status ?? "Waiting"}</Paragraph>
              <DropDown
                options={[
                  {
                    value: "Shipped",
                    label: "Shipped",
                  },
                  {
                    value: "Packing",
                    label: "Packing",
                  },
                  {
                    value: "Created",
                    label: "Created",
                  },
                ]}
                defaultValue="Created"
                onChange={setNewStatus}
                value={newStatus}
              />
              <Spacer height={spacing.m} />
              <Button
                onClick={handleOnUpdateOrderStatus}
                disabled={newStatus === orderItem.payment_intent?.metadata?.status}
                loading={isUpdatingOrderStatus}
              >
                Update Status
              </Button>
            </>
          )}
          {currentLocation === "2" && (
            <>
              {orderItem.line_items.data.map(item => (
                <Paragraph key={item.id}>
                  {item?.description} x {item?.quantity}
                  <br />
                  Cost: €{formatCurrency(item.amount_total, ".")}
                </Paragraph>
              ))}
              <Paragraph>Batch No: {orderItem.payment_intent?.metadata?.batch ?? "-"}</Paragraph>
              <Paragraph>
                <TextInput
                  label="Update Batch No"
                  meta={{}}
                  aria="batchNo"
                  placeholder={"New Batch No"}
                  input={{
                    type: "string",
                    name: "batchNo",
                    onChange: val => {
                      setBatchNumber(val.target.value);
                    },
                    value: batchNumber,
                    onBlur: () => {
                      console.log("");
                    },
                    onFocus: () => {
                      console.log("");
                    },
                  }}
                ></TextInput>
                <Button
                  loading={isUpdatingOrderBatch}
                  onClick={handleOnUpdateOrderBatch}
                  disabled={batchNumber === "" || batchNumber === orderItem.payment_intent?.metadata?.batch}
                >
                  Update Batch Number
                </Button>
              </Paragraph>
            </>
          )}
        </>
      )}
    </OrderDetailStyled>
  );
}
