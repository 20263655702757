import { useQuery } from "@tanstack/react-query";

import API from "api";
import { AppOrderListItem } from "types/App/Order";

useOrders.queryKey = "orders";

export default function useOrders() {
  return useQuery(
    [useOrders.queryKey],
    () => {
      return API.Order.getAll() as Promise<AppOrderListItem[]>;
    },
    { notifyOnChangeProps: "all" }
  );
}
