import * as React from "react";
import styled from "styled-components";
import fontFamily from "styles/fontFamily";

interface TagProps {
  id: string;
  reverse?: boolean;
}

export const TAG_BORDER_COLOR = `rgba(0,0,0,0.1)`;
export const TAG_BORDER_WIDTH = `0.5mm`;

const TAG_WIDTH = 69; // 70 does not allow for border on width
const TAG_HEIGHT = 13;
const HOLE_SIZE = 2;
const HOLE_DISTANCE = 7.5;
const PINGY_TEXT_WIDTH = 15.5;

const TEXT_LOST_MARGIN = 15.5;
const TEXT_PINGIFY_MARGIN = 14.5;
const TEXT_LOST_GAP = 1;
const TEXT_PINGIFY_GAP = 1;
const TEXT_OFFSET_LOST = 1;
const TEXT_OFFSET_PINGIFY = -1;

const HOLE_COLOR = TAG_BORDER_COLOR;

const SEPERATOR_COLOR = "transparent";

const TagStyled = styled.div`
  width: ${TAG_WIDTH}mm;
  height: ${TAG_HEIGHT}mm;
  background-color: white;
`;

const TagContainer = styled.div`
  border-bottom: ${TAG_BORDER_WIDTH} solid ${TAG_BORDER_COLOR};
  border-right: ${TAG_BORDER_WIDTH} solid ${TAG_BORDER_COLOR};
  display: inline-block;
`;

export default function TagComponent({ id, reverse }: TagProps) {
  return (
    <TagContainer>
      <TagStyled>
        <svg id="tag" width={`${TAG_WIDTH}mm`} height={`${TAG_HEIGHT}mm`} viewBox={`0 0 ${TAG_WIDTH} ${TAG_HEIGHT}`}>
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html: `
            *{
              font-family:${fontFamily.primary};
            }
            .text_lost{
              font-weight: 600;
              font-size: 1.5mm;
            }
            .text_return_at{
              font-weight: 400;
              font-size: 0.8mm;
              dominant-baseline: hanging;
            }
            .text_pingify{
              font-weight: 600;
              font-size: 0.9mm;
            }
            .text_id{
              font-weight: 600;
              font-size: 1.3mm;
              dominant-baseline: hanging;
            }
            `,
            }}
          ></style>
          {reverse ? (
            <>
              <g>
                <circle className="hole_1" fill={HOLE_COLOR} cx={HOLE_DISTANCE} cy={TAG_HEIGHT / 2} r={HOLE_SIZE / 2} />
                <circle
                  className="hole_2"
                  fill={HOLE_COLOR}
                  cx={TAG_WIDTH - HOLE_DISTANCE}
                  cy={TAG_HEIGHT / 2}
                  r={HOLE_SIZE / 2}
                />
              </g>
              <g transform={`translate(0,${TEXT_OFFSET_PINGIFY})`}>
                <text
                  textAnchor="start"
                  x={TEXT_PINGIFY_MARGIN}
                  y={TAG_HEIGHT / 2 - TEXT_PINGIFY_GAP / 2}
                  className="text_pingify"
                >
                  pingify.io/
                </text>
                <text
                  textAnchor="middle"
                  x={0 + TEXT_PINGIFY_MARGIN + PINGY_TEXT_WIDTH / 2}
                  y={TAG_HEIGHT / 2 + TEXT_PINGIFY_GAP / 2}
                  className="text_id"
                >
                  {id}
                </text>
              </g>
              <line x1={TAG_WIDTH / 2} y1={0} x2={TAG_WIDTH / 2} y2={TAG_HEIGHT} stroke={SEPERATOR_COLOR} />
              <g transform={`translate(0,${TEXT_OFFSET_LOST})`}>
                <text
                  textAnchor="end"
                  x={TAG_WIDTH - TEXT_LOST_MARGIN}
                  y={TAG_HEIGHT / 2 - TEXT_LOST_GAP / 2}
                  className="text_lost"
                >
                  Lost?
                </text>
                <text
                  textAnchor="end"
                  x={TAG_WIDTH - TEXT_LOST_MARGIN}
                  y={TAG_HEIGHT / 2 + TEXT_LOST_GAP / 2}
                  className="text_return_at"
                >
                  Return at
                </text>
              </g>
            </>
          ) : (
            <>
              <g>
                <circle className="hole_1" fill={HOLE_COLOR} cx={HOLE_DISTANCE} cy={TAG_HEIGHT / 2} r={HOLE_SIZE / 2} />
                <circle
                  className="hole_2"
                  fill={HOLE_COLOR}
                  cx={TAG_WIDTH - HOLE_DISTANCE}
                  cy={TAG_HEIGHT / 2}
                  r={HOLE_SIZE / 2}
                />
              </g>
              <g transform={`translate(0,${TEXT_OFFSET_LOST})`}>
                <text
                  textAnchor="start"
                  x={TEXT_LOST_MARGIN}
                  y={TAG_HEIGHT / 2 - TEXT_LOST_GAP / 2}
                  className="text_lost"
                >
                  Lost?
                </text>
                <text
                  textAnchor="start"
                  x={TEXT_LOST_MARGIN}
                  y={TAG_HEIGHT / 2 + TEXT_LOST_GAP / 2}
                  className="text_return_at"
                >
                  Return at
                </text>
              </g>
              <line x1={TAG_WIDTH / 2} y1={0} x2={TAG_WIDTH / 2} y2={TAG_HEIGHT} stroke={SEPERATOR_COLOR} />
              <g transform={`translate(0,${TEXT_OFFSET_PINGIFY})`}>
                <text
                  textAnchor="end"
                  x={TAG_WIDTH - TEXT_PINGIFY_MARGIN}
                  y={TAG_HEIGHT / 2 - TEXT_PINGIFY_GAP / 2}
                  className="text_pingify"
                >
                  pingify.io/
                </text>
                <text
                  textAnchor="middle"
                  x={TAG_WIDTH - TEXT_PINGIFY_MARGIN - PINGY_TEXT_WIDTH / 2}
                  y={TAG_HEIGHT / 2 + TEXT_PINGIFY_GAP / 2}
                  className="text_id"
                >
                  {id}
                </text>
              </g>
            </>
          )}
        </svg>
      </TagStyled>
    </TagContainer>
  );
}
