import React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

export type BadgeVarients = "default" | "success" | "error" | "info" | "action";

interface BadgeProps {
  text: string;
  varient?: BadgeVarients;
}

const BadgeStyled = styled.span`
  padding: ${spacing.xxs} ${spacing.xs};
  border-radius: 20px;
  color: ${color.greyDark1};
  background-color: ${color.grey2};
  text-align: center;
  font-size: 12px;
  font-weight: ${fontWeight.semiBold};
  margin: 0 ${spacing.xs};
  font-family: ${fontFamily.primary};

  &.success {
    color: ${color.green};
    background-color: ${color.lightGreen};
  }

  &.error {
    color: ${color.red};
    background-color: ${color.lightRed};
  }

  &.info {
    color: ${color.orange};
    background-color: ${color.lightOrange};
  }

  &.action {
    color: ${color.purple};
    background-color: ${color.lightPurple};
  }
`;

BadgeComponent.testID = "Badge";

export default function BadgeComponent({ text, varient }: BadgeProps) {
  return (
    <BadgeStyled data-testid={BadgeComponent.testID} className={varient}>
      {text}
    </BadgeStyled>
  );
}
