import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Link from "components/atoms/Inline/Link";
import DisplayTextContainer from "components/molecules/Display/DisplayTextContainer";
import * as React from "react";
import styled from "styled-components";
import { ReactComponent as IconBackIosSVG } from "assets/icons/icon-back-ios.svg";
import { useNavigate } from "react-router-dom";
import Meta from "components/atoms/Meta";

const PrivacyStatementStyled = styled.div``;

const BackButtonStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
`;

export default function PrivacyStatementComponent() {
  const navigate = useNavigate();

  const handleOnBack = () => {
    navigate("/");
  };

  return (
    <PrivacyStatementStyled>
      <Meta
        title={`User Data Deletion`}
        description={`Request user data to be removed`}
        ogUrl={"http://pingify.io/delete-data"}
      />
      <BackButtonStyled>
        <Button onClick={handleOnBack} layout="icon-left" varient="light" icon={<IconBackIosSVG />}>
          Pingify.io
        </Button>
      </BackButtonStyled>
      <DisplayTextContainer>
        <Heading as="h1">Request user data to be removed</Heading>

        <Heading as="h2">Without Login (Manual)</Heading>

        <p>
          To request your user data to be removed without Login you can send an email to{" "}
          <a href="mailto:data-removal@pingify.io">data-removal@pingify.io</a> with the email of the account you want
          removing.
        </p>

        <Heading as="h2">In App (Automatically)</Heading>

        <p>Remove your user data and anything associated when logged in the pingify app.</p>
        <p>
          Navigate to:{" "}
          <b>
            Account (bottom right menu button) {"->"} Settings {"->"} Delete Account
          </b>
        </p>
      </DisplayTextContainer>
    </PrivacyStatementStyled>
  );
}
