import Paragraph from "components/atoms/Paragraph/Paragraph";
import Container from "components/atoms/Container/Container";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import color from "styles/color";
import fontSize from "styles/fontSize";
import media from "styles/media";

const FooterStyled = styled.div`
  border-top: 1px solid ${color.grey2};
  display: flex;
  flex-direction: column;
`;

const FooterContainerStyled = styled.div`
  margin-top: 0px;
  margin-bottom: 0px;
`;

const FooterContentStyled = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  min-height: 299px;
  padding: 24px 0px;
  @media ${media.mobile} {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 24px 0px;
  }
  @media ${media.desktop} {
  }
`;

const FooterNavigationStyled = styled.div`
  flex: 1;
  height: 100%;
`;

const FooterUlStyled = styled.ul`
  min-width: 150px;
  display: inline-block;
  vertical-align: top;
  padding-bottom: 24px;
  padding-left: 0px;
`;

const FooterLiStyled = styled.li`
  list-style-type: none;
  :first-child {
    padding-bottom: 20px;
    & a {
      color: ${color.primary};
      font-size: ${fontSize.h4};
    }
  }
  padding: 8px 0px;
  & a {
    color: ${color.greyDark1};
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: ${color.primary};
    }
  }
`;

const FooterNoteStyled = styled.div`
  border-top: 1px solid ${color.grey2};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  @media ${media.mobile} {
    min-height: 60px;
  }
  @media ${media.desktop} {
    min-height: 80px;
  }
`;

const FooterLeftStyled = styled.p`
  font-size: 12px;
  white-space: nowrap;
`;

const FooterRightStyled = styled.p`
  font-size: 12px;
  white-space: nowrap;
`;

export default function FooterComponent() {
  return (
    <FooterStyled>
      <FooterContainerStyled as={Container}>
        <FooterContentStyled className="footer__content">
          <FooterNavigationStyled className="footer__navigation">
            <FooterUlStyled>
              <FooterLiStyled>
                <Link to="home">Home</Link>
              </FooterLiStyled>
              <FooterLiStyled>
                <Link to="how-it-works">How it works</Link>
              </FooterLiStyled>
              <FooterLiStyled>
                <Link to="products">Products</Link>
              </FooterLiStyled>
              <FooterLiStyled>
                <Link to="terms-and-conditions">Terms and conditions</Link>
              </FooterLiStyled>
              <FooterLiStyled>
                <Link to="privacy-policy">Privacy policy</Link>
              </FooterLiStyled>
              <FooterLiStyled>
                <Link to="data-removal">Data Removal</Link>
              </FooterLiStyled>
            </FooterUlStyled>
          </FooterNavigationStyled>
        </FooterContentStyled>
        <FooterNoteStyled className="footer__note">
          <FooterLeftStyled as={Paragraph} className="footer__note-left">
            Pingify.io
          </FooterLeftStyled>
          <FooterRightStyled as={Paragraph}>Simple. Subtle. Secure.</FooterRightStyled>
        </FooterNoteStyled>
      </FooterContainerStyled>
    </FooterStyled>
  );
}
