import * as React from "react";
import styled from "styled-components";
import color from "styles/color";

interface PrintPageProps {
  children?: React.ReactElement;
  size?: number;
}

const A4_WIDTH = "21cm";
const A4_HEIGHT = "29.68cm";

const PrintPageContentStyled = styled.div`
  width: ${A4_WIDTH};
  min-height: ${A4_HEIGHT};
  /* background-color: ${color.grey2}; */
  background-color: white;
`;

const PrintPageStyled = styled.div`
  /* overflow: hidden;/ */
  /* display: inline; */
  /* background-color: white; */
`;

const PrintPageContainer = styled.div`
  /* overflow: hidden;/ */
  /* display: inline; */
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
`;

export default React.forwardRef<HTMLDivElement, PrintPageProps>(function PrintPageComponent(
  { children, size = 0.5 },
  ref
) {
  return (
    <PrintPageContainer style={{ width: `calc(${A4_WIDTH} * ${size})`, height: `calc(${A4_HEIGHT} * ${size})` }}>
      <PrintPageStyled
        style={{ transform: `scale(${100 * size}%) translate(-${(100 * size) / 1}%, -${(100 * size) / 1}%)` }}
      >
        <PrintPageContentStyled className="printable" ref={ref}>
          {children}
        </PrintPageContentStyled>
      </PrintPageStyled>
    </PrintPageContainer>
  );
});
