import * as React from "react";
import styled from "styled-components";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Heading from "components/atoms/Heading/Heading";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";
import color from "styles/color";
import Spacer from "components/atoms/Spacer/Spacer";

interface IProps {
  maxHeight?: string;
  borderRadius?: string;
  width?: "fit" | "square";
  title?: string;
  body?: string;
  buttonElement?: React.ReactElement;
  svgElement?: React.ReactElement;
}

const SVG_SIZE = "200px";

const ContainerStyled = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: block;
  padding: 0 ${spacing.xl};
`;

const TextContainerStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  & h1 {
    margin-bottom: 0px;
    font-weight: ${fontWeight.bold};
    color: ${color.primary};
  }
  & p {
    margin-top: ${spacing.xs};
    text-align: center;
  }
`;

const FlexInfoComponent = ({ title, body, svgElement, buttonElement }: IProps) => {
  return (
    <ContainerStyled>
      <TextContainerStyled>
        {svgElement ? React.cloneElement(svgElement, { style: { width: SVG_SIZE, height: SVG_SIZE } }) : <></>}
        <Heading as="h1" varient="h3">
          {title}
        </Heading>
        <Paragraph>{body}</Paragraph>
        {buttonElement ? (
          <>
            <Spacer height={spacing.s} />
            {buttonElement}
          </>
        ) : (
          <></>
        )}
      </TextContainerStyled>
    </ContainerStyled>
  );
};

export default FlexInfoComponent;
