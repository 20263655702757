import Icon from "components/atoms/Icon/Icon";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  testID?: string;
  icon?: React.ReactElement;
  appendElement?: React.ReactElement;
}

const INPUT_ICON_SIZE = 20;

const InputContainerStyled = styled.div`
  border: solid ${color.grey3} 1px;
  border-radius: 3px;
  width: 100%;
  display: flex;
  overflow: hidden;
  background-color: ${color.grey2};
`;

const InputStyled = styled.input`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.input};
  font-weight: ${fontWeight.thin};
  color: ${color.black};
  padding: ${spacing.m};
  border: none;
  flex: 1;
  background-color: transparent;
  ::placeholder {
    color: ${color.greyDark1};
  }
`;

const IconStyled = styled.div`
  padding: ${spacing.m} 0px ${spacing.m} ${spacing.m};
`;

const InputComponent = React.forwardRef(({ icon, appendElement, ...props }: InputProps, ref: any) => {
  return (
    <InputContainerStyled>
      {icon && (
        <IconStyled size={INPUT_ICON_SIZE} as={Icon}>
          {icon}
        </IconStyled>
      )}
      <InputStyled ref={ref} {...props}></InputStyled>
      {appendElement}
    </InputContainerStyled>
  );
});

InputComponent.displayName = "InputComponent";

export default InputComponent;
