import Button from "components/atoms/Button/Button";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Spacer from "components/atoms/Spacer/Spacer";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import spacing from "styles/spacing";

const ProductsStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function ProductsComponent() {
  const navigate = useNavigate();
  return (
    <ProductsStyled>
      <Paragraph>Design</Paragraph>
      <Button onClick={() => navigate("design/tags")}>Tags</Button>
      <Spacer height={spacing.m} />
      <Button onClick={() => navigate("design/sticker")}>Sticker</Button>
      <Spacer height={spacing.m} />
      <Button onClick={() => navigate("design/keyring-wood")}>Keyring (wood)</Button>
      <Paragraph>Print</Paragraph>
      <Button onClick={() => navigate("print/tags")}>Tags</Button>
      <Spacer height={spacing.m} />
      <Button onClick={() => navigate("print/stickers")}>Stickers</Button>
      <Spacer height={spacing.m} />
      <Button onClick={() => navigate("print/keyring-glass")}>Keyring (Glass)</Button>
      <Spacer height={spacing.m} />
      <Button onClick={() => navigate("print/keyring-wood")}>Keyring (Wood)</Button>
      <Paragraph>Print (Package)</Paragraph>
      <Button onClick={() => navigate("print/package/keyring")}>Keyring</Button>
      <Spacer height={spacing.m} />
      <Button onClick={() => navigate("print/package/tags")}>Tags</Button>
      <Spacer height={spacing.m} />
      <Button onClick={() => navigate("print/package/stickers")}>Stickers</Button>
      <Spacer height={spacing.m} />
      <Button onClick={() => navigate("print/package/stickers")}>Activation code</Button>
      <Spacer height={spacing.xl} />
    </ProductsStyled>
  );
}
