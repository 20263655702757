import Footer from "components/organisms/Footer/Footer";
import Navigation from "pages/components/Navigation";
import React from "react";
import { Routes, Route, matchRoutes, useLocation } from "react-router-dom";
import Admin from "./Admin";
import Download from "./Download";
import Home from "./Home";
import HowItWorks from "./HowItWorks";
import Ping from "./Ping";
import PrivacyStatement from "./PrivacyStatement";
import ProductDetail from "./ProductDetail";
import Products from "./Products";
import Recover from "./Recover";
import TermsAndConditions from "./TermsAndConditions";
import Test from "./Test";
import { track } from "@amplitude/analytics-browser";
import Checkout from "./Checkout";
import DeleteData from "./DataRemoval";

const NavigationWhiteList = ["/product", "/how-it-works"];

export default function PageRoutes() {
  const location = useLocation();

  React.useEffect(() => {
    track("Navigation: Start", { location: location.pathname.length < 30 ? location.pathname : "Too Long" });
  }, []);

  const hasNavigationFooter = React.useMemo(
    () =>
      matchRoutes(
        [
          {
            id: "home",
            path: "/",
          },
          {
            id: "how-it-works",
            path: "/how-it-works",
          },
          {
            id: "products",
            path: "/products",
          },
          {
            id: "productsDetail",
            path: "/products/:id",
          },
          {
            id: "download",
            path: "/download",
          },
          {
            id: "data-removal",
            path: "/data-removal",
          },
          {
            id: "checkout",
            path: "/checkout",
          },
        ],
        location.pathname
      ) !== null,
    [location.pathname]
  );

  React.useEffect(() => {
    if (hasNavigationFooter) track(`Navigation: ${location.pathname}`);
  }, [location.pathname]);

  return (
    <>
      {hasNavigationFooter === true ? <Navigation /> : <></>}
      {/**
       * Pages
       */}
      <RoutesComponent />
      {/**
       * Pages
       */}
      {hasNavigationFooter === true ? <Footer /> : <></>}
    </>
  );
}

export function RoutesComponent() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/admin/*" element={<Admin />} />
      <Route path="/how-it-works" element={<HowItWorks />} />
      <Route path="/products" element={<Products />} />
      <Route path="/products/:id" element={<ProductDetail />} />
      <Route path="/test" element={<Test />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/data-removal" element={<DeleteData />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyStatement />} />
      <Route path="/download" element={<Download />} />
      <Route path="/recover/:key" element={<Recover />} />
      <Route path="/:id/*" element={<Ping />} />
    </Routes>
  );
}
