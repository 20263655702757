import * as React from "react";
import styled from "styled-components";
import Sticker from "../../components/Sticker";

const StickerDesignStyled = styled.div`
  transform: scale(300%);
  /* border: 1px solid green; */
`;

export default function StickerDesignComponent() {
  return (
    <StickerDesignStyled>
      <Sticker id="12345" />
    </StickerDesignStyled>
  );
}
