import BasicCard from "components/organisms/Cards/BasicCard/BasicCard";
import * as React from "react";
import styled from "styled-components";
import productsData, { ProductSlug } from "data/products";
import CardText from "components/organisms/Cards/CardText/CardText";
import { useNavigate, useParams } from "react-router-dom";
import CardImage from "components/organisms/Cards/CardImage/CardImage";
import Button from "components/atoms/Button/Button";
import { ReactComponent as IconBackIOSSVG } from "assets/icons/icon-back-ios.svg";
import { actions as basketActions } from "reducer/basket";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import CounterInput from "components/atoms/Form/CounterInput";
import FeatureCard from "components/organisms/Cards/FeatureCard/FeatureCard";
import CardBlog from "components/organisms/Cards/CardBlog/CardBlog";
import spacing from "styles/spacing";
import Spacer from "components/atoms/Spacer/Spacer";
import useProduct from "hooks/query/use-product";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Meta from "components/atoms/Meta";

const ProductDetailStyled = styled.div``;

export default function ProductDetailComponent() {
  const { id } = useParams<{ id: ProductSlug }>();
  const productId = id !== undefined ? productsData[id].id : "";
  const productBasketItem = useSelector((state: RootState) => state.basket.items.find(item => item.id === productId));
  const dispatch = useDispatch();
  const { data: productData, isLoading } = useProduct(productId);

  if (id === undefined) return <></>;

  const navigate = useNavigate();

  const handleOnBack = () => {
    navigate("/products");
  };

  const handleOnAddProduct = () => {
    productId && dispatch(basketActions.addUpdateProductToBasket({ id: productId, amount: 1 }));
  };

  const handleOnUpdateProduct = (newValue: number) => {
    if (newValue === 0) productId && dispatch(basketActions.deleteProductFromBasket({ id: productId }));
    else productId && dispatch(basketActions.addUpdateProductToBasket({ id: productId, amount: newValue }));
  };

  return (
    <ProductDetailStyled>
      <Meta
        title={`Pingify - ${productsData[id]?.title}`}
        description={productsData[id]?.description}
        ogImage={productsData[id]?.ogImage}
        ogUrl={productsData[id]?.ogLink}
        twitterCard="product"
      />
      <Button varient="secondary" layout="icon-left" onClick={handleOnBack} icon={<IconBackIOSSVG />}>
        All Products
      </Button>
      <BasicCard
        swapMobileOrientation
        backgroundColor={`rgba(255,255,255,0.7)`}
        rightComponent={
          id !== undefined ? (
            <CardText
              headingSize={2}
              title={productsData[id]?.title}
              subtitle={`${productsData[id]?.subtitle}`}
              body={productsData[id]?.description}
              primaryActionComponent={
                typeof productBasketItem === "object" && productData !== undefined ? (
                  <CounterInput
                    max={productData.stock}
                    value={productBasketItem.amount}
                    onChange={handleOnUpdateProduct}
                  />
                ) : (
                  <>
                    <Button
                      disabled={isLoading || productData === undefined || productData.stock === 0}
                      onClick={handleOnAddProduct}
                    >
                      Add to cart
                    </Button>
                    <Spacer width={spacing.m} />
                    <Paragraph>
                      {isLoading
                        ? ""
                        : productData !== undefined
                        ? productData?.stock > 0
                          ? productData?.stock > 10
                            ? "In stock"
                            : `${productData?.stock} available`
                          : "Currently unavailable"
                        : "Error fetching availability"}
                    </Paragraph>
                  </>
                )
              }
            />
          ) : undefined
        }
        leftFlex={1}
        rightFlex={1}
        leftComponent={
          id !== undefined ? (
            <CardImage
              alt={productsData[id]?.title}
              supportedTypes={["png"]}
              maxHeight={300}
              src={productsData[id]?.images[0]}
            />
          ) : undefined
        }
      />
      <Spacer height={spacing.xl} />
      <BasicCard
        leftComponent={
          <CardText headingSize={2} title={productsData[id]?.feature.title} body={productsData[id]?.feature.body} />
        }
        backgroundColor={"#f1f4f7"}
        rightComponent={
          <CardImage
            alt={productsData[id]?.feature.imageAlt}
            supportedTypes={productsData[id]?.feature.supportedTypes}
            maxHeight={300}
            src={productsData[id]?.feature.image}
          />
        }
      />
    </ProductDetailStyled>
  );
}
