import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Container from "components/atoms/Container/Container";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontSize from "styles/fontSize";
import media from "styles/media";

interface FeatureCardProps {
  testID?: string;
  featureComponent?: React.ReactElement;
  theme?: "light" | "dark";
  title?: string | React.ReactElement;
  body?: string | React.ReactElement;
  backgroundColor?: string;
  gradientBackground?: boolean;
  flexCenter?: boolean;
}

const LARGE_HEIGHT = "60vh";

const LARGE_HEIGHT_PADDING = "60px";

const FeatureCardStyled = styled.section(
  ({ $theme, $gradientBackground }: { $theme: "light" | "dark"; $gradientBackground?: boolean }) => `
  width: 100%;
  position: relative;
  overflow: hidden;
  ${
    $gradientBackground
      ? `background: radial-gradient(
    circle,
    rgba(0, 180, 194, 1) 20%,
    rgba(30, 122, 184, 1) 100%
  );`
      : ""
  }
  ${
    $theme === "dark"
      ? `
    background-color: rgba(60, 60, 60, 0.4);`
      : ""
  }
@media ${media.mobile} {
}
@media ${media.desktop} {
  min-height: ${LARGE_HEIGHT};
    display: relative;
    box-sizing: border-box;
    &__container {
      display: flex;
      flex-direction: column;
      padding-top: ${LARGE_HEIGHT_PADDING};
      padding-bottom: ${LARGE_HEIGHT_PADDING};
      box-sizing: border-box;
    }
    &__title {
    }
    &__component {
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
    }
}
`
);

const TitleContainerStyled = styled.div(
  ({ $theme, $gradientBackground }: { $theme: "light" | "dark"; $gradientBackground?: boolean }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    & h2 {
      color: ${color.black};
      margin: 0px;
    }
    & p {
       font-size: ${fontSize.h4}
    }
    ${
      $theme === "dark"
        ? `
      & h2, p, b {
        color: ${color.white} !important;
      }
    `
        : ``
    }
    @media ${media.mobile} {
    }
    @media ${media.desktop} {
      & h1 {
        margin-bottom: 15px;
      }
      margin-bottom: 25px;
    }
  `
);

FeatureCardComponent.testID = "featureCard";

export default function FeatureCardComponent({
  featureComponent,
  theme = "light",
  title = "Title",
  body = "Here is the body text.",
  backgroundColor,
  gradientBackground,
  flexCenter = false,
  ...props
}: FeatureCardProps) {
  return (
    <FeatureCardStyled
      data-testid={composeTestID(FeatureCardComponent.testID, props.testID)}
      $theme={theme}
      $gradientBackground={gradientBackground}
      style={
        flexCenter
          ? {
              backgroundColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }
          : { backgroundColor }
      }
    >
      <Container className="featureCard__container">
        <TitleContainerStyled $theme={theme} $gradientBackground={gradientBackground} className="featureCard__title">
          <Heading as="h1" varient="h2">
            {title}
          </Heading>
          <Paragraph>{body}</Paragraph>
        </TitleContainerStyled>
        <div className="featureCard__component">{featureComponent}</div>
      </Container>
    </FeatureCardStyled>
  );
}
