import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Link from "components/atoms/Inline/Link";
import DisplayTextContainer from "components/molecules/Display/DisplayTextContainer";
import * as React from "react";
import styled from "styled-components";
import { ReactComponent as IconBackIosSVG } from "assets/icons/icon-back-ios.svg";
import { useNavigate } from "react-router-dom";
import Meta from "components/atoms/Meta";

const PrivacyStatementStyled = styled.div``;

const BackButtonStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
`;

export default function PrivacyStatementComponent() {
  const navigate = useNavigate();

  const handleOnBack = () => {
    navigate("/");
  };

  return (
    <PrivacyStatementStyled>
      <Meta
        title={`Pingify - Privacy Statement`}
        description={`Read our privacy statement`}
        ogUrl={"http://pingify.io/privacy-policy"}
      />
      <BackButtonStyled>
        <Button onClick={handleOnBack} layout="icon-left" varient="light" icon={<IconBackIosSVG />}>
          Pingify.io
        </Button>
      </BackButtonStyled>
      <DisplayTextContainer>
        <Heading as="h1">Privacy Policy for Pingify.io</Heading>
        <b>Effective Date: 10-01-2022</b>
        <br />
        <br />

        <i>Last Updated: 04-09-2023</i>

        <Heading as="h2">1. Introduction</Heading>

        <p>
          Welcome to Pingify.io (the App and Website). Pingify.io is a service that allows users (Website users) to send
          one-way messages to the owner of stickers (App Users) and keyrings bearing unique codes. This privacy policy
          (Privacy Policy) is designed to help you understand how we collect, use, disclose, and safeguard your personal
          information. We take your privacy seriously and are committed to protecting your data.
        </p>

        <Heading as="h2">2. Information We Collect (App)</Heading>

        <Heading as="h3">2.1. Personal Information:</Heading>

        <p>
          To use Pingify.io, we collect the following personal information:
          <ul>
            <li>Email Address: We collect your email address to facilitate password recovery.</li>
            <li>Name: We collect your name to personalize your experience within the app.</li>
            <li>Password: We securely store your password to enable access to your account.</li>
          </ul>
        </p>

        <Heading as="h3">2.2. Optional Information:</Heading>

        <p>
          You may choose to provide additional information about the item associated with the unique code. This may
          include a short description and a small image. The collection of this data is at your discretion.
        </p>

        <Heading as="h2">3. Data Sharing</Heading>

        <p>We do not share your personal information with third parties for marketing purposes.</p>

        <Heading as="h2">4. Data Security</Heading>

        <p>
          We take reasonable measures to protect your data. However, no data transmission over the internet is entirely
          secure, and we cannot guarantee the security of your data.
        </p>
        <Heading as="h2">5. Data Retention</Heading>

        <p>
          We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy
          Policy, or as required by law.
        </p>

        <Heading as="h2">6. Changes to this Privacy Policy</Heading>

        <p>
          We may update this Privacy Policy to reflect changes to our data practices. We will notify you of any material
          changes via the App or email.
        </p>

        <Heading as="h2">7. Contact Us</Heading>

        <p>
          If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at{" "}
          <Link to="mailto:support@pingify.io" external>
            support@pingify.io
          </Link>
          .
        </p>

        <Heading as="h2">8. GDPR Compliance</Heading>

        <p>
          For users within the European Union (EU), this Privacy Policy is designed to comply with the General Data
          Protection Regulation (GDPR). You have the right to access, rectify, or delete your personal data, as well as
          the right to data portability.
        </p>
      </DisplayTextContainer>
    </PrivacyStatementStyled>
  );
}

const bin = `

Effective Date: [Insert Effective Date]

Last Updated: [Insert Last Update Date]

1. Introduction

Welcome to Pingify.io ("the App"). Pingify.io is a service that allows users to send one-way messages to the owner of stickers and keyrings bearing unique codes. This privacy policy ("Privacy Policy") is designed to help you understand how we collect, use, disclose, and safeguard your personal information. We take your privacy seriously and are committed to protecting your data.

2. Information We Collect

2.1. Personal Information: To use Pingify.io, we collect the following personal information:

Email Address: We collect your email address to facilitate password recovery.

Name: We collect your name to personalize your experience within the app.

Password: We securely store your password to enable access to your account.

2.2. Unique Product Codes: When you scan a product's unique code, we collect and display your first name in the app. This information is used to notify the owner of the product.

2.3. Optional Information: You may choose to provide additional information about the item associated with the unique code. This may include a short description and a small image. The collection of this data is at your discretion.

3. How We Use Your Information

We use the collected information for the following purposes:

To provide you with access to the App's services and features.

To notify the owner of a product when their code is scanned and when a one-way message is sent to them.

To personalize your user experience within the App.

To send password recovery emails.

4. Data Deletion

You have the option to delete your account and associated data via the App, as per Apple App Store requirements.

5. Data Sharing

We do not share your personal information with third parties for marketing purposes.

6. Data Security

We take reasonable measures to protect your data. However, no data transmission over the internet is entirely secure, and we cannot guarantee the security of your data.

7. Data Retention

We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as required by law.

8. Changes to this Privacy Policy

We may update this Privacy Policy to reflect changes to our data practices. We will notify you of any material changes via the App or email.

9. Contact Us

If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at [Your Contact Information].

10. GDPR Compliance

For users within the European Union (EU), this Privacy Policy is designed to comply with the General Data Protection Regulation (GDPR). You have the right to access, rectify, or delete your personal data, as well as the right to data portability.

`;
