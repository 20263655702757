import * as React from "react";
import styled from "styled-components";
import { ReactComponent as PlusSVG } from "assets/icons/plus.svg";
import { ReactComponent as MinusSVG } from "assets/icons/minus.svg";
import Button from "components/atoms/Button/Button";
import spacing from "styles/spacing";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";

interface CounterInputProps {
  value: number;
  onChange: (value: number) => void;
  max?: number;
}

const CounterInputStyled = styled.div`
  display: flex;
`;

const CounterTextStyled = styled.span`
  padding: 0 ${spacing.m};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: ${fontSize.button};
  font-weight: ${fontWeight.bold};
`;

export default function CounterInputComponent({ value, onChange, max }: CounterInputProps) {
  return (
    <CounterInputStyled>
      <Button
        iconSize={20}
        layout="icon-only"
        onClick={() => {
          onChange(value - 1);
        }}
        icon={<MinusSVG />}
      ></Button>
      <CounterTextStyled>{value}</CounterTextStyled>
      <Button
        disabled={max !== undefined ? value >= max : false}
        iconSize={20}
        layout="icon-only"
        onClick={() => {
          onChange(value + 1);
        }}
        icon={<PlusSVG />}
      ></Button>
    </CounterInputStyled>
  );
}
