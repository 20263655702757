import { useQuery } from "@tanstack/react-query";

import API from "api";

usePing.queryKey = "ping";

export default function usePing(id: string) {
  return useQuery([usePing.queryKey, id], () => {
    return API.Ping.get(id);
  });
}
