export default {
  api: process.env.REACT_APP_BASE_URL,
  environment: process.env.REACT_APP_ENV,
  amplitude: {
    apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY ?? "",
  },
  meta: {
    description: {
      default:
        "A simple, subtle and secure solution to protecting your beloved items. Just activate your pingify tag in the app and attach it to your item. The when you loose it and somebody scans the tag, you will be instantly notified on your phone.",
      products:
        "We have a wide range of products to fit many items. Explore the range to find the best fit to protect your items.",
    },
  },
};
