import * as React from "react";
import styled from "styled-components";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

interface DisplayPingOwnerProps {
  children: string;
}

const DisplayPingOwnerStyled = styled.span`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.p};
  font-weight: ${fontWeight.thin};
  margin: ${spacing.xxs} 0px ${spacing.xs} 0px;
  display: block;
`;

DisplayPingOwnerComponent.testID = "displayPingOwner";

export default function DisplayPingOwnerComponent({ children }: DisplayPingOwnerProps) {
  return <DisplayPingOwnerStyled>{children}</DisplayPingOwnerStyled>;
}
