export default {
  h1: "38px",
  h2: "32px",
  h3: "24px",
  h4: "21px",
  input: "16px",
  p: "16px",
  button: "16px",
  small: "14px",
};
