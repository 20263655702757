import FlexInfo from "components/molecules/FlexInfo";
import * as React from "react";
import styled from "styled-components";
import { ReactComponent as QuestionMarkSVG } from "assets/svg/question-mark.svg";
import Button from "components/atoms/Button/Button";
import { useNavigate } from "react-router-dom";

interface PingNotFoundProps {
  id: string;
}

export default function PingNotFoundComponent({ id }: PingNotFoundProps) {
  const navigate = useNavigate();

  const handleOnTryAgain = () => {
    navigate("/");
  };
  return (
    <FlexInfo
      svgElement={<QuestionMarkSVG />}
      title={`Ping not found`}
      body={`The ping '${id}' can not be found.`}
      buttonElement={
        <Button size="small" onClick={handleOnTryAgain}>
          Search again
        </Button>
      }
    />
  );
}
