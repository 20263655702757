import * as React from "react";
import styled from "styled-components";
import useOrder from "hooks/query/use-orders";
import FlexLoader from "components/molecules/FlexLoader/FlexLoader";
import OrdersTable from "./components/OrdersTable";
import color from "styles/color";
import spacing from "styles/spacing";
import Button from "components/atoms/Button/Button";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Spacer from "components/atoms/Spacer/Spacer";
import Modal from "components/molecules/Modal";
import OrderDetail from "./components/OrderDetail";
import { useNavigate } from "react-router-dom";

const DashboardPageStyled = styled.div``;

const OrderActionBarContainerStyled = styled.div`
  width: 100%;
  background-color: ${color.grey1};
  padding: ${spacing.m};
  display: block;
  /* padding-top: 0; */
  & p {
    margin-top: 0;
  }
`;

const OrderActionBarStyled = styled.div`
  display: flex;
  width: 100%;
`;

export default function DashboardPageComponent() {
  const { data: orderData, isLoading } = useOrder();
  const [selection, setSelection] = React.useState<string[]>([]);
  const [orderDetailId, setOrderDetailId] = React.useState<false | string>(false);

  const navigate = useNavigate();

  const handleOnPrintShippingLabels = () => {
    console.log("selection, ", selection);
    navigate(`/admin/print/shipping-label?ids=${selection.join(",")}`);
  };

  return (
    <DashboardPageStyled>
      <OrderActionBarContainerStyled>
        <Paragraph>Selected ({selection.length})</Paragraph>
        <OrderActionBarStyled>
          <Button
            onClick={handleOnPrintShippingLabels}
            disabled={selection.length === 0}
          >{`Print Shipping Labels`}</Button>
          <Spacer width={spacing.m} />
        </OrderActionBarStyled>
      </OrderActionBarContainerStyled>
      {isLoading ? (
        <FlexLoader />
      ) : orderData !== undefined ? (
        <>
          <OrdersTable
            data={orderData}
            onItemClick={(id: string) => setOrderDetailId(id)}
            selection={selection}
            setSelection={setSelection}
          />
          <Modal
            title={typeof orderDetailId === "string" ? `Order ${orderDetailId.slice(0, 15)}` : ""}
            onClose={() => setOrderDetailId(false)}
            open={!!orderDetailId}
          >
            <OrderDetail orderData={orderData} id={orderDetailId} />
          </Modal>
        </>
      ) : (
        <></>
      )}
    </DashboardPageStyled>
  );
}
