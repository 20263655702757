import * as Test from "./Test";
import * as Ping from "./Ping";
import * as Checkout from "./Checkout";
import * as Order from "./Order";
import * as Authentication from "./Authentication";
import * as Product from "./Product";

export default {
  Test,
  Ping,
  Authentication,
  Checkout,
  Order,
  Product,
};
