import Picture, { SupportedImageType } from "components/atoms/Picture/Picture";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import media from "styles/media";

interface CardImageProps {
  testID?: string;
  src: string;
  maxHeight?: number;
  width?: string;
  alt: string;
  supportedTypes: SupportedImageType[];
}

const CardImageStyled = styled.div(
  ({ $maxHeight }: { $maxHeight?: number }) => `
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: ${$maxHeight}px;
  & img {
    border-radius: 15px;
    max-height: ${$maxHeight}px;
  }

  @media ${media.mobile} {

  }
  @media ${media.desktop} {

  }
`
);

CardImageComponent.testID = "cardImage";

export default function CardImageComponent({ testID, src, maxHeight, alt, supportedTypes }: CardImageProps) {
  return (
    <CardImageStyled $maxHeight={maxHeight} data-testid={composeTestID(CardImageComponent.testID, testID)}>
      <Picture alt={alt} supportedTypes={supportedTypes} src={src} />
    </CardImageStyled>
  );
}
