import * as React from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import color from "styles/color";
import PackageCodePrint from "./PackageCodePrint";
import PackageKeyringPrint from "./PackageKeyringPrint";
import PackageStickersPrint from "./PackageStickersPrint";
import PackageTagsPrint from "./PackageTagsPrint";
import StickersPrint from "./StickersPrint";
import TagsPrint from "./TagsPrint";
import KeyringWoodPrint from "./KeyringWoodPrint";

const DesignStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DesignContainerStyled = styled.div`
  flex: 1;
  background-color: ${color.grey2};
  min-height: 1000px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function DesignComponent() {
  return (
    <DesignStyled>
      <DesignContainerStyled>
        <Routes>
          <Route path="/tags" element={<TagsPrint />} />
          <Route path="/stickers" element={<StickersPrint />} />
          <Route path="/keyring-wood" element={<KeyringWoodPrint />} />
          <Route path="/package/stickers" element={<PackageStickersPrint />} />
          <Route path="/package/keyring" element={<PackageKeyringPrint />} />
          <Route path="/package/tags" element={<PackageTagsPrint />} />
          <Route path="/package/code" element={<PackageCodePrint />} />
        </Routes>
      </DesignContainerStyled>
    </DesignStyled>
  );
}
