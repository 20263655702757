import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Container from "components/atoms/Container/Container";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";

interface TitleDescriptionProps {
  testID?: string;
  title?: string;
  description?: string;
}

const TitleDescriptionStyled = styled.div`
  margin-top: 0px;
  margin-bottom: 0px;
  h1 {
    width: 100%;
    text-align: center;
  }
  p {
    text-align: center;
  }
`;

TitleDescriptionComponent.testID = "titleDescription";

export default function TitleDescriptionComponent({ title, description, ...props }: TitleDescriptionProps) {
  return (
    <TitleDescriptionStyled as={Container} data-testid={composeTestID(TitleDescriptionComponent.testID, props.testID)}>
      <Heading as="h1">{title}</Heading>
      {description && <Paragraph>{description}</Paragraph>}
    </TitleDescriptionStyled>
  );
}
