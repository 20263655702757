import DisplayPingTitle from "components/molecules/Display/DisplayPingTitle";
import DisplayPingInstruction from "components/molecules/Display/DisplayPingInstruction";
import * as React from "react";
import styled from "styled-components";
import { PingPublic } from "types/Ping";
import { Field, Form } from "react-final-form";
import TextInput from "components/molecules/Form/TextInput/TextInput";
import spacing from "styles/spacing";
import MultiLineInput from "components/molecules/Form/MultiLineInput";
import Button from "components/atoms/Button/Button";
import Spacer from "components/atoms/Spacer/Spacer";
import FlexLoader from "components/molecules/FlexLoader/FlexLoader";
import api from "api";
import { FORM_ERROR } from "final-form";

interface PingMessageProps {
  data: PingPublic;
  onBack: () => void;
  onSuccess: () => void;
}

interface PingMessageForm {
  alias: string;
  message: string;
  contactDetails: string;
}

const PingMessageStyled = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 0 ${spacing.xl} ${spacing.xl} ${spacing.xl};
`;

const ButtonContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function PingMessageComponent({ data, onBack, onSuccess }: PingMessageProps) {
  const [isSending, setIsSending] = React.useState(false);
  const handleOnSubmit = async (formValues: PingMessageForm) => {
    try {
      setIsSending(true);
      await api.Ping.returnPing(data.id, formValues);
      onSuccess();
    } catch (er) {
      return { [FORM_ERROR]: "Failed to send" };
    }
    setIsSending(false);
  };

  const handleValidation = (formValues: PingMessageForm) => {
    return {
      alias: formValues.alias !== undefined && formValues.alias !== "" ? undefined : "Please enter a name or alias",
      contactDetails:
        formValues.contactDetails !== undefined && formValues.contactDetails !== ""
          ? undefined
          : "Please enter contact details",
      message: formValues.message !== undefined && formValues.message !== "" ? undefined : "Please enter a message",
    };
  };

  return (
    <>
      <FlexLoader visible={isSending}>
        <>Sending</>
      </FlexLoader>
      <Form onSubmit={handleOnSubmit} validate={handleValidation}>
        {({ handleSubmit }) => {
          return (
            <PingMessageStyled>
              <DisplayPingTitle>{data.title}</DisplayPingTitle>
              <DisplayPingInstruction>Send a message</DisplayPingInstruction>
              <Field compact name="alias" label="Name" placeholder="Name or Alias" component={TextInput} />
              <Field
                compact
                name="contactDetails"
                label="Contact details"
                placeholder="Phone / Email"
                component={TextInput}
              />
              <Field
                compact
                name="message"
                label="Message (How to collect item)"
                placeholder="Message"
                component={MultiLineInput}
              />
              <Spacer height={spacing.m} />
              <ButtonContainerStyled>
                <Button onClick={handleSubmit}>Send</Button>
              </ButtonContainerStyled>
            </PingMessageStyled>
          );
        }}
      </Form>
    </>
  );
}
