import * as React from "react";
import styled from "styled-components";
import PrintPage from "../../../Print/components/PrintA4Page";
import { useReactToPrint } from "react-to-print";
import Button from "components/atoms/Button/Button";
import spacing from "styles/spacing";
import Heading from "components/atoms/Heading/Heading";
import BottomBar from "../../../Print/components/BottomBar";
import generateId from "functions/generate-id";
import Tag, { TAG_BORDER_COLOR, TAG_BORDER_WIDTH } from "../../components/Tag";
import InformationBox from "pages/Admin/Print/components/InformationBox";
import Paragraph from "components/atoms/Paragraph/Paragraph";

const TagsA4Styled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  align-items: center;
`;

const PrintContentContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: ${spacing.l};
  &.printable {
    display: flex;
  }
`;

const TagsContainerStyled = styled.div`
  border-top: ${TAG_BORDER_WIDTH} ${TAG_BORDER_COLOR} solid;
  border-left: ${TAG_BORDER_WIDTH} ${TAG_BORDER_COLOR} solid;
  transform: scaleX(-100%);
`;

export default function TagsA4Component() {
  const componentRef = React.useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const idList = new Array(10).fill(null).map(() => [generateId(), generateId(), generateId()]);

  return (
    <TagsA4Styled>
      <Heading as="h1" varient="h3">
        Tags A4
      </Heading>
      <PrintContentContainer>
        <PrintPage ref={componentRef}>
          <TagsContainerStyled>
            {idList.map(id => (
              <React.Fragment key={`${id[0]}`}>
                <Tag id={id[0]} />
                <Tag id={id[1]} />
                <Tag id={id[2]} />
                <Tag id={id[0]} reverse />
                <Tag id={id[1]} reverse />
                <Tag id={id[2]} reverse />
              </React.Fragment>
            ))}
          </TagsContainerStyled>
        </PrintPage>
      </PrintContentContainer>
      <InformationBox>
        <Heading as="h2" varient="h4">
          Materials
        </Heading>
        <Paragraph>
          <ul>
            <li>
              16mm Ribbon (White Grosgrain){" "}
              <ul>
                <li>
                  <a
                    target="blank"
                    href="https://nl.aliexpress.com/item/32967445089.html?spm=a2g0o.productlist.main.1.78ff5cc96ga995&algo_pvid=f0efb543-6a35-4500-b0b0-d4d1c04c4398&algo_exp_id=f0efb543-6a35-4500-b0b0-d4d1c04c4398-0&pdp_ext_f=%7B%22sku_id%22%3A%2266567787308%22%7D&pdp_npi=2%40dis%21EUR%212.66%212.4%21%21%21%21%21%40214528be16749965026792132d06b6%2166567787308%21sea&curPageLogUid=QmblfcS8EqGA"
                  >
                    Alibaba
                  </a>
                </li>
                <li>
                  <a
                    target="blank"
                    href="https://photopaperdirect.com/collections/transfer-paper/products/a4-light-inkjet-t-shirt-transfer-paper-1"
                  >
                    photopaperdirect.com (UK/US only)
                  </a>
                </li>
              </ul>
            </li>
            <li>
              T-Shirt transfer paper{" "}
              <a target="blank" href="https://nl.aliexpress.com/item/1005003973891646.html?gatewayAdapt=glo2nld">
                Buy here
              </a>
            </li>
            <li>
              Tag packaging{" "}
              <a target="blank" href="https://nl.aliexpress.com/item/1005003973891646.html?gatewayAdapt=glo2nld">
                Instructions here
              </a>
            </li>
            <li>
              Double tubular rivets 7mm Diameter, 7.3mm Shaft height{" "}
              <ul>
                <li>
                  <a target="blank" href="www.sprintis.de">
                    Website
                  </a>
                </li>
                <li>
                  <a
                    target="blank"
                    href="https://www.sprintis.de/Buchbinderbedarf/Oesen/Doppelhohlnieten/Doppelhohlnieten-Unterteil-geschlossen-Typ-A-7-mm-Kopfdurchmesser.html"
                  >
                    Male
                  </a>
                </li>
                <li>
                  <a
                    target="blank"
                    href="https://www.sprintis.de/Buchbinderbedarf/Oesen/Doppelhohlnieten/Doppelhohlnieten-Oberteil-Typ-A-7-mm-Kopfdurchmesser.html"
                  >
                    Female
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </Paragraph>
        <Heading as="h2" varient="h4">
          Printing
        </Heading>
        <Paragraph>
          <ul>
            <li>Print on A4 tshirt transfer</li>
            <li>Mac -&gt; Print -&gt; More Setings -&gt; System dialogue -&gt; Media Type: Photo Matte Paper</li>
          </ul>
        </Paragraph>
        <Heading as="h2" varient="h4">
          Assembly
        </Heading>
        <Paragraph>
          <ul>
            <li>Cut pieces around guide lines (not including)</li>
            <li>Cut Ribbon slightly larger than width of A4</li>
            <li>
              Place first piece ontop and iron (mid-low heat, hold for 10 seconds and then press with iron at end)
            </li>
            <li>Repeat for other side</li>
            <li>Wait until cooled and then peel off</li>
            <li>
              Cut <b>on</b> guide lines with scissors
            </li>
            <li>
              Fold with <b>pingify.io/</b> text pointing towards the hole
            </li>
            <li>Punch hole (small size is fine)</li>
            <li>Coat ends with invisible nail varnish (Or something to prevent fraying)</li>
            <li>
              Place male rivet facing into <b>pingify.io/</b> text side.
            </li>
          </ul>
        </Paragraph>
      </InformationBox>
      <BottomBar>
        <Button onClick={handlePrint}>Print</Button>
      </BottomBar>
    </TagsA4Styled>
  );
}
