import Button from "components/atoms/Button/Button";
import DisplayPingDescription from "components/molecules/Display/DisplayPingDescription";
import DisplayPingID from "components/molecules/Display/DisplayPingID";
import DisplayPingLastViewed from "components/molecules/Display/DisplayPingLastViewed";
import DisplayPingOwner from "components/molecules/Display/DisplayPingOwner";
import DisplayPingTitle from "components/molecules/Display/DisplayPingTitle";
import * as React from "react";
import styled from "styled-components";
import { PingPublic } from "types/Ping";

const PindContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface PingDetailProps {
  data: PingPublic;
  onMessageClick: () => void;
}
export default function PingDetailComponent({ data, onMessageClick }: PingDetailProps) {
  return (
    <PindContainerStyled>
      <DisplayPingTitle>{data.title}</DisplayPingTitle>
      <DisplayPingOwner>{data.owner.fname}</DisplayPingOwner>
      <DisplayPingID>{data.id}</DisplayPingID>
      <DisplayPingDescription>{data.description}</DisplayPingDescription>
      <DisplayPingLastViewed>Moments ago</DisplayPingLastViewed>
      <Button onClick={onMessageClick}>Send a message</Button>
    </PindContainerStyled>
  );
}
