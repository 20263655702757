import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Spacer from "components/atoms/Spacer/Spacer";
import CardLoader from "components/molecules/Card/CardLoader";
import useCheckout from "hooks/query/use-checkout";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import media from "styles/media";
import spacing from "styles/spacing";
import CheckoutProduct from "./components/CheckoutProduct";
import { ReactComponent as IconBackIOSSVG } from "assets/icons/icon-back-ios.svg";
import Badge from "components/atoms/Badge";
import fontSize from "styles/fontSize";
import color from "styles/color";

const CheckoutPageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

const CheckoutPageStyled = styled.div`
  max-width: 800px;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${spacing.xl};
  h1 {
    text-align: center;
  }
  p {
    margin-bottom: 0;
  }
  @media ${media.mobile} {
  }
  @media ${media.desktop} {
    min-width: 600px;
  }
`;

const BackButtonStyled = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
`;

export default function CheckoutPageComponent() {
  const [searchParams, setSearchParams] = useSearchParams();

  const sessionId = searchParams.get("session_id");
  const success = searchParams.get("success") === "true";
  const canceled = searchParams.get("canceled") === "true";

  const navigate = useNavigate();

  const handleOnNavigateBack = () => {
    navigate("/");
  };

  if (canceled) {
    return (
      <CheckoutPageContainer>
        <BackButtonStyled>
          <Button varient="secondary" onClick={handleOnNavigateBack} icon={<IconBackIOSSVG />} layout="icon-left">
            Return
          </Button>
        </BackButtonStyled>
        <CheckoutPageStyled>
          <Heading as="h1" varient="h2">
            Order Canceled
          </Heading>
          <Paragraph>Your order was canceled before it completed.</Paragraph>
        </CheckoutPageStyled>
      </CheckoutPageContainer>
    );
  }

  const { data: checkoutData, isLoading } = useCheckout(sessionId ?? "");

  const status = checkoutData !== undefined ? checkoutData.payment_intent.metadata?.status ?? "Created" : undefined;

  return (
    <CheckoutPageContainer>
      <BackButtonStyled>
        <Button varient="secondary" onClick={handleOnNavigateBack} icon={<IconBackIOSSVG />} layout="icon-left">
          Return
        </Button>
      </BackButtonStyled>
      <CheckoutPageStyled>
        <Heading as="h1" varient="h2">
          Thank you for your purchase!
        </Heading>
        {isLoading ? (
          <CardLoader />
        ) : checkoutData === undefined ? (
          <CheckoutPageContainer>
            <BackButtonStyled>
              <Button varient="secondary" onClick={handleOnNavigateBack} icon={<IconBackIOSSVG />} layout="icon-left">
                Return
              </Button>
            </BackButtonStyled>
            <CheckoutPageStyled>
              <Heading as="h1" varient="h2">
                Error fetching order
              </Heading>
              <Paragraph>
                Sorry, we could not fetch your order information. If you placed an order and would like help finding out
                information please contact support at support@pingify.io.
              </Paragraph>
              <Paragraph>Order Number: {sessionId ?? "-"}</Paragraph>
            </CheckoutPageStyled>
          </CheckoutPageContainer>
        ) : (
          <>
            <Paragraph>
              Your order number is <b>{checkoutData.client_reference_id}</b>
            </Paragraph>
            {/* <Spacer height={spacing.s} /> */}
            <Paragraph>The following items are on their way:</Paragraph>
            <Spacer height={spacing.l} />
            {checkoutData.line_items.map(lineItem => (
              <CheckoutProduct key={lineItem.tag} tag={lineItem.tag} quantity={lineItem.quantity} />
            ))}
            <Spacer height={spacing.s} />
            <Paragraph>
              Current Status: <Badge text={status ?? "-"} />
            </Paragraph>
            <Spacer height={spacing.m} />
            <Paragraph style={{ textAlign: "center", fontSize: fontSize.small }}>
              If you need more help with your order.
              <br /> Please contact support at support@pingify.io.
            </Paragraph>
            <Spacer height={spacing.l} />
          </>
        )}
        <br />
      </CheckoutPageStyled>
    </CheckoutPageContainer>
  );
}
