import * as React from "react";
import styled from "styled-components";
import media from "styles/media";
import spacing from "styles/spacing";

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  testID?: string;
  children?: React.ReactElement | React.ReactElement[];
  maxWidth?: number;
  as?: "article" | "section";
}

const ContainerStyled = styled.div(
  ({ maxWidth }: Pick<ContainerProps, "maxWidth">) => `
  @media ${media.mobile} {
    margin: ${spacing.m} ${spacing.l};
  }
  @media ${media.desktop} {
    margin: ${spacing.l} ${spacing.xxl};
  }
  ${maxWidth ? `max-width: ${maxWidth}px;` : ""}
`
);

export default ContainerStyled;
