import * as React from "react";
import styled from "styled-components";
import Sticker, { STICKER_HEIGHT, STICKER_WIDTH } from "./Sticker";

interface Sticker4PackProps {
  idList: [string, string, string, string];
  showCut?: boolean;
  xOrigin?: number;
  yOrigin?: number;
}

export const STICKER_PACK_WIDTH = 55;
export const STICKER_PACK_HEIGHT = 65;
const STICKER_HORZONTAL_GAP = 5; // mm
const STICKER_VERTICAL_GAP = 5; // mm

const Sticker4PackStyled = styled.g`
  width: ${STICKER_PACK_WIDTH};
  height: ${STICKER_PACK_HEIGHT};
  /* background-color: green; */
  /* margin-bottom: 5mm;
  margin-right: 5mm; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const StickerContainerStyled = styled.g`
  /* display: inline-block; */
  /* margin: ${STICKER_VERTICAL_GAP / 2}mm ${STICKER_HORZONTAL_GAP / 2}mm; */
`;

export default function Sticker4PackComponent({
  idList,
  showCut = false,
  xOrigin = 0,
  yOrigin = 0,
}: Sticker4PackProps) {
  return (
    <Sticker4PackStyled viewBox={`0 0 ${STICKER_PACK_WIDTH} ${STICKER_PACK_HEIGHT}`}>
      {idList.map((id, index) => {
        let x;
        if (index === 0 || index === 2) {
          x = xOrigin + STICKER_PACK_WIDTH / 2 - STICKER_HORZONTAL_GAP / 2 - STICKER_WIDTH;
        } else {
          x = xOrigin + STICKER_PACK_WIDTH / 2 + STICKER_HORZONTAL_GAP / 2;
        }
        let y;
        if (index === 0 || index === 1) {
          y = yOrigin + STICKER_PACK_HEIGHT / 2 - STICKER_VERTICAL_GAP / 2 - STICKER_HEIGHT;
        } else {
          y = yOrigin + STICKER_PACK_HEIGHT / 2 + STICKER_VERTICAL_GAP / 2;
        }

        return (
          <StickerContainerStyled key={id}>
            <Sticker x={x} y={y} id={id} showCut={showCut} onlySVG />
          </StickerContainerStyled>
        );
      })}
    </Sticker4PackStyled>
  );
}
