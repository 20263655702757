import Button from "components/atoms/Button/Button";
import Container from "components/atoms/Container/Container";
import BlogPost from "components/organisms/BlogPost/BlogPost";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import media from "styles/media";
import spacing from "styles/spacing";

export interface BlogPostCategory {
  key: string;
  label: string;
}

export interface BlogPost {
  id: string;
  categories: string[];
  title: string;
  date: string;
  owner?: string;
  subtitle: string;
  images?: string[];
  location?: string;
}

export interface BlogTemplateProps {
  testID?: string;
  categories: BlogPostCategory[];
  posts: BlogPost[];
}

const BlogTemplateStyled = styled.div`
  width: 100%;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const BlogTemplateButtonContainerStyled = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 0px 0px ${spacing.m} 0px;
  justify-content: center;
  align-items: center;
  margin: 0px !important;
  & > * {
    margin: 0px ${spacing.s};
  }
  @media ${media.mobile} {
    flex-direction: column;
  }
  @media ${media.desktop} {
  }
  @media ${media.large} {
    flex-direction: row;
  }
`;

const BlogTemplateButtonPostsBackgroundStyled = styled.div`
  background-color: ${color.grey2};
  display: flex;
  flex: 1;
  @media ${media.mobile} {
    display: flex;
  }
  @media ${media.desktop} {
    padding: ${spacing.xs} ${spacing.l};
    justify-content: center;
  }
  @media ${media.large} {
    padding: ${spacing.xs} ${spacing.l};
    justify-content: center;
  }
`;

const BlogTemplateButtonPostsContainerStyled = styled.div`
  max-width: 1020px;
  margin: 0px;
  @media ${media.mobile} {
    width: 100%;
    flex-direction: column;
    > * {
      // width: 100%;
      margin: ${spacing.xs} 0px;
    }
  }
  @media ${media.desktop} {
    min-width: 700px;
    padding: 0px ${spacing.l};
  }
  @media ${media.large} {
    min-width: 1000px;
    padding: 0px ${spacing.l};
  }
`;

BlogTemplateComponent.testID = "blogTemplate";

export default function BlogTemplateComponent({ categories, posts, ...props }: BlogTemplateProps) {
  const [filter, setFilter] = React.useState("all");

  const handleCategoryChange = (newFilter: string) => () => {
    setFilter(newFilter);
  };

  return (
    <BlogTemplateStyled data-testid={composeTestID(BlogTemplateComponent.testID, props.testID)}>
      <BlogTemplateButtonContainerStyled as={Container} className="blogContainer__button-container">
        <Button onClick={handleCategoryChange("all")} varient={filter === "all" ? "primary" : "secondary"}>
          All
        </Button>
        {categories.map(item => (
          <Button
            varient={filter === item.key ? "primary" : "secondary"}
            key={item.key}
            onClick={handleCategoryChange(item.key)}
          >
            {item.label}
          </Button>
        ))}
      </BlogTemplateButtonContainerStyled>
      <BlogTemplateButtonPostsBackgroundStyled className="blogContainer__posts-background">
        <BlogTemplateButtonPostsContainerStyled as={Container} className="blogContainer__posts-container">
          {posts
            .filter(post => (filter === "all" ? true : post.categories.includes(filter)))
            .map(post => (
              <BlogPost key={post.id} {...post} />
            ))}
        </BlogTemplateButtonPostsContainerStyled>
      </BlogTemplateButtonPostsBackgroundStyled>
    </BlogTemplateStyled>
  );
}
