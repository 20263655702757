import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Picture from "components/atoms/Picture/Picture";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import color from "styles/color";
import fontSize from "styles/fontSize";
import media from "styles/media";

interface CardBlogPost {
  title: string;
  body: string;
  user?: string;
  time?: string;
  image: any;
  buttonText?: string;
  location?: string;
}

interface CardBlogPostProps {
  testID?: string;
  post: CardBlogPost;
}

const CardBlogPostStyled = styled.div`
  scroll-snap-align: start;
  display: inline-block;
  position: relative;
  @media ${media.mobile} {
    width: 100%;
    height: 100%;
  }
  @media ${media.desktop} {
    width: 50%;
    height: 100%;
  }
  @media ${media.large} {
    width: 33.33333%;
    height: 100%;
  }
`;

const CardBlogPostBubbleContainerStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CardBlogPostBubbleStyled = styled.article`
  width: 280px;
  height: 420px;
  background-color: ${color.white};
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  position: absolute;
  /* padding: 24px; */
  display: flex;
  flex-direction: column;
  & p {
    white-space: normal;
  }
  & img {
    width: 280px;
    height: 280px;
    object-fit: cover;
    display: block;
  }
`;
const CardBlogPostTextStyled = styled.div`
  flex-shrink: 1;
  flex-direction: column;
  display: flex;
  width: 100%;
  & > h1 {
    /* text-transform: uppercase; */
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin: 0px;
    font-size: ${fontSize.input};
  }
  & > p {
    height: 45px;
    flex-shrink: 1;
    overflow: hidden;
    margin: 4px 0;
  }
  padding: 14px 24px 4px 24px;
`;

const CardBlogPostButtonStyled = styled.div`
  display: flex;
  justify-content: center;
  padding: 4px;
`;

CardBlogPostComponent.testID = "cardBlogPost";

export default function CardBlogPostComponent({ post, testID }: CardBlogPostProps) {
  const navigate = useNavigate();

  const handleGoto = (location: string) => () => {
    navigate(location);
  };

  return (
    <CardBlogPostStyled data-testid={composeTestID(CardBlogPostComponent.testID, testID)}>
      <CardBlogPostBubbleContainerStyled className="cardBlog__blog-message-bubble-container">
        <CardBlogPostBubbleStyled className="cardBlog__blog-message-bubble">
          {post.image ? <Picture alt={post.title} supportedTypes={["png"]} src={post.image} /> : <></>}
          <CardBlogPostTextStyled className="cardBlog__blog-text">
            <Heading as="h1" varient="h4">
              {post.title}
            </Heading>
            <Paragraph>{post.body}</Paragraph>
            {post.buttonText ? (
              <CardBlogPostButtonStyled className="cardBlog__blog-message-button">
                <Button size="small" onClick={post.location ? handleGoto(post.location) : undefined}>
                  {post.buttonText}
                </Button>
              </CardBlogPostButtonStyled>
            ) : (
              <></>
            )}
          </CardBlogPostTextStyled>
        </CardBlogPostBubbleStyled>
      </CardBlogPostBubbleContainerStyled>
    </CardBlogPostStyled>
  );
}
