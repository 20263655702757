import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BasketProduct } from "types/BasketProduct";

interface BasketState {
  items: BasketProduct[] | [];
}

const initialState: BasketState = {
  items: [],
};

interface DeletePayload {
  id: BasketProduct["id"];
}

const backetSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    addUpdateProductToBasket: (state, { payload }: PayloadAction<BasketProduct>) => {
      const currentProductExists = state.items.findIndex(basketItem => basketItem.id === payload.id) !== -1;

      state.items = currentProductExists
        ? state.items.map(basketItem => (basketItem.id === payload.id ? payload : basketItem))
        : [...state.items, payload];
    },
    deleteProductFromBasket: (state, { payload }: PayloadAction<DeletePayload>) => {
      state.items = state.items.filter(basketItem => basketItem.id !== payload.id);
    },
  },
});

const { actions } = backetSlice;
export { actions };
export default backetSlice.reducer;
