import * as React from "react";
import styled from "styled-components";
import TitleBodyComponent from "./TitleBody";

interface ImageTitleBodyProps {
  imageUrl: any;
  title: string;
  italic?: boolean;
  body: React.ReactElement | React.ReactElement[] | string;
}

const IMAGE_WIDTH = "10mm";
const IMAGE_HEIGHT = "10mm";

const SIDE_MARGIN = "2mm";

const MARGIN_VERTICAL = "2mm";

const ImageTitleBodyStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${MARGIN_VERTICAL} 0;
  & div {
    flex: 1;
    margin: 0;
    /* margin-right: -3mm; */
  }
`;
const ImageBorderStyled = styled.img`
  display: inline-block;
  width: ${IMAGE_WIDTH};
  height: ${IMAGE_HEIGHT};
  object-fit: contain;
  overflow: hidden;
  margin-right: ${SIDE_MARGIN};
`;

export default function ImageTitleBody({ imageUrl, title, body, italic }: ImageTitleBodyProps) {
  return (
    <ImageTitleBodyStyled>
      <ImageBorderStyled src={imageUrl}></ImageBorderStyled>
      <TitleBodyComponent title={title} body={body} italic={italic} />
    </ImageTitleBodyStyled>
  );
}
