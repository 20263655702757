import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import colors from "styles/color";
import fontFamily from "styles/fontFamily";
import fontWeight from "styles/fontWeight";
import media from "styles/media";
import spacing from "styles/spacing";
import Icon from "../Icon/Icon";
import Loader from "../LoaderSimple";

type IconLayoutType = "icon-left" | "icon-right" | "icon-only" | "no-icon";
type IconMobileLayoutType = "icon-only";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: string;
  varient?: "primary" | "secondary" | "light";
  testID?: string;
  disabled?: boolean;
  icon?: React.ReactElement;
  iconSize?: number;
  layout?: IconLayoutType;
  mobileLayout?: IconMobileLayoutType;
  backgroundColor?: string;
  color?: string;
  size?: "small" | "large";
  loading?: boolean;
}

ButtonComponent.testID = "button";

const LINE_HEIGHT = "30px";

const DISABLED_BACKGROUND_COLOR = "rgba(0,0,0,0.2)";

const LARGE_PADDING_HORIZONTAL = "30px";
const LARGE_PADDING_VERTICAL = "10px";
const SMALL_PADDING_HORIZONTAL = "10px";
const SMALL_PADDING_VERTICAL = "5px";
const BUTTON_MEDIUM_HEIGHT = "47px";
const BUTTON_MEDIUM_PADDING = "30px";
const BUTTON_SMALL_HEIGHT = "35px";
const BUTTON_SMALL_PADDING = "15px";
const LARGE_HEIGHT = `calc(${LINE_HEIGHT} + ${LARGE_PADDING_VERTICAL} * 2)`;
const SMALL_HEIGHT = `calc(${LINE_HEIGHT} + ${SMALL_PADDING_VERTICAL} * 2)`;

const ButtonStyled = styled.button(
  ({
    $varient = "primary",
    $color,
    $layout,
    $mobileLayout,
    $backgroundColor,
    $size,
  }: {
    $color: string;
    $layout: IconLayoutType;
    $mobileLayout?: IconMobileLayoutType;
    $size: "small" | "large";
    $backgroundColor?: string;
    $varient?: "primary" | "secondary" | "light";
  }) => {
    const size = $size === "large" ? BUTTON_MEDIUM_HEIGHT : BUTTON_SMALL_HEIGHT;
    const padding = $size === "large" ? BUTTON_MEDIUM_PADDING : BUTTON_SMALL_PADDING;
    return `
font-weight: ${fontWeight.bold};
font-family: ${fontFamily.secondary};
font-size: 18px;
border-radius: calc(${$size === "large" ? LARGE_HEIGHT : SMALL_HEIGHT} / 2);
background-color: ${
      $backgroundColor
        ? $backgroundColor
        : $varient === "primary"
        ? colors.primary
        : $varient === "secondary"
        ? "transparent"
        : colors.white
    };
color: ${$color};
line-height: 27px;
padding: 10px ${padding};
border: none;
cursor: pointer;
display: flex;
height: ${$size === "large" ? LARGE_HEIGHT : SMALL_HEIGHT};
align-items: center;
${composeResponsiveButtonLayoutStyle($layout, $mobileLayout)}
&:disabled{
background-color: ${
      $varient === "primary" ? DISABLED_BACKGROUND_COLOR : $varient === "secondary" ? "transparent" : colors.grey1
    };
color: ${$varient === "primary" ? colors.white : DISABLED_BACKGROUND_COLOR};
}

`;
  }
);

export default function ButtonComponent({
  children,
  icon,
  varient = "primary",
  layout,
  mobileLayout,
  size = "large",
  iconSize = 16,
  backgroundColor,
  color: forceColor,
  loading = false,
  ...props
}: ButtonProps) {
  const color = forceColor
    ? forceColor
    : varient === "primary"
    ? colors.white
    : varient === "secondary"
    ? colors.primary
    : colors.black;
  const defaultLayout: IconLayoutType = layout !== undefined ? layout : icon !== undefined ? "icon-left" : "no-icon";

  return (
    <ButtonStyled
      disabled={props.disabled ?? false}
      data-testid={composeTestID(ButtonComponent.testID, props.testID)}
      $varient={varient}
      $mobileLayout={mobileLayout}
      $layout={defaultLayout}
      $color={color}
      $backgroundColor={backgroundColor}
      $size={size}
      {...props}
    >
      {icon && (layout === "icon-left" || layout === "icon-only") && (
        <Icon id="icon" color={color} size={iconSize}>
          {icon}
        </Icon>
      )}
      {loading === true && <Loader size="27px" varient={"white"} />}
      {children && <span>{children}</span>}
      {icon && layout === "icon-right" && (
        <Icon id="icon" color={color} size={iconSize}>
          {icon}
        </Icon>
      )}
    </ButtonStyled>
  );
}

function composeButtonLayoutStyle(layout: IconLayoutType | IconMobileLayoutType) {
  switch (layout) {
    case "icon-left": {
      return `
      & #icon { margin-right: ${spacing.s}}
      `;
    }
    case "icon-right": {
      return `
      & #icon { margin-left: ${spacing.s}}
      `;
    }
    case "icon-only": {
      return `
      padding: 10px 15px;
      & span {
        display:none;
      }`;
    }
    case "no-icon": {
      return ``;
    }
    default: {
      return ``;
    }
  }
}

function composeResponsiveButtonLayoutStyle(layout: IconLayoutType, mobileLayout?: IconMobileLayoutType) {
  return `
    @media ${media.mobile} {
      ${composeButtonLayoutStyle(mobileLayout ?? layout)}
    }
    @media ${media.desktop} {
      ${composeButtonLayoutStyle(layout)}
    }
  `;
}
