import DisplayPingPicture from "components/molecules/Display/DisplayPingPicture";
import FlexLoader from "components/molecules/FlexLoader/FlexLoader";
import composeTestID from "functions/compose-test-id";
import usePing from "hooks/query/use-ping";
import * as React from "react";
import { matchPath, Route, Routes, useLocation, useNavigate, useNavigation, useParams } from "react-router-dom";
import styled from "styled-components";
import spacing from "styles/spacing";
import { ReactComponent as StickerSVG } from "assets/icons/sticker.svg";
import { ReactComponent as KeyringSVG } from "assets/icons/keyring.svg";
import { ReactComponent as TagSVG } from "assets/icons/tag.svg";
import { motion } from "framer-motion";
import media from "styles/media";
import PingDetail from "./components/PingDetail";
import PingMessage from "./components/PingMessage";
import { ReactComponent as IconBackIosSVG } from "assets/icons/clear.svg";
import Button from "components/atoms/Button/Button";
import PingMessageSuccess from "./components/PingMessageSuccess";
import PingNotFound from "./components/PingNotFound";
import NotFound from "pages/NotFound";

interface indexProps {
  testID?: string;
}

const iconMap = {
  keyring: <KeyringSVG />,
  sticker: <StickerSVG />,
  tag: <TagSVG />,
};

const PingStyled = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @media ${media.mobile} {
  }
  @media ${media.desktop} {
    padding: ${spacing.m} 0px;
    justify-content: center;
  }
`;

const PingInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
`;

const BackButtonStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
`;

PingComponent.testID = "ping";

const pingIdRegex = new RegExp("[0-9A-Za-z]{5}");

export default function PingComponent({ ...props }: indexProps) {
  const { id } = useParams();
  const navigate = useNavigate();

  const isValidPing = id !== undefined && pingIdRegex.test(id);

  if (!isValidPing) return <NotFound />;
  if (id === undefined) return <></>;

  const { pathname } = useLocation();

  const isMessage = React.useMemo(() => matchPath({ path: `/${id}/message`, end: true }, pathname), [pathname]);
  const isMessageSuccess = React.useMemo(
    () => matchPath({ path: `/${id}/message/success`, end: true }, pathname),
    [pathname]
  );

  const { data, isLoading } = usePing(id);

  const handleOnMessageClick = () => {
    navigate("./message");
  };
  const handleOnMessageBack = () => {
    navigate(`/${id}`);
  };
  const handleOnMessageSuccess = () => {
    navigate(`/${id}/message/success`);
  };

  return (
    <PingStyled data-testid={composeTestID(PingComponent.testID, props.testID)}>
      {isMessage || isMessageSuccess ? (
        <BackButtonStyled>
          <Button onClick={handleOnMessageBack} layout="icon-only" varient="light" icon={<IconBackIosSVG />}></Button>
        </BackButtonStyled>
      ) : (
        <></>
      )}
      <FlexLoader delay={1000} visible={isLoading}>
        Finding Ping
      </FlexLoader>
      {!isLoading && data !== undefined ? (
        <PingInfoStyled
          as={motion.div}
          initial={{ opacity: 0, translateY: 20 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{ duration: 0.5, type: "spring" }}
          style={{ paddingTop: isMessageSuccess ? 0 : spacing.xl }}
        >
          {!isMessageSuccess ? (
            <DisplayPingPicture size={isMessage ? "small" : "large"} icon={iconMap[data.productIcon]} src={data.img} />
          ) : (
            <></>
          )}
          <Routes>
            <Route path="/" element={<PingDetail data={data} onMessageClick={handleOnMessageClick} />} />
            <Route
              path="/message"
              element={<PingMessage data={data} onBack={handleOnMessageBack} onSuccess={handleOnMessageSuccess} />}
            />
            <Route
              path="/message/success"
              element={<PingMessageSuccess data={data} onFinish={handleOnMessageBack} />}
            />
          </Routes>
        </PingInfoStyled>
      ) : !isLoading ? (
        <PingNotFound id={id} />
      ) : (
        <></>
      )}
    </PingStyled>
  );
}
