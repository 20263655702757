import Icon from "components/atoms/Icon/Icon";
import { motion } from "framer-motion";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";

interface DisplayPingPictureProps {
  testID?: string;
  src: string | null;
  icon?: React.ReactElement;
  size?: "large" | "small";
}

const LARGE_PING_IMAGE_SIZE = "200px";
const LARGE_PING_ICON_SIZE = "60px";
const SMALL_PING_IMAGE_SIZE = "100px";
const SMALL_PING_ICON_SIZE = "30px";

const DisplayPingPictureStyled = styled.div`
  position: relative;
`;

const PingImgStyled = styled.img`
  border-radius: 100%;
  background-color: ${color.grey2};
  object-fit: cover;
`;

const DisplayPingPictureIconStyled = styled.div`
  position: absolute;
  width: ${LARGE_PING_ICON_SIZE};
  height: ${LARGE_PING_ICON_SIZE};
  bottom: 0px;
  right: 0px;
  border-radius: 100%;
  background-color: ${color.primary};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
`;

DisplayPingPictureComponent.testID = "displayPingPicture";

export default function DisplayPingPictureComponent({ icon, src, size = "large", ...props }: DisplayPingPictureProps) {
  const dynamicSize = size === "large" ? LARGE_PING_IMAGE_SIZE : SMALL_PING_IMAGE_SIZE;
  return (
    <DisplayPingPictureStyled data-testid={composeTestID(DisplayPingPictureComponent.testID, props.testID)}>
      <PingImgStyled
        as={motion.img}
        initial={{ opacity: 0, scale: 1.1, translateX: -30, boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)" }}
        animate={{
          opacity: 1,
          scale: 1,
          width: dynamicSize,
          height: dynamicSize,
          translateX: 0,
          boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
        }}
        transition={{ duration: 0.5, type: "spring" }}
        src={src !== null ? src : "images/ping/fake-tag.jpg"}
      />
      {size === "small" ? (
        <></>
      ) : (
        <DisplayPingPictureIconStyled
          as={motion.div}
          initial={{ opacity: 0, scale: 1.1, translateX: 15, boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)" }}
          animate={{ opacity: 1, scale: 1, translateX: 0, boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
          transition={{ duration: 0.5, type: "spring" }}
        >
          <Icon size={40} color={color.white}>
            {icon}
          </Icon>
        </DisplayPingPictureIconStyled>
      )}
    </DisplayPingPictureStyled>
  );
}
