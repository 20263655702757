import styled from "styled-components";
import color from "styles/color";
import spacing from "styles/spacing";

const BottomBarStyled = styled.div`
  width: 100%;
  background-color: ${color.grey2};
  display: flex;
  justify-content: center;
  padding: ${spacing.m} 0px;
  position: fixed;
  bottom: 0;
  left: 0;
`;

export default BottomBarStyled;
