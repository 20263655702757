import * as React from "react";
import styled from "styled-components";
import { KeyringWood } from "../../components/KeyringWood";

const KeyringWoodDesignStyled = styled.div`
  transform: scale(300%);
  /* border: 1px solid green; */
`;

export default function KeyringWoodDesignComponent() {
  return (
    <KeyringWoodDesignStyled>
      <KeyringWood id="C6V5N" />
    </KeyringWoodDesignStyled>
  );
}
