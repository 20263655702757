import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import media from "styles/media";
import spacing from "styles/spacing";

interface CardButtonsProps {
  testID?: string;
  children: React.ReactElement;
}

const SCALE = 1.4;

const CardButtonsStyled = styled.div`
  .appLink__ios {
    background-image: url("/appStore/ios.svg");
    width: calc(120px * ${SCALE});
    background-size: contain;
    height: calc(40px * ${SCALE});
    margin: ${spacing.m};
  }

  .appLink__android {
    background-image: url("/appStore/android.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: calc(135px * ${SCALE});
    height: calc(40px * ${SCALE});
    margin: ${spacing.m};
  }
  @media ${media.mobile} {
    width: 100%;
    padding: ${spacing.m};
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & button {
      width: 100%;
      box-sizing: border-box;
      margin: calc(${spacing.m} / 2) 0px;
    }
  }
  @media ${media.desktop} {
    display: flex;
    justify-content: center;
    margin: 0px auto;
    & button {
      display: inline-block;
      margin: 0px calc(${spacing.m} / 2);
    }
  }
`;

CardButtonsComponent.testID = "cardButtons";

export default function CardButtonsComponent({ children, ...props }: CardButtonsProps) {
  return (
    <CardButtonsStyled data-testid={composeTestID(CardButtonsComponent.testID, props.testID)}>
      {children}
    </CardButtonsStyled>
  );
}
