import { Product } from "types/Product";
import * as React from "react";
export type ProductSlug = "tag" | "sticker" | "keyring" | "keyring-wood";

const PRODUCT_DATA = {
  sticker: {
    id: "STICKERS_QRCODE_BLUEX4",
    title: "Stickers",
    released: true,
    categories: ["sticker"],
    location: "/products/sticker",
    ogImage: "https://pingify.io/images/photos/tags_square_transparent.png",
    ogLink: "https://pingify.io/product/sticker",
    subtitle: "€3.00 (Pack of 4)",
    weightGrams: 10,
    description:
      "Protect any item with a smooth surface (flat or curved). These stickers have a subtle design with just enough colour to draw the attention of any finder.",
    images: ["/images/photos/stickers_square_transparent"],
    feature: {
      title: (
        <>
          Durable <b>epoxy</b> coating
        </>
      ),
      body: "No need to worry about wearing off in your pocket. The durable epoxy coating will keep your stickers safe and readable for a long time.",
      image: "/images/product/stickers/deconstruction",
      imageAlt: "Deconstruction of sticker",
      supportedTypes: ["png"],
    },
  },
  keyring: {
    id: "KEYRING_GLASS",
    title: "Keyring",
    released: true,
    subtitle: "€3.00",
    categories: ["keyring"],
    location: "/products/keyring",
    ogImage: "https://pingify.io/images/photos/keyring_square_transparent.png",
    ogLink: "https://pingify.io/product/keyring",
    weightGrams: 16,
    description:
      "Keys are one of the most commonly lost items, also one of the most problematic. Avoid the hassle of changing all the locks with pingify keyrings.",
    images: ["/images/photos/keyring_square_transparent"],
    feature: {
      title: (
        <>
          Durable <b>glass</b> cover
        </>
      ),
      body: "No need to worry about wearing off in your pocket. The durable epoxy coating will keep your stickers safe and readable for a long time.",
      image: "/images/product/stickers/deconstruction",
      imageAlt: "Deconstruction of sticker",
      supportedTypes: ["png"],
    },
  },
  tag: {
    id: "TEXTILE_TAGSX3",
    title: "Tags",
    released: false,
    subtitle: "€2.00 (Pack of 3)",
    categories: ["tag"],
    location: "/products/tag",
    ogImage: "https://pingify.io/images/photos/tags_square_transparent.png",
    ogLink: "https://pingify.io/product/tag",
    weightGrams: 8,
    description:
      "Machine washable tags to attach to any items that can be pierced. Perfect for teddies, umbrellas, wallets and many other items.",
    images: ["/images/photos/tags_square_transparent"],
    feature: {
      title: <>Pinch with pliers to attach</>,
      body: "Easy application for teddies and more, seemlessly blend into items.",
      image: "/images/product/tags/tag_example",
      imageAlt: "Pingify tags application",
      supportedTypes: ["jpg"],
    },
  },
  "keyring-wood": {
    id: "KEYRING_WOOD",
    title: "Wood Keyring",
    released: false,
    subtitle: "€2.00",
    categories: ["keyring"],
    weightGrams: 10,
    location: "/products/keyring-wood",
    ogImage: "https://pingify.io/images/photos/wood-keyring_square_transparent.png",
    ogLink: "https://pingify.io/product/keyring-wood",
    description:
      "Keys are one of the most commonly lost items, also one of the most problematic. Avoid the hassle of changing all the locks with pingify keyrings.",
    images: ["/images/photos/wood-keyring_square_transparent"],
    feature: {
      title: (
        <>
          <b>Lazer</b> engraved
        </>
      ),
      body: "We like to keep things simple. So this keyring is made of only two materials and engraved with lazer for a perminant print. No ink or plastic... just wood and metal",
      image: "/images/product/wood_keyring/wood_laser",
      imageAlt: "Deconstruction of sticker",
      supportedTypes: ["png"],
    },
  },
};

export default PRODUCT_DATA as {
  [key in ProductSlug]: Product;
};

export const ProductDataWithTagID = Object.fromEntries(
  Object.entries(PRODUCT_DATA).map(([_, value]) => [value.id, value])
);
