import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import useOrders from "hooks/query/use-orders";

export default function useConfirmTransaction() {
  const queryCache = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, status }: { id: string; status: string }) => {
      return api.Order.updateStatus(id, status);
    },
    onSuccess: (_, { id: _id }: { id: string; status: string }) => {
      queryCache.invalidateQueries({ queryKey: [useOrders.queryKey] });
    },
  });
}
