import LoaderSimple from "components/atoms/LoaderSimple";
import useOrdersQuery from "hooks/query/use-orders-query";
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import PrintPage from "../components/PrintA4Page";
import { useReactToPrint } from "react-to-print";
import Button from "components/atoms/Button/Button";
import spacing from "styles/spacing";
import color from "styles/color";
import PrintLabel from "../components/PrintLabel";
import Heading from "components/atoms/Heading/Heading";
import BottomBar from "../components/BottomBar";

const ShippingLabelStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  align-items: center;
`;

const PrintContentContainer = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: ${spacing.xxl};
  &.printable {
    display: flex;
  }
`;

export default function ShippingLabelComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const ids = searchParams.get("ids")?.split(",");

  const componentRef = React.useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (ids === undefined) return <>No Ids in URL</>;

  const { data: orderData, isLoading, error } = useOrdersQuery(ids);
  return (
    <ShippingLabelStyled>
      <Heading as="h1" varient="h3">
        Shipping Labels {ids.length}/10
      </Heading>
      <PrintContentContainer>
        {isLoading ? (
          <LoaderSimple />
        ) : orderData !== undefined ? (
          <PrintPage ref={componentRef}>
            <>
              {orderData.map(order => (
                <PrintLabel key={order.id} order={order} />
              ))}
            </>
          </PrintPage>
        ) : (
          <>Error Fetching data</>
        )}
      </PrintContentContainer>
      <BottomBar>
        <Button onClick={handlePrint}>Print</Button>
      </BottomBar>
    </ShippingLabelStyled>
  );
}
