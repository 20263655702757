import composeTestID from "functions/compose-test-id";
import * as React from "react";
import { Link, LinkProps as LinkComponentProps } from "react-router-dom";
import styled from "styled-components";
import colorStyle from "styles/color";
import fontWeight from "styles/fontWeight";

interface LinkProps extends LinkComponentProps {
  testID?: string;
  color?: string;
  external?: boolean;
}

const LinkStyled = styled.a(
  ({ color }: Pick<LinkProps, "color">) => `
  color: ${color ?? colorStyle.primary};
  font-weight: ${fontWeight.bold};
  text-decoration: none;
`
);

LinkComponent.testID = "link";

export default function LinkComponent({ external = false, ...props }: LinkProps) {
  return external === true ? (
    <LinkStyled data-testid={composeTestID(LinkComponent.testID, props.testID)} href={props.to as string} {...props} />
  ) : (
    <LinkStyled as={Link} data-testid={composeTestID(LinkComponent.testID, props.testID)} {...props}></LinkStyled>
  );
}
