import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Picture from "components/atoms/Picture/Picture";
import TextInput from "components/molecules/Form/TextInput/TextInput";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import { Field, Form } from "react-final-form";
import styled from "styled-components";
import color from "styles/color";
import media from "styles/media";

interface EnterPingCardProps {
  testID?: string;
}

const SectionStyled = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  background: radial-gradient(circle at 20% 80%, #00b4c2 10%, #1e7ab8 110%);

  @media ${media.small} {
    flex-direction: row;
    height: 400px;
  }
`;

const FormStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 28px;
  box-sizing: border-box;

  & h1 {
    color: ${color.white};
  }

  & input {
    text-align: center;
  }
`;

const HeadingStyled = styled.h3`
  color: white;
  text-align: center;
`;

const PictureStyled = styled.picture`
  img {
    width: 300px;
  }
`;

EnterPingCardComponent.testID = "enterPingCard";

export default function EnterPingCardComponent({ ...props }: EnterPingCardProps) {
  const navigate = useNavigate();
  const handleOnSubmit = ({ code }: { code: string }) => {
    navigate(`/${code}`);
  };
  return (
    <Form onSubmit={handleOnSubmit} initialValues={{ code: "" }}>
      {({ handleSubmit }) => (
        <SectionStyled>
          <FormStyled>
            <Heading as="h1" varient="h3">
              Found a ping?
            </Heading>

            <Field
              parse={(val: string) =>
                val
                  .replace(/[^a-zA-Z0-9]/g, "") // Remove specialCharacters
                  .toUpperCase()
                  .slice(0, 5)
              }
              name="code"
              placeholder="Enter code"
              component={TextInput}
            />
            <Button varient="light" onClick={handleSubmit}>
              Search
            </Button>
          </FormStyled>
          <PictureStyled
            as={Picture}
            supportedTypes={["png"]}
            src={"/images/product-selection"}
            alt="pingify products"
          />
        </SectionStyled>
      )}
    </Form>
  );
}
