import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Spacer from "components/atoms/Spacer/Spacer";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import spacing from "styles/spacing";

const AdminDashboardStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function AdminDashboardComponent() {
  const navigate = useNavigate();
  return (
    <AdminDashboardStyled>
      <Heading as="h1" varient="h2">
        Dashboard
      </Heading>
      <Button onClick={() => navigate("orders")}>Orders</Button>
      <Spacer height={spacing.m}></Spacer>
      <Button onClick={() => navigate("products")}>Products</Button>
    </AdminDashboardStyled>
  );
}
