import { PingPublic } from "types/Ping";
import Connection from "./Connection";

export interface IReturnPing {
  alias: string;
  message: string;
  contactInfo?: string;
}

export async function get(id: string) {
  const response = await Connection.get(`ping/${id}`);
  return response.data as PingPublic;
}

export async function returnPing(id: string, values: IReturnPing) {
  const response = await Connection.post(`ping/${id}/return`, values);
  return response.data;
}
