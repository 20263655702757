import * as React from "react";
import styled from "styled-components";
import fontFamily from "styles/fontFamily";
import { QRCode } from "react-qr-svg";

interface StickerProps {
  id: string;
  showCut?: boolean;
  onlySVG?: boolean;
  x?: number;
  y?: number;
}

export const STICKER_BORDER_COLOR = `rgba(0,0,0,0.1)`;
export const STICKER_BORDER_WIDTH = `0.5mm`;

export const STICKER_WIDTH = 19; // 70 does not allow for border on width
export const STICKER_HEIGHT = 25;
const STICKER_TOP_HEIGHT = 5;
const STICKER_ROUNDED = 3;

const STICKER_TOP_COLOR = "white";

const TEXT_TOP_COLOR = "#2A7B96";
const TEXT_TOP_ANCHOR = 3.5;

const TEXT_ID_TOP = 5.7;
const TEXT_ID_COLOR = "white";

const HORIZONTAL_MARGIN = 2;

const QR_CODE_BORDER = 0.3;
const QR_CODE_SIZE = 14;
const QR_CODE_TOP = 8.3;
const QR_CODE_SIZE_OFFSET = (QR_CODE_SIZE - QR_CODE_BORDER * 2) * 0.03;
const QR_CODE_TOP_OFFSET = 0.65;
const QR_CODE_COLOR = "white";

const STROKE_WIDTH = 1;
const STROKE_COLOR = "#ff00ff"; // 100% Magenta

const StickerStyled = styled.div`
  width: ${STICKER_WIDTH}mm;
  height: ${STICKER_HEIGHT}mm;
  & svg {
    width: ${STICKER_WIDTH}mm;
    height: ${STICKER_HEIGHT}mm;
  }
`;

const StickerContainer = styled.div`
  display: inline-block;
`;

export default function StickerComponent({ id, showCut = false, onlySVG = false, x, y }: StickerProps) {
  return onlySVG ? (
    <StickerSVG x={x} y={y} id={id} showCut={showCut} />
  ) : (
    <StickerContainer>
      <StickerStyled>
        <StickerSVG id={id} showCut={showCut} />
      </StickerStyled>
    </StickerContainer>
  );
}

function StickerSVG({ id, showCut = false, ...rest }: Omit<StickerProps, "onlySVG">) {
  return (
    <svg
      {...rest}
      id="tag"
      width={STICKER_WIDTH}
      height={STICKER_HEIGHT}
      viewBox={`0 0 ${STICKER_WIDTH} ${STICKER_HEIGHT}`}
      overflow="visible"
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: `
            *{
              font-family:${fontFamily.primary};
            }
            .text_lost{
              font-weight: 600;
              font-size: 0.7mm;
            }
            .text_return_at{
              font-weight: 500;
              font-size: 0.53mm;
            }
            .text_pingify{
              font-weight: 600;
              font-size: 0.5mm;
              dominant-baseline: hanging;
            }
            `,
        }}
      ></style>
      <defs>
        <clipPath id="mask">
          <rect x={0} width={STICKER_WIDTH} y={0} height={STICKER_HEIGHT} fill={"white"} rx={STICKER_ROUNDED} />
        </clipPath>
        <radialGradient id="gradient" cx="50%" cy="60%" r="150%" fx="50%" fy="60%">
          <stop offset="0" style={{ stopColor: "#3EB4B3" }} />
          <stop offset="1" style={{ stopColor: "#1B7AB9" }} />
        </radialGradient>
      </defs>

      {showCut ? (
        <>
          <rect
            x={0}
            strokeWidth={`${STROKE_WIDTH}pt`}
            stroke={STROKE_COLOR}
            width={STICKER_WIDTH}
            y={0}
            height={STICKER_HEIGHT}
            fill={"transparent"}
            rx={STICKER_ROUNDED}
          />
        </>
      ) : (
        <>
          <rect
            x={0}
            width={STICKER_WIDTH}
            y={0}
            height={STICKER_HEIGHT}
            // fill={STICKER_MAIN_COLOR}
            fill={`url(#gradient)`}
            clipPath="url(#mask)"
          />
          <rect
            x={0}
            width={STICKER_WIDTH}
            y={0}
            height={STICKER_TOP_HEIGHT}
            fill={STICKER_TOP_COLOR}
            clipPath="url(#mask)"
          />
          <text
            textAnchor="start"
            x={HORIZONTAL_MARGIN}
            y={TEXT_TOP_ANCHOR}
            fill={TEXT_TOP_COLOR}
            className="text_lost"
          >
            Lost?
          </text>
          <text
            textAnchor="end"
            x={STICKER_WIDTH - HORIZONTAL_MARGIN}
            y={TEXT_TOP_ANCHOR}
            fill={TEXT_TOP_COLOR}
            className="text_return_at"
          >
            Return at
          </text>
          <text textAnchor="middle" fill={TEXT_ID_COLOR} x={STICKER_WIDTH / 2} y={TEXT_ID_TOP} className="text_pingify">
            pingify.io/{id}
          </text>
          <g
            transform={`translate(0, -${
              (QR_CODE_SIZE - QR_CODE_BORDER * 2 - QR_CODE_SIZE_OFFSET) / 2 - QR_CODE_TOP_OFFSET
            })`}
          >
            <QRCode
              bgColor={QR_CODE_COLOR}
              fgColor="rgba(255,255,255,0)"
              level="L"
              fill="none"
              width={QR_CODE_SIZE - QR_CODE_BORDER - QR_CODE_BORDER + QR_CODE_SIZE_OFFSET}
              x={(STICKER_WIDTH - QR_CODE_SIZE) / 2 + QR_CODE_BORDER - QR_CODE_SIZE_OFFSET / 2}
              y={QR_CODE_TOP + QR_CODE_BORDER}
              value={`pingify.io/${id}`}
            />
          </g>
          <rect
            x={(STICKER_WIDTH - QR_CODE_SIZE) / 2}
            y={QR_CODE_TOP}
            strokeWidth={QR_CODE_BORDER}
            width={QR_CODE_SIZE}
            height={QR_CODE_SIZE}
            fill="transparent"
            stroke={QR_CODE_COLOR}
          />
        </>
      )}
    </svg>
  );
}
