import * as React from "react";
import styled from "styled-components";
import { A6_HEIGHT, A6_WIDTH } from "./PrintA6Page";

interface A6SideHTMLProps {
  children?: React.ReactElement | React.ReactElement[];
}

export const A6_SIDE_VERTICAL_PADDING = "6mm";
export const A6_SIDE_HORIZONTAL_PADDING = "8mm";

const A6SideHTMLStyled = styled.div`
  display: inline-block;
  width: ${A6_WIDTH / 2}mm;
  height: ${A6_HEIGHT}mm;
  position: relative;
  padding: ${A6_SIDE_VERTICAL_PADDING} ${A6_SIDE_HORIZONTAL_PADDING};
`;

export function A6SideHTML({ children }: A6SideHTMLProps) {
  return <A6SideHTMLStyled>{children}</A6SideHTMLStyled>;
}
