import * as React from "react";
import styled from "styled-components";
import color from "styles/color";

const HOLE_SIZE = "3mm";

const TOP_MARGIN = "12mm";

const MARGIN_HORIZONTAL = "15mm";

const BlankTagsStyled = styled.div`
  margin: ${TOP_MARGIN} auto;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const HoleStyled = styled.div`
  background-color: ${color.grey1};
  width: ${HOLE_SIZE};
  height: ${HOLE_SIZE};
  margin: 0 calc(${MARGIN_HORIZONTAL} / 2);
  border-radius: 100%;
  display: inline-block;
`;

export function BlankTags() {
  return (
    <BlankTagsStyled>
      <HoleStyled />
      <HoleStyled />
      <HoleStyled />
    </BlankTagsStyled>
  );
}
