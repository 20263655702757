import Lottie from "react-lottie";
import * as React from "react";
import loaderBlueData from "assets/lottie/loaderBlue.json";
import loaderWhiteData from "assets/lottie/loaderWhite.json";
import styled from "styled-components";

interface LoaderProps {
  varient?: "light" | "dark";
  size?: string;
}

const LOADER_SIZE = "60px";

const LottieStyled = styled(Lottie)`
  width: ${LOADER_SIZE};
  height: ${LOADER_SIZE};
  display: inline-block;
`;

export default function LoaderComponent({ varient = "dark", size = "70px" }: LoaderProps) {
  return (
    <LottieStyled
      options={{ animationData: varient === "dark" ? loaderBlueData : loaderWhiteData, autoplay: true, loop: true }}
      width={size}
      height={size}
    />
  );
}
