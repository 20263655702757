import TitleDescription from "components/molecules/TitleDescription/TitleDescription";
import * as React from "react";
import productsData from "data/products";
import styled from "styled-components";
import BlogTemplate from "templates/BlogTemplate/BlogTemplate";
import Meta from "components/atoms/Meta";
import Constants from "constants/index";

const ProductsStyled = styled.div``;

export default function ProductsComponent() {
  return (
    <ProductsStyled>
      <Meta
        title={`Pingify - Explore our products`}
        description={Constants.meta.description.products}
        ogUrl={"https://pingify.io/products"}
      />
      <TitleDescription title="Products" />
      <BlogTemplate
        categories={[
          { key: "sticker", label: "Sticker" },
          { key: "keyring", label: "Keyring" },
          { key: "tag", label: "Tag" },
        ]}
        posts={Object.values(productsData) as any[]}
      />
    </ProductsStyled>
  );
}
