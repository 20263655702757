const THANKS_ARRAY = [
  "Oh what a kind human you are",
  "What a noble person you are",
  "Much gratitude for your service",
  "Give yourself a pitty pat on the back",
  "Splended work there chap",
];

export default function generateRandomThanks() {
  return THANKS_ARRAY[Math.floor(Math.random() * THANKS_ARRAY.length)];
}
