import * as React from "react";
import styled from "styled-components";

interface SpacerProps {
  height?: string;
  width?: string;
}

const SpacerStyled = styled.div``;

export default function SpacerComponent({ height, width }: SpacerProps) {
  return <SpacerStyled style={{ width: width ?? "100%", height: height ?? "100%" }}></SpacerStyled>;
}
