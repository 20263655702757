import Connection from "./Connection";

export interface RecoveryKeyValues {
  email: string;
  newPassword: string;
  repeatNewPassword: string;
  recoveryKey?: string;
}

export interface LoginPayload {
  email: string;
  password: string;
}

export async function recoveryPassword(values: RecoveryKeyValues) {
  const response = await Connection.post("authentication/recovery/password", values);
  return response;
}

export async function logIn({ email, password }: LoginPayload) {
  const response = await Connection.post("authentication/login/user", { email, password });
  return response;
}
