import * as React from "react";
import styled from "styled-components";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

interface DisplayPingTitleProps {
  children: string | null;
}

const DisplayPingTitleStyled = styled.h1`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.h4};
  font-weight: ${fontWeight.default};
  margin: ${spacing.m} 0px ${spacing.xxs} 0px;
  display: block;
  text-align: center;
`;

DisplayPingTitleComponent.testID = "displayPingTitle";

export default function DisplayPingTitleComponent({ children }: DisplayPingTitleProps) {
  return <DisplayPingTitleStyled>{children}</DisplayPingTitleStyled>;
}
