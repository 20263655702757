import Meta from "components/atoms/Meta";
import CardButtons from "components/organisms/Cards/CardButtons/CardButtons";
import FeatureCard from "components/organisms/Cards/FeatureCard/FeatureCard";
import * as React from "react";
import styled from "styled-components";

const DownloadStyled = styled.div``;

export default function DownloadComponent() {
  return (
    <DownloadStyled>
      <Meta title={`Pingify - Download`} description={`Download our app here.`} ogUrl={"http://pingify.io/download"} />
      <FeatureCard
        title={<>Protect all of your belongings.</>}
        body={
          <>
            Get <b>notified</b> when somebody finds them.
          </>
        }
        theme="dark"
        gradientBackground
        featureComponent={
          <CardButtons>
            <>
              <a href="https://apps.apple.com/us/app/pingify/id1493302804" className="appLink__ios" />
              <a
                href="https://play.google.com/store/apps/details?id=com.pingify.pingify"
                className="appLink__android"
              />
            </>
          </CardButtons>
        }
        flexCenter
      />
    </DownloadStyled>
  );
}
