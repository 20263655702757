import Constants from "constants/index";
import authCookies from "authCookies/index";

const DEFAULT_HEADERS = { "Content-Type": "application/json" };

const Connection = {
  get: async (path: string, options?: object) => {
    const response = await fetch(`${Constants.api}${path}`, {
      method: "GET",
      headers: DEFAULT_HEADERS,
      ...(options ? options : {}),
    });
    await checkError(response);
    return response.json();
  },
  getAuthorized: async (path: string, options?: object) => {
    const jwt = await authCookies.get("jwt");
    const response = await fetch(`${Constants.api}${path}`, {
      method: "GET",
      headers: { ...DEFAULT_HEADERS, jwt },
      ...(options ? options : {}),
    });
    await checkError(response);
    return response.json();
  },
  post: async (path: string, body?: object, options?: object) => {
    const response = await fetch(`${Constants.api}${path}`, {
      method: "POST",
      headers: DEFAULT_HEADERS,
      body: JSON.stringify(body),
      ...(options ? options : {}),
    });
    await checkError(response);
    return response.json();
  },
  delete: async (path: string, body?: object, options?: object) => {
    const response = await fetch(`${Constants.api}${path}`, {
      method: "DELETE",
      headers: DEFAULT_HEADERS,
      body: JSON.stringify(body),
      ...(options ? options : {}),
    });
    await checkError(response);
    return response.json();
  },
  patch: async (path: string, body?: object, options?: object) => {
    const response = await fetch(`${Constants.api}${path}`, {
      method: "PATCH",
      headers: DEFAULT_HEADERS,
      body: JSON.stringify(body),
      ...(options ? options : {}),
    });
    await checkError(response);
    return response.json();
  },
  patchAuthorized: async (path: string, body?: object, options?: object) => {
    const jwt = await authCookies.get("jwt");
    const response = await fetch(`${Constants.api}${path}`, {
      method: "PATCH",
      headers: { ...DEFAULT_HEADERS, jwt },
      body: JSON.stringify(body),
      ...(options ? options : {}),
    });
    await checkError(response);
    return response.json();
  },
  put: async (path: string, body?: object, options?: object) => {
    const response = await fetch(`${Constants.api}${path}`, {
      method: "PUT",
      headers: DEFAULT_HEADERS,
      body: JSON.stringify(body),
      ...(options ? options : {}),
    });
    await checkError(response);
    return response.json();
  },
  putAuthorized: async (path: string, body?: object, options?: object) => {
    const jwt = await authCookies.get("jwt");
    const response = await fetch(`${Constants.api}${path}`, {
      method: "PUT",
      headers: { ...DEFAULT_HEADERS, jwt },
      body: JSON.stringify(body),
      ...(options ? options : {}),
    });
    await checkError(response);
    return response.json();
  },
};

export default Connection;

// TODO find fetch typescript response and configure
const checkError = async (response: any) => {
  // eslint-disable-line
  if (![404, 200].includes(response.status)) {
    throw new Error(response.status);
  }
};
