import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";
import { ProductDataWithTagID } from "../../../data/products";

interface CheckoutProductProps {
  tag: string;
  quantity: number;
}

const IMAGE_SIZE = "120px";

const CheckoutProductStyled = styled.div`
  display: flex;
  width: 400px;
  background-color: ${color.grey1};
  border-radius: 12px;
  margin-bottom: ${spacing.s};
`;

const CheckoutImageStyled = styled.img`
  width: ${IMAGE_SIZE};
  height: ${IMAGE_SIZE};
`;

const CheckoutContentStyled = styled.div`
  flex: 1;
  padding: ${spacing.s} 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CheckoutItemTitleStyled = styled.div`
  font-size: ${fontSize.input};
  font-weight: ${fontWeight.semiBold};
  margin-bottom: ${spacing.xxs};
  margin-top: 0;
`;

const CheckoutItemDescriptionStyled = styled.div`
  font-size: ${fontSize.input};
  color: ${color.greyDark1};
  margin-bottom: ${spacing.xxs};
`;

const CheckoutItemInfoStyled = styled.div`
  font-size: ${fontSize.input};
`;

export default function CheckoutProductComponent({ tag, quantity }: CheckoutProductProps) {
  return (
    <CheckoutProductStyled>
      <CheckoutImageStyled src={`${ProductDataWithTagID[tag].images[0]}.png`} />
      <CheckoutContentStyled>
        <CheckoutItemTitleStyled>{ProductDataWithTagID[tag].title}</CheckoutItemTitleStyled>
        <CheckoutItemDescriptionStyled>
          <i>{ProductDataWithTagID[tag].subtitle}</i>
        </CheckoutItemDescriptionStyled>
        <CheckoutItemInfoStyled>Quantity: {quantity}</CheckoutItemInfoStyled>
      </CheckoutContentStyled>
    </CheckoutProductStyled>
  );
}
