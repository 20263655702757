import * as React from "react";
import Lottie from "lottie-react";
import styled from "styled-components";
import ConfettiJSON from "assets/lottie/confetti.json";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Heading from "components/atoms/Heading/Heading";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";
import color from "styles/color";

interface IProps {
  maxHeight?: string;
  borderRadius?: string;
  width?: "fit" | "square";
  title?: string;
  body?: string;
}

const ContainerStyled = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: block;
`;

const LottieStyled = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const TextContainerStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  & h1 {
    margin-bottom: 0px;
    font-weight: ${fontWeight.bold};
    color: ${color.primary};
  }
  & p {
    margin-top: ${spacing.xs};
  }
`;

const LottieImageStyled = styled(Lottie)`
  min-height: 100%;
  min-width: 100%;
  display: block;
  object-fit: cover;
  z-index: -1;
  & svg {
    height: calc(100vh) !important;
  }
`;

const CardLottieImage = ({ maxHeight, title, body }: IProps) => {
  return (
    <ContainerStyled>
      <LottieStyled style={{ maxHeight }}>
        <LottieImageStyled
          rendererSettings={{
            preserveAspectRatio: "xMidYMid slice",
          }}
          loop={false}
          animationData={ConfettiJSON}
        />
      </LottieStyled>
      <TextContainerStyled>
        <Heading as="h1" varient="h2">
          {title}
        </Heading>
        <Paragraph>{body}</Paragraph>
      </TextContainerStyled>
    </ContainerStyled>
  );
};

export default CardLottieImage;
