import * as React from "react";
import styled from "styled-components";
import Button from "components/atoms/Button/Button";
import spacing from "styles/spacing";
import Heading from "components/atoms/Heading/Heading";
import BottomBar from "../../../Print/components/BottomBar";
import InformationBox from "pages/Admin/Print/components/InformationBox";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import PrintA6Page from "pages/Admin/Print/components/PrintA6Page";
import A6Side from "pages/Admin/Print/components/A6Side";
import PackagePage1 from "../../components/Package/Package_Page_1";
import PackagePage4 from "../../components/Package/Package_Page_4";
import { toPng } from "html-to-image";
import Spacer from "components/atoms/Spacer/Spacer";
import TagPierceInstruction from "../../assets/tag_pierce_instruction.svg";
import TagPinchInstruction from "../../assets/tag_pinch_instruction.svg";
import PrintA6PageHTML from "pages/Admin/Print/components/PrintA6PageHTML";
import TitleBody from "../../components/Package/TitleBody";
import { A6SideHTML } from "pages/Admin/Print/components/A6SideHTML";
import Activate from "../../components/Package/Activate";
import { SloganText } from "../../components/Package/SloganText";
import ImageTitleBody from "../../components/Package/ImageTitleBody";
import { BlankKeyring } from "../../components/Package/BlankKeyring";
import BodyImage from "../../components/Package/BodyImage";
import { useReactToPrint } from "react-to-print";
import { generateActivationCode } from "functions/generate-activation-code";

const KeyringA4Styled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  align-items: center;
`;

const PrintContentContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: ${spacing.l};
  &.printable {
    display: flex;
  }
`;

const FILE_NAME = "Code_Package";

export default function PackageCodeA6Print() {
  const [showGuides, setShowGuides] = React.useState(false);
  const componentRef = React.useRef<HTMLDivElement>(null);
  const [activationCode, setActivationCode] = React.useState(generateActivationCode());

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <KeyringA4Styled>
      <Heading as="h1" varient="h3">
        Package (Code)
      </Heading>
      <PrintContentContainer>
        <PrintA6PageHTML ref={componentRef} showGuides={showGuides}>
          <>
            <A6SideHTML>
              <>
                <Activate code={activationCode} />
              </>
            </A6SideHTML>
            <A6SideHTML></A6SideHTML>
          </>
        </PrintA6PageHTML>
      </PrintContentContainer>
      <InformationBox>
        <Heading as="h2" varient="h4">
          Printing
        </Heading>
        <Paragraph>
          <ul>
            <li>Place printed packaging in printer, setting 15x10</li>
            <li>Print 1 copy per sheet (code renews after each print)</li>
          </ul>
        </Paragraph>
      </InformationBox>
      <BottomBar>
        <Button
          onClick={() => {
            handlePrint();
            setActivationCode(generateActivationCode());
          }}
        >
          Print
        </Button>
      </BottomBar>
    </KeyringA4Styled>
  );
}
