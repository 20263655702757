import * as React from "react";
import styled from "styled-components";
import fontWeight from "styles/fontWeight";

interface TitleBodyProps {
  title?: string;
  italic?: boolean;
  body: React.ReactElement | React.ReactElement[] | string;
  marginBottom?: string;
}

const FONT_SIZE = "3mm";
const MARGIN_SMALL = "2mm";

const MARGIN_LARGE = "2mm";

const TitleBodyStyled = styled.div`
  margin: ${MARGIN_LARGE} 0;
`;
const TitleStyled = styled.div`
  font-weight: ${fontWeight.default};
  font-size: ${FONT_SIZE};
`;
const BodyStyled = styled.div`
  font-weight: ${fontWeight.light};
  font-size: ${FONT_SIZE};
  margin: ${MARGIN_SMALL} 0;
  & p {
    margin: ${MARGIN_SMALL} 0;
  }
`;

export default function TitleBodyComponent({ title, body, italic = false, marginBottom }: TitleBodyProps) {
  return (
    <TitleBodyStyled style={marginBottom ? { marginBottom } : undefined}>
      {title && <TitleStyled style={{ fontStyle: italic ? "italic" : "normal" }}>{title}</TitleStyled>}
      <BodyStyled>{body}</BodyStyled>
    </TitleBodyStyled>
  );
}
