import { A6_HEIGHT, A6_WIDTH } from "pages/Admin/Print/components/PrintA6Page";
import * as React from "react";

const ICON_WIDTH = 21; // Check viewport of original icon
const ICON_HEIGHT = 29; // Check viewport of original icon

export default function PackagePage1() {
  return (
    <g
      style={{ transformOrigin: "center", transformBox: "fill-box" }}
      transform={`translate(${A6_WIDTH / 4 - ICON_WIDTH / 2} ${A6_HEIGHT / 2 - ICON_HEIGHT / 2})`}
    >
      <path
        d="M.413.649a1.314 1.314 0 0 1-.341-.52 1.359 1.359 0 0 1-.06-.616C.02-.547.035-.606.05-.666.242-.717.428-.74.592-.733a.923.923 0 0 0-.119.313.759.759 0 0 0 .037.386c.06.126.134.245.23.333A.755.755 0 0 0 1 .463a1.226 1.226 0 0 1-.267.186C.666.686.599.716.532.746.495.716.451.686.413.649z"
        fill="url(#a)"
        fillRule="nonzero"
        transform="matrix(10.096 0 0 -10.096 7.004 9.48)"
      />
      <path
        d="M.548.723A.963.963 0 0 1 .055.17a1.006 1.006 0 0 1 .037-.748.712.712 0 0 1 .143-.217c.327.097.568.289.7.578.132.29.06.674-.181 1.012A.601.601 0 0 1 .548.723z"
        fill="url(#b)"
        fillRule="nonzero"
        transform="matrix(6.2226 0 0 -6.2226 13.991 11.554)"
      />
      <path
        d="M.11.807C.11.8.103.794.103.786A1.853 1.853 0 0 1 .642-1.22a.217.217 0 0 1 .307.027c.075.096.068.232-.027.307a1.392 1.392 0 0 0-.437.69A1.69 1.69 0 0 0 .43.213a1.405 1.405 0 0 0 .464 1.011l.007.006c-.048.021-.103.041-.143.062a.292.292 0 0 1-.124.027C.42 1.319.219 1.082.11.807z"
        fill="url(#c)"
        fillRule="nonzero"
        transform="matrix(10.9868 0 0 -10.9868 0 14.492)"
      />
      <defs>
        <linearGradient
          id="a"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1 0 0 -1 0 .006)"
        >
          <stop offset="0" stopColor="#41b4b2" stopOpacity="1" />
          <stop offset="1" stopColor="#1f7ab9" stopOpacity="1" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1 0 0 -1 0 0)"
        >
          <stop offset="0" stopColor="#41b4b2" stopOpacity="1" />
          <stop offset="1" stopColor="#1f7ab9" stopOpacity="1" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1 0 0 -1 0 .024)"
        >
          <stop offset="0" stopColor="#41b4b2" stopOpacity="1" />
          <stop offset="1" stopColor="#1f7ab9" stopOpacity="1" />
        </linearGradient>
      </defs>
    </g>
  );
}
