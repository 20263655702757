import { APICheckout } from "types/API/Checkout";
import { BasketProduct } from "types/BasketProduct";
import Connection from "./Connection";

export interface CheckoutRequest {
  basket: BasketProduct[];
}

export async function post(basket: BasketProduct[]) {
  const response = await Connection.post(`checkout`, {
    basket,
  });
  return response.data as string;
}

export async function getCheckout(sessionId: string) {
  const response = await Connection.get(`checkout/${sessionId}`);
  return response.data as APICheckout;
}
