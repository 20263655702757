import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Link from "components/atoms/Inline/Link";
import DisplayTextContainer from "components/molecules/Display/DisplayTextContainer";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as IconBackIosSVG } from "assets/icons/icon-back-ios.svg";

const TermsAndConditionsStyled = styled.div``;

const BackButtonStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
`;

export default function TermsAndConditionsComponent() {
  const navigate = useNavigate();

  const handleOnBack = () => {
    navigate("/");
  };

  return (
    <TermsAndConditionsStyled>
      <BackButtonStyled>
        <Button onClick={handleOnBack} layout="icon-left" varient="light" icon={<IconBackIosSVG />}>
          Pingify.io
        </Button>
      </BackButtonStyled>
      <DisplayTextContainer>
        <Heading as="h1">Terms and Conditions</Heading>
        <p>
          These terms and conditions (“Agreement”) set forth the general terms and conditions of your use of the{" "}
          <Link external to="https://pingify.io">
            pingify.io
          </Link>{" "}
          website (“Website”), “Pingify.io” mobile application (“Mobile Application”) and any of their related products
          and services (collectively, “Services”). This Agreement is legally binding between you (“User”, “you” or
          “your”) and this Website operator and Mobile Application developer (“Operator”, “we”, “us” or “our”). If you
          are entering into this agreement on behalf of a business or other legal entity, you represent that you have
          the authority to bind such entity to this agreement, in which case the terms “User”, “you” or “your” shall
          refer to such entity. If you do not have such authority, or if you do not agree with the terms of this
          agreement, you must not accept this agreement and may not access and use the Services. By accessing and using
          the Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this
          Agreement. You acknowledge that this Agreement is a contract between you and the Operator, even though it is
          electronic and is not physically signed by you, and it governs your use of the Services.
        </p>
        <h2>Accounts and membership</h2>
        <p>
          If you create an account on the Services, you are responsible for maintaining the security of your account and
          you are fully responsible for all activities that occur under the account and any other actions taken in
          connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in
          and start using the Services. Providing false contact information of any kind may result in the termination of
          your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of
          security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred
          as a result of such acts or omissions.
        </p>
        <h2>Links to other resources</h2>
        <p>
          Although the Services may link to other resources (such as websites, mobile applications, etc.), we are not,
          directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any
          linked resource, unless specifically stated herein. We are not responsible for examining or evaluating, and we
          do not warrant the offerings of, any businesses or individuals or the content of their resources. We do not
          assume any responsibility or liability for the actions, products, services, and content of any other third
          parties. You should carefully review the legal statements and other conditions of use of any resource which
          you access through a link on the Services. Your linking to any other off-site resources is at your own risk.
        </p>
        <h2>Changes and amendments</h2>
        <p>
          We reserve the right to modify this Agreement or its terms related to the Services at any time at our
          discretion. When we do, we will send you an email to notify you. We may also provide notice to you in other
          ways at our discretion, such as through the contact information you have provided.
        </p>
        <p>
          An updated version of this Agreement will be effective immediately upon the posting of the revised Agreement
          unless otherwise specified. Your continued use of the Services after the effective date of the revised
          Agreement (or such other act specified at that time) will constitute your consent to those changes.
        </p>
        <h2>Acceptance of these terms</h2>
        <p>
          You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and
          using the Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this
          Agreement, you are not authorized to access or use the Services. This terms and conditions policy was created
          with the{" "}
          <Link external to="https://www.websitepolicies.com/terms-and-conditions-generator">
            terms and conditions generator
          </Link>
          .
        </p>
        <h2>Contacting us</h2>
        <p>
          If you have any questions, concerns, or complaints regarding this Agreement, we encourage you to contact us
          using the details below:
        </p>
        <p>&#105;n&#102;o&#64;&#112;&#105;&#110;gif&#121;.&#105;o</p>
        <p>This document was last updated on November 28, 2021</p>
      </DisplayTextContainer>
    </TermsAndConditionsStyled>
  );
}
