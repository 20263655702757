import * as React from "react";
import styled from "styled-components";
import EnterPingCard from "components/organisms/Cards/EnterPingCard/EnterPingCard";
import FeatureCard from "components/organisms/Cards/FeatureCard/FeatureCard";
import CardBlog from "components/organisms/Cards/CardBlog/CardBlog";
import productsData from "data/products";
import CardButtons from "components/organisms/Cards/CardButtons/CardButtons";
import BasicCard from "components/organisms/Cards/BasicCard/BasicCard";
import CardText from "components/organisms/Cards/CardText/CardText";
import CardImage from "components/organisms/Cards/CardImage/CardImage";
import Button from "components/atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import Meta from "components/atoms/Meta";
import Constants from "constants/index";

const HomeContainer = styled.div``;

export default function HomePage() {
  const navigate = useNavigate();
  return (
    <HomeContainer>
      <Meta
        title={"Pingify - Protect your things with pings"}
        description={Constants.meta.description.default}
        ogUrl="https://pingify.io"
      />
      <EnterPingCard />
      <FeatureCard
        title="Explore our products"
        body="Simple ways to protect different belongings."
        theme="light"
        backgroundColor={"#f1f4f7"}
        featureComponent={
          <CardBlog
            posts={Object.entries(productsData).map(([name, product]) => ({
              title: product.title,
              body: product.description,
              image: product.images[0],
              buttonText: "See more",
              location: product.location,
            }))}
          />
        }
      />
      <FeatureCard
        title={<>Protect all of your belongings.</>}
        body={
          <>
            Get <b>notified</b> when somebody finds them.
          </>
        }
        theme="dark"
        gradientBackground
        featureComponent={
          <CardButtons>
            <>
              <a href="https://apps.apple.com/us/app/pingify/id1493302804" className="appLink__ios" />
              <a
                href="https://play.google.com/store/apps/details?id=com.pingify.pingify"
                className="appLink__android"
              />
            </>
          </CardButtons>
        }
        flexCenter
      />
      <BasicCard
        leftComponent={
          <CardText
            headingSize={2}
            title={<>Simple. Subtle. Secure.</>}
            body="No expensive trackers. No batteries to change. Just a simple and subtle product to attach to your belongings without any hastle"
          />
        }
        rightComponent={
          <CardImage
            alt="Example of pingify sticker on phone"
            supportedTypes={["png"]}
            maxHeight={300}
            src={"/images/photos/product_example_1"}
          />
        }
      />
      <BasicCard
        theme="dark"
        rightComponent={
          <CardText
            headingSize={2}
            title={<>Protect what is important.</>}
            body="Nothing is worse than a teddy lost far from home. 'Pingify Tags' are machine washable and a great solution to protect the most precious of them all."
            primaryActionComponent={
              <Button
                onClick={() => {
                  navigate("/products/tag");
                }}
              >
                Pingify Tags
              </Button>
            }
          />
        }
        leftFlex={1}
        rightFlex={1}
        leftComponent={
          <CardImage
            alt="Lost teddy with pingify tag"
            supportedTypes={["jpg"]}
            maxHeight={330}
            src={"/images/photos/lost_teddy_square"}
          />
        }
      />
    </HomeContainer>
  );
}
