import { useQuery } from "@tanstack/react-query";

import API from "api";
import { AppOrderListItem } from "types/App/Order";

useOrdersQuery.queryKey = "ordersQuery";

export default function useOrdersQuery(ids: string[]) {
  return useQuery(
    [useOrdersQuery.queryKey, ...ids],
    () => {
      return API.Order.getAllQuery(ids) as Promise<AppOrderListItem[]>;
    },
    { notifyOnChangeProps: "all" }
  );
}
