import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import { FontStyle } from "./FontStyle";

interface PrintPageProps {
  children?: React.ReactElement;
  size?: number;
  showGuides?: boolean;
}

export const A6_WIDTH = 149; //mm
export const A6_HEIGHT = 105; //mm

const PrintPageDivStyled = styled.div`
  width: ${A6_WIDTH}mm;
  height: ${A6_HEIGHT}mm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  border: none;
  background-color: white;
  * {
    vertical-align: top;
  }
`;

const LinesStyled = styled.svg`
  width: 0.1mm;
  height: 100%;
  background-color: pink;
  left: 50%;
  position: absolute;
  top: 0;
`;

export default React.forwardRef<HTMLDivElement, PrintPageProps>(function PrintPageComponent(
  { children, size = 1, showGuides = false },
  ref
) {
  return (
    <PrintPageDivStyled ref={ref} style={{ transform: `scale(${size * 100}%)` }}>
      {children}
      {showGuides && <LinesStyled />}
    </PrintPageDivStyled>
  );
});
