import styled from "styled-components";

export interface IconProps {
  color?: string;
  size?: number;
}

const IconStyled = styled.div(
  ({ color, size = 14 }: IconProps) => `
  display: inline-block;
  & > svg {
    width: ${size}px;
    height: ${size}px;
    display: block;
    & > path {
      fill: ${color} !important;
    }
  }
`
);

export default IconStyled;
