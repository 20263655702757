import * as React from "react";
import styled from "styled-components";
import spacing from "styles/spacing";

interface TableRowProps {
  onRowClick?: () => void;
  badgeElement: React.ReactElement;
  colSpan: number;
}

const TableRowStyled = styled.tr<{ isClickable: boolean }>``;

const BadgeContainerStyled = styled.div`
  padding: ${spacing.xs} ${spacing.xs} 0 ${spacing.xs};
  position: relative;
  width: 100%;
  display: flex;
  cursor: pointer;

  & span {
    flex: 1;
    display: block;
    width: 100%;
  }
`;

export default function TableRowComponent({ badgeElement, onRowClick, colSpan }: TableRowProps) {
  return (
    <TableRowStyled onClick={onRowClick} isClickable={!!onRowClick}>
      <td colSpan={colSpan}>
        <BadgeContainerStyled>{badgeElement}</BadgeContainerStyled>
      </td>
    </TableRowStyled>
  );
}
