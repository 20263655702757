import * as React from "react";
import Lottie from "lottie-react";
import styled from "styled-components";

interface IProps {
  src: any;
  maxHeight?: string;
  borderRadius?: string;
  width?: "fit" | "square";
}

const LottieStyled = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 300px;
`;

const LottieImageStyled = styled(Lottie)`
  max-height: 300px;
  max-width: 300px;
  object-fit: contain;
`;

const CardLottieImage = ({ src, maxHeight }: IProps) => {
  return (
    <LottieStyled style={{ maxHeight }}>
      <LottieImageStyled as={LottieImageStyled} animationData={src} />
    </LottieStyled>
  );
};

export default CardLottieImage;
