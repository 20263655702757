import * as React from "react";
import styled from "styled-components";
import color from "styles/color";

interface TwoImagesProps {
  imageUrls: [any, any];
}

const IMAGE_WIDTH = "20mm";
const IMAGE_HEIGHT = "15mm";

const BORDER_WIDTH = "0.3mm";
const BORDER_COLOR = color.grey3;

const BORDER_RADIUS = "0.5mm";

const SIDE_MARGIN = "6mm";

const TwoImagesStyled = styled.div`
  width: 100%;
  padding: 0 ${SIDE_MARGIN};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const ImageBorderStyled = styled.img`
  display: inline-block;
  width: ${IMAGE_WIDTH};
  height: ${IMAGE_HEIGHT};
  border: ${BORDER_WIDTH} ${BORDER_COLOR} solid;
  border-radius: ${BORDER_RADIUS};
  object-fit: cover;
  overflow: hidden;
`;

export default function TwoImagesComponent({ imageUrls }: TwoImagesProps) {
  return (
    <TwoImagesStyled>
      <ImageBorderStyled src={imageUrls[0]}></ImageBorderStyled>
      <ImageBorderStyled src={imageUrls[1]}></ImageBorderStyled>
    </TwoImagesStyled>
  );
}
