import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

const LARGE_PADDING = spacing.m;
const SMALL_PADDING = spacing.s;

const TableDataStyled = styled.td(
  ({ $paddingSize }: { $paddingSize: "small" | "large" }) => `
  text-align: left;
  padding: ${$paddingSize === "small" ? SMALL_PADDING : LARGE_PADDING} ${
    $paddingSize === "small" ? SMALL_PADDING : LARGE_PADDING
  };
  font-family: ${fontFamily.primary};
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.p};
  color: ${color.black};
`
);

/**
 * See reason for float https://stackoverflow.com/a/56685632
 */
const TableHeaderFloat = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
`;

interface TableDataProps extends React.HTMLAttributes<HTMLTableCellElement> {
  paddingSize?: "small" | "large";
  colSpan?: number;
}

TableDataComponent.testID = "tableData";

export default function TableDataComponent({ children, paddingSize = "large", ...props }: TableDataProps) {
  return (
    <TableDataStyled $paddingSize={paddingSize} {...props}>
      <TableHeaderFloat>{children}</TableHeaderFloat>
    </TableDataStyled>
  );
}
