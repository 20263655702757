import Spacer from "components/atoms/Spacer/Spacer";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontWeight from "styles/fontWeight";
import { AppOrderListItem } from "types/App/Order";

interface PrintLabelProps {
  order: AppOrderListItem;
}

const PrintLabelStyled = styled.div`
  width: 9cm;
  height: 5cm;
  background-color: ${color.white};
  margin: 0.3cm;
  display: inline-block;
  border: 0.2mm solid ${color.grey3};
  padding: 0.3cm 0.8cm;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  font-size: 0.4cm;
  font-family: ${fontFamily.primary};
  font-weight: ${fontWeight.regular};
  position: relative;
`;

const PrintLabelLine = styled.div`
  padding: 0.1cm 0;
`;

const BatchNumberSmallStyled = styled.div`
  color: ${color.greyDark1};
  font-size: 0.35cm;
  position: absolute;
  right: 0.3cm;
  top: 0.3cm;
`;

export default function PrintLabelComponent({ order }: PrintLabelProps) {
  return (
    <PrintLabelStyled>
      <BatchNumberSmallStyled>{order.payment_intent.metadata.batch ?? "-"}</BatchNumberSmallStyled>
      <PrintLabelLine>{order.shipping_details.name}</PrintLabelLine>
      <Spacer height={"0.3cm"} />
      {order.shipping_details.address.line1 && <PrintLabelLine>{order.shipping_details.address.line1},</PrintLabelLine>}
      {order.shipping_details.address.line2 && <PrintLabelLine>{order.shipping_details.address.line2}</PrintLabelLine>}
      {
        <PrintLabelLine>
          {order.shipping_details.address.postal_code}, {order.shipping_details.address.city}
        </PrintLabelLine>
      }
      <PrintLabelLine>{order.shipping_details.address.country}</PrintLabelLine>
    </PrintLabelStyled>
  );
}
