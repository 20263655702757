import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

interface DisplayPingIDProps {
  children: string;
}

const DisplayPingIDStyled = styled.span`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.p};
  display: block;
  font-weight: ${fontWeight.regular};
  margin: ${spacing.xs} 0px ${spacing.s} 0px;
  color: ${color.primary};
`;

DisplayPingIDComponent.testID = "displayPingID";

export default function DisplayPingIDComponent({ children }: DisplayPingIDProps) {
  return <DisplayPingIDStyled>Unique ID: {children}</DisplayPingIDStyled>;
}
