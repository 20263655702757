import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

interface DisplayPingLastViewedProps {
  children: string;
}

const DisplayPingLastViewedTitleStyled = styled.span`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.p};
  display: block;
  font-weight: ${fontWeight.regular};
  margin: ${spacing.xs} 0px ${spacing.xs} 0px;
  color: ${color.greyDark1};
  text-align: center;
`;

const DisplayPingLastViewedDataStyled = styled.span`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.p};
  display: block;
  font-weight: ${fontWeight.regular};
  margin: 0px 0px ${spacing.l} 0px;
  color: ${color.black};
  text-align: center;
`;

DisplayPingLastViewedComponent.testID = "displayPingLastViewed";

export default function DisplayPingLastViewedComponent({ children }: DisplayPingLastViewedProps) {
  return (
    <>
      <DisplayPingLastViewedTitleStyled>Last viewed:</DisplayPingLastViewedTitleStyled>
      <DisplayPingLastViewedDataStyled>{children}</DisplayPingLastViewedDataStyled>
    </>
  );
}
