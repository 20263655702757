import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import TitleBodyComponent from "./TitleBody";

interface ImageTitleBodyProps {
  imageUrl: any;
  body: React.ReactElement | React.ReactElement[] | string;
}

const SIDE_MARGIN = "2mm";

const MARGIN_VERTICAL = "2mm";

const IMAGE_WIDTH = "20mm";
const IMAGE_HEIGHT = "15mm";

const BORDER_WIDTH = "0.3mm";
const BORDER_COLOR = color.grey3;

const BORDER_RADIUS = "2mm";

const ImageTitleBodyStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${MARGIN_VERTICAL} 0;
  & div {
    flex: 1;
    margin: 0;
    /* margin-right: -3mm; */
  }
`;
const ImageBorderStyled = styled.img`
  display: inline-block;
  width: ${IMAGE_WIDTH};
  height: ${IMAGE_HEIGHT};
  border: ${BORDER_WIDTH} ${BORDER_COLOR} solid;
  border-radius: ${BORDER_RADIUS};
  object-fit: cover;
  overflow: hidden;
  margin-left: ${SIDE_MARGIN};
`;

export default function BodyImage({ imageUrl, body }: ImageTitleBodyProps) {
  return (
    <ImageTitleBodyStyled>
      <TitleBodyComponent body={body} />
      <ImageBorderStyled src={imageUrl}></ImageBorderStyled>
    </ImageTitleBodyStyled>
  );
}
