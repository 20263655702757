import * as React from "react";
import styled from "styled-components";
import media from "styles/media";
import spacing from "styles/spacing";

export interface IDisplayTextContainerProps {
  children: React.ReactElement | React.ReactElement[];
}

const DisplayTextContainerStyled = styled.div`
  padding: 0px ${spacing.xl};
  max-width: 768px;
  margin: ${spacing.xl} auto;
  & > h1 {
    margin: ${spacing.m} 0px;
  }
  @media ${media.mobile} {
    max-width: 768px;
  }
  @media ${media.desktop} {
    max-width: 960px;
  }
`;

export default function DisplayTextContainer({ children }: IDisplayTextContainerProps) {
  return <DisplayTextContainerStyled>{children}</DisplayTextContainerStyled>;
}
