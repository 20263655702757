import * as React from "react";
import styled from "styled-components";
import Button from "components/atoms/Button/Button";

const TestContainer = styled.div``;

export default function TestComponent() {
  return (
    <TestContainer>
      <Button>Click me</Button>
    </TestContainer>
  );
}
