import { useQuery } from "@tanstack/react-query";

import API from "api";

useProduct.queryKey = "product";

export default function useProduct(tag: string) {
  return useQuery([useProduct.queryKey, tag], () => {
    return API.Product.getProduct(tag);
  });
}
