import Icon from "components/atoms/Icon/Icon";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import { ReactComponent as NavigationX } from "assets/icons/navigation-x.svg";
import { ReactComponent as NavigationBurger } from "assets/icons/navigation-burger.svg";
import { NavLink } from "react-router-dom";
import media from "styles/media";
import spacing from "styles/spacing";
import color from "styles/color";
import fontSize from "styles/fontSize";
import BasketButton from "./BasketButton";
import { useIsMobile } from "hooks/use-is-mobile";

interface NavigationProps {
  testID?: string;
}

export const LARGE_NAV_HEIGHT = "53px";
export const SMALL_NAV_HEIGHT = "50px";

const LARGE_NAV_PADDING = spacing.m;
const SMALL_NAV_PADDING = spacing.s;

const LARGE_MENU_PADDING = spacing.m;
const SMALL_MENU_PADDING = spacing.m;

const NavigationStyled = styled.nav(
  ({ $isOpen }: { $isOpen: boolean }) => `
  background-color: white;
  z-index: 999;
  width: 100%;
  box-sizing: border-box;

  & li {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & a {
        text-decoration: none;
    }
  }

  @media ${media.mobile} {
    height: ${SMALL_NAV_HEIGHT};
    background-color: white;
    width: 100%;
    overflow: hidden;
    display: flex;
    color: ${color.white};
    position: fixed;
    top: 0px;
    flex-direction: column;
    border-bottom: solid 1px ${color.grey2};
    ${$isOpen ? "height: 100%;" : ""}
    & li {
        height: ${SMALL_NAV_HEIGHT};
        & a {
            display: flex;
            align-items: center;
            height: ${SMALL_NAV_HEIGHT};
        }
    }
  }
  @media ${media.desktop} {
    border-bottom: solid 1px ${color.grey2};
    display: flex;
    position: fixed;
    top: 0px;
    flex-direction: row;
    padding: 0px ${LARGE_NAV_PADDING};
    height: ${LARGE_NAV_HEIGHT};
  }
`
);

const NavigationMobileBarStyled = styled.div`
  @media ${media.mobile} {
    width: 100%;
    height: ${SMALL_NAV_HEIGHT};
    box-sizing: border-box;
    background-color: ${color.white};
    padding: 0px ${SMALL_NAV_PADDING};
    justify-content: space-between;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`;

const NavigationMobileBasketContainer = styled.div`
  position: fixed;
  height: ${SMALL_NAV_HEIGHT};
  right: ${spacing.m};
  top: 0;
`;

const NavigationMenuBurgerStyled = styled.button`
  @media ${media.mobile} {
    width: ${SMALL_NAV_HEIGHT};
    height: ${SMALL_NAV_HEIGHT};
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: Transparent;
    border: none;
  }
  @media ${media.desktop} {
    display: hidden;
    opacity: 0;
  }
`;

const NavigationMainULStyled = styled.ul`
  @media ${media.mobile} {
    flex: 1;
    overflow-y: scroll;
    background-color: white;
    padding: 10px ${SMALL_NAV_PADDING};
    li {
      border-bottom: solid 1px ${color.grey2};
      a {
        color: ${color.black};
        &.active {
          color: ${color.primary};
        }
      }
    }
  }
  @media ${media.desktop} {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
    li a {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px ${LARGE_NAV_PADDING};
      height: ${LARGE_NAV_HEIGHT};
      font-size: ${fontSize.button};
      color: #7a8699 !important;
      box-sizing: border-box;
      margin-left: 10px;
      transition: color 0.5s ease;
    }
    li a:hover {
      color: ${color.primary} !important;
    }
    li a.active {
      color: ${color.secondary} !important;
      font-weight: 500;
      border-top: solid 1px white;
    }
  }
`;

const NavigationAsideULStyled = styled.ul`
  & li {
    height: 100%;
    a.active,
    a:link,
    a {
      font-size: ${fontSize.button};
      transition: color 0.5s ease;
      background-color: ${color.primary};
      border-radius: 40px;
      height: 40px;
      color: white !important;
      font-weight: 400;
    }
  }
  @media ${media.mobile} {
    padding: 10px ${SMALL_NAV_PADDING};
    & li {
      border-radius: 8px;
      box-sizing: border-box;
      display: flex !important;
      justify-content: center;
      align-items: center;
      a.active,
      a:link,
      a {
        display: flex;
        align-items: center;
        padding: 0px ${SMALL_MENU_PADDING};
        font-weight: 400;
        width: 150px;
        justify-content: center;
      }
    }
  }
  @media ${media.desktop} {
    position: absolute;
    margin: 0px;
    right: 10px;
    top: 0px;
    height: 100%;
    & li {
      border-radius: 8px;
      box-sizing: border-box;
      display: flex !important;
      justify-content: center;

      a.active,
      a:link,
      a {
        display: flex;
        align-items: center;
        padding: 0px ${LARGE_MENU_PADDING};
      }
    }
  }
`;

const NavigationPaddingStyled = styled.div`
  width: 100%;
  @media ${media.mobile} {
    height: ${SMALL_NAV_HEIGHT};
  }
  @media ${media.desktop} {
    height: ${LARGE_NAV_HEIGHT};
  }
`;

NavigationComponent.testID = "navigation";

export default function NavigationComponent({ ...props }: NavigationProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const isMobile = useIsMobile();

  const handleOnToggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <NavigationStyled
        $isOpen={isOpen}
        data-testid={composeTestID(NavigationComponent.testID, props.testID)}
        className={`nav ${isOpen ? "-open" : ""}`}
      >
        <NavigationMobileBarStyled className="nav__mobile-bar">
          {isMobile ? (
            <>
              <NavigationMenuBurgerStyled
                className={`nav__menu-burger ${isOpen ? "-open" : ""}`}
                onClick={handleOnToggleMenu}
              >
                <Icon>{isOpen ? <NavigationX /> : <NavigationBurger />}</Icon>
              </NavigationMenuBurgerStyled>
            </>
          ) : (
            <></>
          )}
        </NavigationMobileBarStyled>
        {isMobile ? (
          <NavigationMobileBasketContainer>
            <BasketButton />
          </NavigationMobileBasketContainer>
        ) : (
          <></>
        )}

        {!isMobile || isOpen ? (
          <NavigationMainULStyled className="nav__main">
            <li>
              <NavLink to="/" end onClick={isOpen ? handleOnToggleMenu : undefined}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/how-it-works" onClick={isOpen ? handleOnToggleMenu : undefined}>
                How it works
              </NavLink>
            </li>
            <li>
              <NavLink to="/products" onClick={isOpen ? handleOnToggleMenu : undefined}>
                Products
              </NavLink>
            </li>
            <li>
              <NavLink to="/download" onClick={isOpen ? handleOnToggleMenu : undefined}>
                App
              </NavLink>
            </li>
          </NavigationMainULStyled>
        ) : (
          <></>
        )}
        {isMobile ? (
          <></>
        ) : (
          <NavigationAsideULStyled className="nav__right">
            <li>
              <BasketButton />
              {/* <Button icon={<CartOutlineSVG />} layout={"icon-right"} varient="light">
              2
            </Button> */}
              {/* <NavLink to="/download" onClick={isOpen ? handleOnToggleMenu : undefined}>
              Get the App
            </NavLink> */}
            </li>
          </NavigationAsideULStyled>
        )}
      </NavigationStyled>
      <div>
        <NavigationPaddingStyled />
      </div>
    </>
  );
}
