import React from "react";
import PageRoutes from "pages";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { init } from "@amplitude/analytics-browser";
import Constants from "constants/index";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      staleTime: 60000,
    },
  },
});

// Initialise amplitude
init(Constants.amplitude.apiKey, undefined, { disableCookies: true });

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <PageRoutes />
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
