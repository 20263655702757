import Button from "components/atoms/Button/Button";
import * as React from "react";
import styled from "styled-components";
import { ReactComponent as CartOutlineSVG } from "assets/icons/cart-outline.svg";
import { Menu } from "@headlessui/react";
import Icon from "components/atoms/Icon/Icon";
import media from "styles/media";
import color from "styles/color";
import spacing from "styles/spacing";
import Spacer from "components/atoms/Spacer/Spacer";
import fontWeight from "styles/fontWeight";
import basket, { actions as basketActions } from "reducer/basket";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { BasketProduct } from "types/BasketProduct";
import BasketItem from "./BasketItem";
import api from "api";

const LARGE_NAV_HEIGHT = "53px";
const SMALL_NAV_HEIGHT = "50px";

const BasketButtonStyled = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${media.mobile} {
    height: ${SMALL_NAV_HEIGHT};
  }
  @media ${media.desktop} {
    height: ${LARGE_NAV_HEIGHT};
  }
`;

const BasketStyled = styled.div`
  position: relative;
  z-index: 100;
`;

const BasketItemsStyled = styled.div`
  min-width: 300px;
  position: absolute;
  right: 0;
  background-color: ${color.white};
  border: 1px solid ${color.grey2};
  padding: ${spacing.m} 0;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  @media ${media.mobile} {
    top: calc(${SMALL_NAV_HEIGHT} + ${spacing.s});
  }
  @media ${media.desktop} {
    top: calc(${LARGE_NAV_HEIGHT} + ${spacing.s});
  }
`;

const NOTIFICATION_SIZE = "22px";

const BasketNumberStyled = styled.div`
  margin: ${spacing.xxs};
  width: ${NOTIFICATION_SIZE};
  height: ${NOTIFICATION_SIZE};
  border-radius: 100%;
  background-color: ${color.primary};
  font-size: 14px;
  color: ${color.white};
  font-weight: ${fontWeight.bold};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function BasketButtonComponent() {
  const basketItems = useSelector((state: RootState) => state.basket.items);

  const dispath = useDispatch();

  const hasItems = basketItems.length > 0;

  const handleOnBasketItemDelete = (basketItem: BasketProduct) => {
    dispath(basketActions.deleteProductFromBasket({ id: basketItem.id }));
  };

  const handleOnCheckout = async () => {
    console.log("click");
    try {
      const response = await api.Checkout.post(basketItems);
      console.log(response);
      window.open(response);
    } catch (er) {
      console.log(er);
    }
  };

  return (
    <Menu as={BasketStyled}>
      <Menu.Button as={BasketButtonStyled}>
        {hasItems ? <BasketNumberStyled>{basketItems.length}</BasketNumberStyled> : <></>}
        <Icon size={30}>
          <CartOutlineSVG />
        </Icon>
      </Menu.Button>
      <Menu.Items as={BasketItemsStyled}>
        {!hasItems ? (
          <NoItemsInBaskComponent />
        ) : (
          basketItems.map(basketItem => (
            <BasketItem key={basketItem.id} data={basketItem} onDelete={() => handleOnBasketItemDelete(basketItem)} />
          ))
        )}
        <Spacer height={spacing.m} />
        <Menu.Item>
          <Button disabled={!hasItems} varient="primary" onClick={handleOnCheckout}>
            Checkout
          </Button>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
}

const NoItemsContainerStyled = styled.div`
  background-color: ${color.grey2};
  width: calc(100% - ${spacing.m} * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-radius: 10px;
  color: ${color.greyDark1};
  margin: ${spacing.m} ${spacing.m} 0 ${spacing.m};
`;

function NoItemsInBaskComponent() {
  return <NoItemsContainerStyled>No items to your basket</NoItemsContainerStyled>;
}
