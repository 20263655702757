import * as React from "react";
import styled from "styled-components";
import Button from "components/atoms/Button/Button";
import spacing from "styles/spacing";
import Heading from "components/atoms/Heading/Heading";
import BottomBar from "../../../Print/components/BottomBar";
import InformationBox from "pages/Admin/Print/components/InformationBox";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import PrintA6Page from "pages/Admin/Print/components/PrintA6Page";
import A6Side from "pages/Admin/Print/components/A6Side";
import PackagePage1 from "../../components/Package/Package_Page_1";
import PackagePage4 from "../../components/Package/Package_Page_4";
import { toPng } from "html-to-image";
import Spacer from "components/atoms/Spacer/Spacer";
import TagPierceInstruction from "../../assets/tag_pierce_instruction.svg";
import TagPinchInstruction from "../../assets/tag_pinch_instruction.svg";
import PrintA6PageHTML from "pages/Admin/Print/components/PrintA6PageHTML";
import TitleBody from "../../components/Package/TitleBody";
import { A6SideHTML } from "pages/Admin/Print/components/A6SideHTML";
import Activate from "../../components/Package/Activate";
import { SloganText } from "../../components/Package/SloganText";
import ImageTitleBody from "../../components/Package/ImageTitleBody";
import { BlankKeyring } from "../../components/Package/BlankKeyring";
import BodyImage from "../../components/Package/BodyImage";
import { BlankTags } from "../../components/Package/BlankTags";

const KeyringA4Styled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  align-items: center;
`;

const PrintContentContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: ${spacing.l};
  &.printable {
    display: flex;
  }
`;

const FILE_NAME = "Tags_Package";

export default function PackageTagsA6Print() {
  const [showGuides, setShowGuides] = React.useState(false);
  const refOutside = React.useRef<HTMLDivElement>(null);
  const refInside = React.useRef<HTMLDivElement>(null);

  const handlePrint = (location: 0 | 1) => {
    const ref =
      location === 0 && refOutside.current !== null
        ? refOutside.current
        : location === 1 && refInside.current !== null
        ? refInside.current
        : undefined;
    if (ref == undefined) {
      return;
    }

    toPng(ref, {
      cacheBust: true,
      pixelRatio: 10,
    })
      .then(dataUrl => {
        const link = document.createElement("a");
        link.download = `${FILE_NAME}_${location === 0 ? "Outside" : "Inside"}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <KeyringA4Styled>
      <Heading as="h1" varient="h3">
        Package (Tags)
      </Heading>
      <PrintContentContainer>
        <PrintA6Page ref={refOutside} showGuides={showGuides}>
          <>
            <A6Side page={1}>
              <>
                <PackagePage4 />
              </>
            </A6Side>
            <A6Side page={2}>
              <PackagePage1 />
            </A6Side>
          </>
        </PrintA6Page>
      </PrintContentContainer>
      <PrintContentContainer>
        <PrintA6PageHTML ref={refInside} showGuides={showGuides}>
          <>
            <A6SideHTML>
              <>
                <TitleBody
                  title="Instructions"
                  body={
                    <>
                      <p>Fix the tags onto a piercable surface that has access to both sides..</p>
                    </>
                  }
                  marginBottom={"0"}
                />
                <BodyImage
                  imageUrl={TagPierceInstruction}
                  body={
                    <>
                      Pierce a hole big enough to poke the rivet through. Start with a small hole and widen it with a
                      sharp pencil.
                    </>
                  }
                />
                <BodyImage
                  imageUrl={TagPinchInstruction}
                  body={
                    <>
                      Pierce a hole big enough to poke the rivet through. Start with a small hole and widen it with a
                      sharp pencil.
                    </>
                  }
                />
                <TitleBody
                  italic
                  title="Tip"
                  body={<>Leave a small gap when fixing the tag incase you want to remove it with wire-cutters.</>}
                />
                <Activate />
              </>
            </A6SideHTML>
            <A6SideHTML>
              <BlankTags />
              <SloganText></SloganText>
            </A6SideHTML>
          </>
        </PrintA6PageHTML>
      </PrintContentContainer>
      <InformationBox>
        <Heading as="h2" varient="h4">
          Printing
        </Heading>
        <Paragraph>
          <ul>
            <li>
              Use <a href="https://www.printenbind.nl/en/bestel/article?product=ansichtkaart">PrintNBind</a>
              <br />
              <i>
                A6 color double sided printed on 300 grams Natural card (white) paper, basic cut out, front provided
                with a glossy coating, none folded
              </i>
            </li>
          </ul>
        </Paragraph>
        <Heading as="h2" varient="h4">
          Assembly
        </Heading>
        <Paragraph>
          <ul>
            <li>Use 3D printed line creator to create fold in center</li>
            <li>Cut corners with corner stamp ?mm corner</li>
          </ul>
        </Paragraph>
      </InformationBox>
      <BottomBar>
        <Button onClick={() => handlePrint(0)}>Export Outside</Button>
        <Spacer width={spacing.m} />
        <Button onClick={() => handlePrint(1)}>Export Inside</Button>
        <Spacer width={spacing.m} />
        <Button varient="secondary" onClick={() => setShowGuides(!showGuides)}>{`Toggle Guides: ${
          showGuides ? "On" : "Off"
        }`}</Button>
      </BottomBar>
    </KeyringA4Styled>
  );
}
