import { Listbox, Transition } from "@headlessui/react";
import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron-down.svg";
import Icon from "components/atoms/Icon/Icon";
import React, { Fragment } from "react";
import styled from "styled-components";
import color from "styles/color";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

const ListboxStyled = styled.div`
  padding: ${spacing.xs} 0;
  width: 100%;
  position: relative;
`;

const ListButtonStyled = styled(Listbox.Button)`
  width: 100%;
  padding: ${spacing.m};
  border: 0;
  background-color: ${color.grey2};
  /* border-bottom: 1px solid ${color.purple}; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const ListOptionsStyled = styled(Listbox.Options)`
  width: 100%;
  border: none;
  box-shadow: ${spacing.xxs} ${spacing.xxs} ${spacing.s} 0 ${color.grey2};
  border-radius: 0 0 ${spacing.s} ${spacing.s};
  margin-top: 0;
  z-index: 1;
  background-color: ${color.white};
  padding: 0 ${spacing.m};
  overflow-y: auto;
  position: absolute;
  max-height: min(450px, 60vh);
`;

const ListOptionStyled = styled(Listbox.Option)`
  padding: ${spacing.l} ${spacing.xs};
  list-style: none;
  font-weight: ${fontWeight.regular};

  &:not(:last-child) {
    border-bottom: 1px solid ${color.grey2};
  }

  cursor: pointer;
`;
interface OptionInterface {
  label: string;
  value: string;
}

DropDownComponent.testID = "DropDown";

export default function DropDownComponent({
  options,
  onChange,
  defaultValue,
  value,
}: {
  options: OptionInterface[];
  value: string;
  defaultValue: string;
  onChange: (value: string) => void;
}) {
  const selectedValue = React.useMemo(
    () => options.find(option => option.value === value) ?? options.find(option => option.value === defaultValue),
    [value]
  );

  const handleOnChange = (option?: OptionInterface) => {
    option && onChange(option.value);
  };

  return (
    <Listbox value={selectedValue} by="value" onChange={handleOnChange} data-testid={DropDownComponent.testID}>
      <ListboxStyled>
        <Listbox.Button as={ListButtonStyled}>
          <span>{selectedValue?.label}</span>
          <Icon>
            <ChevronDownIcon />
          </Icon>
        </Listbox.Button>
        <Transition as={Fragment}>
          <Listbox.Options as={ListOptionsStyled}>
            {options.map(({ label, value }, idx) => (
              <Listbox.Option key={`${value}-${idx}`} as={ListOptionStyled} value={{ label, value }}>
                <span>{label}</span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </ListboxStyled>
    </Listbox>
  );
}
