import Heading from "components/atoms/Heading/Heading";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import color from "styles/color";
import KeyringWoodDesign from "./KeyringWoodDesign";
import StickerDesign from "./StickerDesign";
import TagsDesign from "./TagsDesign";

const DesignStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DesignContainerStyled = styled.div`
  flex: 1;
  background-color: ${color.grey2};
  min-height: 1000px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function DesignComponent() {
  return (
    <DesignStyled>
      <Heading as="h1">Design</Heading>
      <DesignContainerStyled>
        <Routes>
          <Route path="/tags" element={<TagsDesign />} />
          <Route path="/sticker" element={<StickerDesign />} />
          <Route path="/keyring-wood" element={<KeyringWoodDesign />} />
        </Routes>
      </DesignContainerStyled>
    </DesignStyled>
  );
}
