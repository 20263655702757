import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import spacing from "styles/spacing";

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  testID?: string;
}

const LabelStyled = styled.label`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.p};
  padding: ${spacing.s} 0px;
  display: block;
`;

LabelComponent.testID = "label";

export default function LabelComponent({ ...props }: LabelProps) {
  return <LabelStyled {...props} data-testid={composeTestID(LabelComponent.testID, props.testID)}></LabelStyled>;
}
