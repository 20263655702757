import Loader from "components/atoms/Loader";
import { AnimatePresence, motion } from "framer-motion";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontSize from "styles/fontSize";
import spacing from "styles/spacing";

interface FlexLoaderProps {
  testID?: string;
  children?: string | React.ReactElement;
  visible?: boolean;
  delay?: number;
}

const FlexLoaderStyled = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: ${color.white};
`;

const FlexLoaderText = styled.span`
  font-size: ${fontSize.small};
  color: ${color.greyDark1};
  padding-top: ${spacing.s};
`;

FlexLoaderComponent.testID = "flexLoader";

export default function FlexLoaderComponent({ visible = true, children, delay = 0, ...props }: FlexLoaderProps) {
  const [dots, setDots] = React.useState<"."[]>([".", ".", "."]);

  // React.useEffect(() => {
  //   const tickInterval = setInterval(() => {
  //     console.log("setting");
  //     setDots(dots.length === 3 ? ["."] : [...dots, "."]);
  //   }, 500);
  //   return () => {
  //     clearInterval(tickInterval);
  //   };
  // }, [dots]);

  return (
    <AnimatePresence>
      {visible && (
        <FlexLoaderStyled
          as={motion.div}
          initial={{ opacity: 0, transform: "scale(110%)" }}
          animate={{ opacity: 1, transform: "scale(100%)", transition: { delay: delay / 1000 } }}
          exit={{ opacity: 0, transform: "scale(110%)" }}
          data-testid={composeTestID(FlexLoaderComponent.testID, props.testID)}
        >
          <Loader varient="dark" />
          {children ? (
            <FlexLoaderText>
              {children}
              {dots.join("")}
            </FlexLoaderText>
          ) : (
            <></>
          )}
        </FlexLoaderStyled>
      )}
    </AnimatePresence>
  );
}
